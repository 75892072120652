import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Controller } from 'src/app/core/models/controller.model';
import { ValueGraph } from 'src/app/core/models/logs/value-graph.model';
import { API_BASE_URL } from 'src/environments/environment';
import { ProjectService } from '../project.service';
import { LocationGroup } from 'src/app/shared/services/api-location-groups.service';
import { ControllerFilters } from 'src/app/core/models/view-filter.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationsFilter } from 'src/app/core/models/locations-filter.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Driver } from 'src/app/core/models/driver.model';


export interface KeyOption {
  location: Location;
  objectId: number;
  subObjectId: number;
  floor: LocationGroup;
  checked: boolean;
}

export interface LicenceData {
  isValid: boolean,
  validTo: string,
  reasonIfInvalid: any
}

@Injectable({
  providedIn: 'root'
})
export class ApiProjectService {

  projectReady$ =  new BehaviorSubject<boolean>(false);
  filteredFatLocationsIsReady$ = new BehaviorSubject<boolean>(false);
  allFatLocationsIsReady$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
    ) { }


    sortLocations(locations: Location[]) {
      locations.sort(function(x, y){
        return +Location.getLocationZone(x) - +Location.getLocationZone(y)
    })

    return locations;
    }
    // NEW REQUESTS

    getAllLocationSlim() {
      const filters = new LocationsFilter([1,2,3,4]);
      filters.controllerFilterType = 'slim'
      return this.http.post<Location[]>(API_BASE_URL + '/locations/filtered', filters).pipe(
          tap(locations => {
            locations = this.sortLocations(locations)
            if (!this.allFatLocationsIsReady$.value) {
              this.projectService.setAllLocations(locations)
            }
          })
        );
    }

    getAllLocationsFat() {
      const filters = new LocationsFilter([1,2,3,4]);
      filters.controllerFilterType = 'fat'
      return this.http.post<Location[]>(API_BASE_URL + '/locations/filtered', filters).pipe(
        tap( locations => {
          locations = this.sortLocations(locations)
          this.allFatLocationsIsReady$.next(true)
          this.projectService.setAllLocations(locations, true)
        })
      );
  }


    getFilteredLocationsSlim(filters: LocationsFilter) {
      filters.controllerFilterType = 'slim'
      return this.http.post<Location[]>(API_BASE_URL + '/locations/filtered', filters).pipe(
          tap(locations => {
            locations = this.sortLocations(locations)
            if (!this.filteredFatLocationsIsReady$.value) {
              this.projectService.setFilteredLocations(locations);
            }
          })
        );
    }

    getFilteredLocationsFat(filters: LocationsFilter) {
      filters.controllerFilterType = 'fat'
      return this.http.post<Location[]>(API_BASE_URL + '/locations/filtered', filters).pipe(
          tap(locations => {
            locations = this.sortLocations(locations)
            this.filteredFatLocationsIsReady$.next(true);
            this.projectService.setFilteredLocations(locations);
          })
        );
    }

    getLocationById(id: string) {
      const params = new HttpParams()
      .set('id', id.toString())
      .set('includeControllers', true)
      return this.http.get(`${API_BASE_URL}/locations/byId`,{params})
    }

    getLocationsWithBackupCard() {
      return this.http.get(`${API_BASE_URL}/cards/backup/locations`);
    }

    updateLocation(location: Location) {
      return this.http.put(`${API_BASE_URL}/locations`, location)
    }

    updateLocations(locations: Location[]) {
      return this.http.put(`${API_BASE_URL}/locations/multiple`, locations)
    }

    getAllIOCommonAreaLocations(type: 'slim' | 'fat') {
      let filters = new LocationsFilter([3]);
      filters.controllerFilterType = type
      return this.http.post<Location[]>(API_BASE_URL + '/locations/filtered', filters);
    }


    getAllAccessControlLocations(type: 'slim' | 'fat') {
      let filters = new LocationsFilter([2]);
      filters.controllerFilterType = type
      return this.http.post<Location[]>(API_BASE_URL + '/locations/filtered', filters);
    }

    getAllGuestRoomLocations(type: 'slim' | 'fat') {
      let filters = new LocationsFilter([1]);
      filters.controllerFilterType = type
      return this.http.post<Location[]>(API_BASE_URL + '/locations/filtered', filters);
    }
    // NEW REQUESTS END

    getObjects() {
      return this.http.get<ProjectObject[]>(API_BASE_URL + '/project/objects').pipe(
          tap(objects => {
              this.projectService.setObjects(objects);
          })
      );
    }

    getSubobjects() {
      return this.http.get<ProjectSubObject[]>(API_BASE_URL + '/project/subobjects').pipe(
          tap(subObjects => {
              this.projectService.setSubObjects(subObjects);
          })
      );
    }


  isProjectReady() {
    return this.projectReady$.asObservable();
  }



  changeProperty(designation: string, propertyId: number, newValue: number) {
        const headersVariable: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
        .set('designation', designation)
        .set('propertyId', propertyId.toString())
        .set('value', newValue.toString());
        const body = '';
        return this.http.post(`${API_BASE_URL}/equipment/`, `"${body}"`, { params, responseType: 'text', headers: headersVariable }
        );
  }

  changeMultiplePropertiesByGroup(groupId: number, body: {typeCode:number, value: number}[]){
    return this.http.post(API_BASE_URL + `/equipment/group/multiple/${groupId}`, body, { responseType: 'text'})
  }

  changeMultiplePropertiesByLocation(designation: string /* 0/0/101/0 */, body){
    const httpParams = new HttpParams()
    .set('designation', designation.toString())
    return this.http.post(API_BASE_URL + `/equipment/multiple`, body, {
       responseType: 'text',
       params: httpParams})
  }

  changeGroup(groupId: number, value: number, typeCode: number) {
    const params = new HttpParams()
    .set('value', value.toString())
    .set('groupId', groupId.toString())
    .set('typeCode', typeCode.toString());
    return this.http.post(`${API_BASE_URL}/equipment/group`, null, {params})
  }

  updateControllers(updatedControllers: Controller[]) {
   return this.http.put(`${API_BASE_URL}/project/controllers`, updatedControllers)
  }

  getGraph(body: ValueGraph) {
    return this.http.post<any>(API_BASE_URL + '/valuelogs', body).pipe(
        map( response => {
            return response;
        })
    );
}

getNotPolledControllers() {
  return this.http.get<any>(`${API_BASE_URL}/project/notpolling`).pipe(
    map( response => {
      {
        let notPolledControllers = []
        response.forEach( value => {
          notPolledControllers = [...notPolledControllers, ...value.controllersnotpolling]
        })
        this.projectService.setNotPolledControllers(notPolledControllers)
        return notPolledControllers;
      }
    })
  );
}

addToNotPolledControllers(controllers: Controller[]) {
  return this.http.post<any>(`${API_BASE_URL}/project/notpollinglist`, controllers).pipe(
    map( () => {
        this.getNotPolledControllers().subscribe()
    })
  );
}

stopPolling(controller: Controller) {
  return this.http.post<Controller>(`${API_BASE_URL}/project/stoppolling`, controller).pipe(
    map( () => {
      this.getNotPolledControllers().subscribe()
    })
  );
}

resumePolling(controller: Controller) {
  return this.http.post<Controller>(`${API_BASE_URL}/project/resumepolling`, controller).pipe(
    map( () => {
      this.getNotPolledControllers().subscribe()
    })
  );
}

stopPublishing(propertyId: number) {
  return this.http.post<any>(`${API_BASE_URL}/project/stoppublishing?propertyId=${propertyId}`, {})/* .pipe(
    map( () => {
      this.getNotPublishingList().subscribe()
    })
  ); */
}

resumePublishing(propertyId: number) {
  return this.http.post<any>(`${API_BASE_URL}/project/resumepublishing?propertyId=${propertyId}`, {})/* .pipe(
    map( () => {
      this.getNotPublishingList().subscribe()
    })
  ); */
}

stopPublishingByGroup(propertyType: number, groupId: number) {
  return this.http.post<any>(`${API_BASE_URL}/project/group/stoppublishing?groupId=${groupId}&typeCode=${propertyType}`, {})
}

resumePublishingByGroup(propertyType: number, groupId: number) {
  return this.http.post<any>(`${API_BASE_URL}/project/group/resumepublishing?groupId=${groupId}&typeCode=${propertyType}`, {})
}

getNotPublishingList() {
  return this.http.get<number[]>(`${API_BASE_URL}/project/notpublishing`)
}


getKeyOptionsAccessControllListForLocationId(locationId: number) {
  return this.http.get<KeyOption[]>(`${API_BASE_URL}/locations/aclistforlocid?locid=${locationId}`)
}

renameObject(body: ProjectObject) {
  return this.http.put(`${API_BASE_URL}/project/object`, body)
}

renameSubObject(body: any) {
  return this.http.put(`${API_BASE_URL}/project/subobject`, body)
}

syncHvacSettings(id: number) {
  return this.http.get<any>(`${API_BASE_URL}/project/controllers/synchvacsettings?id=${id}`)
}

getAllTags() {
  return this.http.get<any>(`${API_BASE_URL}/locations/tags`)
}

getProjectId() {
  return this.http.get(`${API_BASE_URL}/project/projectid`, {responseType: 'text'})
}

setProjectId(projectId: string) {
  const params = new HttpParams()
  .set('projectId', projectId.toString())
  return this.http.post<string>(`${API_BASE_URL}/project/projectid`, null, {params})
}

getLicence() {
  return this.http.get<LicenceData>(`${API_BASE_URL}/project/licencedata`)
}

setLicenceKey(licenceKey: string) {
  const params = new HttpParams()
  .set('licenceKey', licenceKey.toString())
  return this.http.post<string>(`${API_BASE_URL}/project/licence`, null, {params})
}

getAllDrivers() {
  return this.http.get<Driver[]>(`${API_BASE_URL}/project/drivers`)
}

resetDriver(driverDesignation: string) {
  return this.http.get<any>(`${API_BASE_URL}/project/drivers/reset?driverDesignation=${driverDesignation}`)
}

syncControllerProperties(body: any){
  return this.http.post<any>(`${API_BASE_URL}/project/controllers/synccontrollerproperties`, body);
}


updateHiddenProperties(body: {id: number, value: number}[]){
  return this.http.post<any>(`${API_BASE_URL}/project/hidden`, body);
}




}
