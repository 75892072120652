import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { SettingsPropData } from '../room-modal-settings/room-modal-settings.component';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-number-input-code',
  templateUrl: './number-input-code.component.html',
  styleUrls: ['./number-input-code.component.scss']
})
export class NumberInputCodeComponent implements OnInit, OnDestroy{
  @Input() set controllers(value: Controller[]) {
    this._controllers = value;
    this.targetValue = null;
  };
  _controllers: Controller[]

  // @Input() findPropFunction: (property: Property) => boolean;
  // @Input() findPropFunctionSecondaryA: (property: Property) => boolean;
  // @Input() findPropFunctionSecondaryB: (property: Property) => boolean;
  @Input() propData: SettingsPropData;

  inputDelay = 2000;
  resetInputDelay = 3000;
  targetValue: number;
  valueSet$ = new Subject<[number, Controller[]]>();
  valueSubscription = new Subscription();
  resetTargetSubscription = new Subscription();

  loadingElements$ = this.loadingService.getLoadingElements();

  constructor(
    private apiProjectService: ApiProjectService,
    private loadingService: LoadingService
    ) {}

  ngOnInit(): void {
    this.valueSubscription = this.valueSet$
      .pipe(
        debounceTime(this.inputDelay),
        )
      .subscribe((newValue: [number, Controller[]]) => {
        let property: Property;
        const parentController: Controller = newValue[1].find(
          (controller: Controller) => {
            property = controller.controllerProperties.$values.find(this.findPropFunction());

            return property;
          }
        );
        // this.valueChanged = false;
        const designation = parentController.designation;
        const propertyId = property.id;
        const trgtValue =
          this.propData.codeFromConstraintA && this.propData.codeToConstraintA &&
          this.propData.codeFromConstraintB && this.propData.codeToConstraintB
          ?
          this.getTargetValue(parentController, newValue[0]):
           newValue[0]
        this.loadingService.addToLoading(`${parentController.id}-${property.id}`)
        this.apiProjectService
          .changeProperty(designation, propertyId, trgtValue)
          .subscribe();
      });
    this.resetTargetSubscription = this.valueSet$
      .pipe(debounceTime(this.resetInputDelay))
      .subscribe(() => {
        this.targetValue = null;
      });
  }

  getTargetValue(parentController: Controller, trgtValue: number) {
    const secondaryPropA = parentController.controllerProperties.$values.find(this.findPropFunctionSecondaryA());
    const secondaryPropB = parentController.controllerProperties.$values.find(this.findPropFunctionSecondaryB());
    const secondaryAValue = secondaryPropA === undefined ? 0 : Number(secondaryPropA?.value);
    const secondaryBValue = secondaryPropB === undefined ? 0 : Number(secondaryPropB?.value);
    const maxTotal = 40;
    if (trgtValue + secondaryAValue + secondaryBValue <= maxTotal) {
      return trgtValue;
    } else {
      return maxTotal - secondaryAValue - secondaryBValue
    }
  }

  decrement() {
    // this.valueChanged = true;
    if (this.targetValue === null || this.targetValue === undefined) {
      let property: Property;
      this._controllers.find((controller: Controller) => {
        property = controller.controllerProperties.$values.find(this.findPropFunction());
        return property;
      });
      this.targetValue = property.value;
    }
    this.targetValue--;
    this.valueSet$.next([this.targetValue, structuredClone(this._controllers)]);
  }

  increment() {
    // this.valueChanged = true;
    if (this.targetValue === null || this.targetValue === undefined) {
      let property: Property;
      this._controllers.find((controller: Controller) => {
        property = controller.controllerProperties.$values.find(this.findPropFunction());
        return property;
      });
      this.targetValue = property.value;
    }
    this.targetValue++;
    this.valueSet$.next([this.targetValue, structuredClone(this._controllers)]);
  }

  findPropFunction () {
    return (property: Property) => {
      return ( property.type  >= this.propData.codeFrom) && (property.type <= this.propData.codeTo);
    }
  }


  findPropFunctionSecondaryA() {
    return (property: Property) => {
      return (property.type >= this.propData.codeFromConstraintA) && (property.type <= this.propData.codeToConstraintA);
    }
  }

  findPropFunctionSecondaryB () {
    return (property: Property) => {

      return (property.type >= this.propData.codeFromConstraintB) && (property.type <= this.propData.codeToConstraintB);
    }
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.valueSubscription.unsubscribe();
    }, this.resetInputDelay);
    this.resetTargetSubscription.unsubscribe();
  }
}
