import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { GroupedPermission } from 'src/app/core/models/permissions/grouped-permission.model';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'isPermissionAccessControl'
})
export class IsPermissionAccessControl implements PipeTransform {

  transform(locations: Location[], permission: GroupedPermission): boolean {

    if (permission.locationId !== null) {
      const location = locations.find( loc => loc.locationId === permission.locationId)
        if (location) {
          return Location.isAccessControl(location);
        }
      }
  }
}
