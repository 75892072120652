import { PipeTransform, Pipe } from '@angular/core';
import { User } from '../../core/models/user/user.model';

@Pipe({
    name: 'usersWithEmail'
  })
  export class UsersWithEmailPipe implements PipeTransform {
    transform(users: User[], searchText: string): any[] {
        if (!users) {
            return [];
        }
        if (!searchText) {
            return users;
        }
        searchText = searchText.toLowerCase();
        return users.filter(user => {
            const searchPattern = user.firstName?.toLowerCase() + ' ' + user.lastName?.toLowerCase();
            return user.email?.length > 0 && searchPattern.includes(searchText);;
        });
    }
  }