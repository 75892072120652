import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'userCardPermission'
})
export class UserCardPermissionPipe implements PipeTransform {

// we check can user create cards for himself or other users
  transform(user: User, signedInUser: User, cardAddForSelf: number, cardAdd: number): boolean {
    return (user?.userId === signedInUser?.userId && signedInUser?.havePermission(cardAddForSelf)) ||
    (user?.userId !== signedInUser?.userId && signedInUser?.havePermission(cardAdd));
  }

}
