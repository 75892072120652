import { LogStatus } from './log-status.enum';

export const LogStatusNameEn = new Map<number, string>([
    [LogStatus.UnknownStatus, 'Unknown Status'],
    [LogStatus.RejectDoorNotFound, 'Rejected - Door not found '],
    [LogStatus.RejectCardNotFound, 'Rejected - Card not found '],
    [LogStatus.RejectCardNotYetValid, 'Rejected - Card not yet valid '],
    [LogStatus.RejectCardExpired, 'Rejected - Card expired '],
    [LogStatus.RejectCardBlacklisted, 'Rejected - Card blacklisted '],
    [LogStatus.RejectNoPermission, 'Rejected - No permission '],
    [LogStatus.RejectByPermissionRule, 'Rejected - By permission rule '],
    [LogStatus.RejectByCounter, 'Rejected - By counter '],
    [LogStatus.RejectByAntiPassback, 'Rejected - By anti passback '],
    [LogStatus.StudentCardRegistered, 'Student card registered '],
    [LogStatus.AccessGranted, 'Access granted'],
    [LogStatus.ExitGrantedByConfiguration, 'Exit granted by configuration'],
    [LogStatus.EntryGrantedByConfiguration, 'Entry granted by configuration'],
    [LogStatus.ScheduledDoorUnlock, 'Scheduled door unlock'],
    [LogStatus.ScheduledDoorLock, 'Scheduled door lock'],
    [LogStatus.SynchronizedFromController, 'Synchronized from controller - '],
    [LogStatus.Exit, 'Exit - ']
    ]);
