import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ControllerCommisioningParam } from 'src/app/core/models/project/controller-commissioning-params';
import { ApiCcpService } from '../../services/api-ccp.service';
import { ApplyToModalComponent } from '../apply-to-modal/apply-to-modal.component';

@Component({
  selector: 'app-room-modal-service',
  templateUrl: './room-modal-service.component.html',
  styleUrls: ['./room-modal-service.component.scss'],
})
export class RoomModalServiceComponent implements OnInit {
  @Input() location: Location;
  // locations$: Observable<Location[]> = this.projectService.getFilteredLocations();
  selectedController: Controller;
  controllers: Controller[] = [];
  renderNumberInputs = true;
  controllerValues: number[] = [];
  controllerCommParams: ControllerCommisioningParam[] = [];
  selectedControllerCommParams: ControllerCommisioningParam[] = [];
  allParamsSelected: boolean;
  isValid: boolean;
  ccpsReady = false;

  constructor(
    private projectService: ProjectService,
    private apiProjectService: ApiProjectService,
    private apiCCPService: ApiCcpService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    this.getLocationByApi();
  }

  checkValidity(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = parseInt(inputElement.value, 10);
    this.isValid = inputElement.validity.valid && value >= 0 && value <= 255;
  }

  getLocationByApi() {
    this.ccpsReady = false;
    this.apiProjectService.getLocationById(this.location.locationId.toString()).subscribe((location: Location)=> {
      this.controllers = location.controllers.$values;
      this.selectController(this.controllers[0]);
      this.controllerCommParams = this.selectedController.controllerCommissioningParameters.$values;
      this.ccpsReady = true;
    });
  }

  addNewParam(){
    const controllerCommParam: ControllerCommisioningParam = {id : 0, memoryType: '', memoryPosition: 0, value: 0, description: ''};
    this.controllerCommParams.push(controllerCommParam);
  }

  deleteParam(index: number){
    let ccp = this.controllerCommParams.at(index);
    this.apiCCPService.deleteCCP([ccp]).subscribe(deletedCcp => {
    });
    this.controllerCommParams.splice(index, 1);
  }

  readParam(index: number){
    let ccp = this.controllerCommParams.at(index);
    ccp.controllerId = this.selectedController.id;
    this.apiCCPService.read([ccp]).subscribe((value: any) => {
      this.controllerValues[index] = value.customObject.$values[0].value;
    });
  }

  sendParam(index: number){
    let ccp = this.controllerCommParams.at(index);
    ccp.controllerId = this.selectedController.id;
    this.apiCCPService.send([ccp]).subscribe((value: any) => {
    });
  }

  readSelected(){
    this.apiCCPService.read(this.selectedControllerCommParams).subscribe((value: any) => {
      this.controllerValues = value.customObject.$values.map(ccpRead => {
        return ccpRead.value;
      });
    });
  }

  sendSelected(){
    this.apiCCPService.send(this.selectedControllerCommParams).subscribe(value => {
    });
  }

  save(){
    this.apiCCPService.addCCP(this.selectedControllerCommParams).subscribe(addedCcps => {
      this.getLocationByApi();
      this.selectedControllerCommParams = []; //emptying because of disabling save btn
    });
  }

  isParamSelected(i: number){
    const cont = this.controllerCommParams.at(i);
    if(this.selectedControllerCommParams.includes(cont)){
      return true;
    }else{
      return false;
    }
  }

  onSelectOneParam(index: number, event){
    var selected;
    if(event.detail.checked){
      selected = true;
    }else{
      selected = false;
    }
    let ccp = this.controllerCommParams.at(index);
    ccp.controllerId = this.selectedController.id;
    if(selected){
      this.selectedControllerCommParams.push(ccp);
    }else{
      const selectedIndex = this.selectedControllerCommParams.indexOf(ccp);
      this.selectedControllerCommParams.splice(selectedIndex, 1);
    }
  }

  onSelectAllParams(event){
    if(event.detail.checked){
      this.allParamsSelected = true;
    }else{
      this.allParamsSelected = false;
    }
    if(this.allParamsSelected){
      this.selectedControllerCommParams.push(...this.controllerCommParams);
    }else{
      this.selectedControllerCommParams = [];
    }
  }

  async openApplyToModal() {
    const modal = await this.modalController.create({
      component: ApplyToModalComponent,
      backdropDismiss: true,
      cssClass: 'apply-to-modal',
      showBackdrop: true,
      componentProps: {
        ccps: this.selectedControllerCommParams,
        selectedController: this.selectedController
      }
    });
    modal.onDidDismiss()
      .then((resp) => {
        if (resp.data) {

        }
      }
      );
    return await modal.present();
  }

  selectController(controller: Controller) {
    this.renderNumberInputs = false;
    this.selectedController = controller;
    this.apiProjectService.getLocationById(this.location.locationId.toString()).subscribe((location: Location)=> {
      this.controllers = location.controllers.$values;
      this.controllerCommParams = controller.controllerCommissioningParameters.$values;
      this.ccpsReady = true;
    });
    setTimeout(() => { // render is reset to reinit child components and restart ngoinit and ngodestroy of child components
      this.renderNumberInputs = true;
    }, 0);
  }
}
