<div class="login-full-screen" >
  <div *ngIf="!isLoggedIn() && forgotPassword===false" class="login-container">
    <div class="logo"></div>
    <div class="hotel-name">Hotel Name</div>
    <div class="login-title">{{'Log in to Rooms' | translate}}</div>

    <form class="flex-column flex-center width80 margin-top200" (ngSubmit)="onLogin(loginForm)" #loginForm="ngForm" >
      <div class="content-form-group width100">
        <label for="email">{{'Email' | translate}}:</label>
        <input
          type="text"
          id="email"
          placeholder="{{'Enter username' | translate}}"
          [(ngModel)]="user.email"
          [ngClass]="{ 'is-invalid': loginForm.controls.email?.errors !=null && loginForm.submitted }"
          name="email"
          required
          email>
        </div>
        <div
            class="invalid-input"
            *ngIf="loginForm.controls.email?.errors !=null && loginForm.submitted">
            {{'Email must be a valid email address' | translate}}
        </div>
      <div class="content-form-group width100 margin-top20">
        <label for="password">{{'Password' | translate}}:</label>
        <input
          type="password"
          id="password"
          placeholder="{{'Enter password' | translate}}"
          [(ngModel)]="user.password"
          [ngClass]="{ 'is-invalid': loginForm.controls.password?.errors !=null && loginForm.submitted }"
          name="password"
          required>
      </div>
      <div
          class="invalid-input"
          *ngIf="loginForm.controls.password?.errors !=null && loginForm.submitted">
          {{'Enter password' | translate}}
      </div>
      <button type="submit" class="btn-big">{{'Login' | translate}}</button>      
    </form>
    <div class="sublink clickable" (click)="forgotPassword = true;">{{'Forgot password?' | translate}}</div>

  </div>

  <div class="login-container"  *ngIf="!isLoggedIn() && forgotPassword && !passwordHasReset">
    <div class="logo"></div>
    <div class="hotel-name">Hotel Name</div>
    <div class="login-title">{{'Forgot password?' | translate}}</div>
    <!-- <div class="margin-top10" >{{'Enter your e-mail. A new password will be sent to you' | translate}}.</div> -->

    <form class="flex-column flex-center margin-top200" (ngSubmit)="onResetPassword(resetPassForm)" #resetPassForm="ngForm">
      <div class="content-form-group">
        <label for="email">{{'Email' | translate}}</label>
        <input
          type="text"
          id="emailForgotPassword"
          placeholder="{{'Enter e-mail' | translate}}"
          name="emailForgotPassword"
          [(ngModel)]="emailForgotenPassword"
          email
          required
          [ngClass]="{
             'is-invalid':
              resetPassForm.controls.emailForgotPassword?.errors !=null && resetPassForm.submitted }">
        </div>
        <div
          class="invalid-input"
          *ngIf="resetPassForm.controls.emailForgotPassword?.errors !=null && resetPassForm.submitted">
          {{'Email must be a valid email address' | translate}}
        </div>
      <button class="btn-big" >{{'Reset Password' | translate}}</button>
    </form>
    <div class="sublink clickable" (click)="backToLogin()">{{'Back to login' | translate}}</div>
  </div>

  <div class="login-container"  *ngIf="!isLoggedIn() && forgotPassword && passwordHasReset">
    <div class="logo"></div>
    <div class="hotel-name">Hotel Name</div>
    <h1 class="login-title">{{'Password reset' |translate}}</h1>
    <div class="margin-left25 margin-right25 margin-bottom20">{{'A new password has been sent to your e-mail. Change your password as soon as you log in.' | translate}}</div>
    <div class="sublink clickable" (click)="backToLogin()">{{'Back to login' | translate}}</div>
  </div>

  <div *ngIf="isLoggedIn()" class="flex-column flex-center margin-top160">
    <h1 class="white">{{'User logged in.' | translate}}</h1>
    <h4 class="white clickable" routerLink="/rooms" >{{'Continue' | translate}}</h4>
  </div>

  <div class="flex-column-center-full margin-top30 powered-by">
    <div>powered by</div>
    <div>ADRIA ELECTRONIC</div>
  </div>
</div>
