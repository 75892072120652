import { Injectable } from '@angular/core';
import { UserLanguage } from 'src/app/core/models/user-language.model';

@Injectable({
  providedIn: 'root'
})

export class UserSettingsService {


  defaultUserLanguage: UserLanguage = {
    language: 'en'
  };

  constructor() {}


  getLanguage(): 'en' | 'hr' {
    const userLanguage: string = localStorage.getItem('irooms_user_language');
    if (userLanguage) {
      const abasUserLanguage: UserLanguage = JSON.parse(userLanguage);
      return abasUserLanguage.language;
    } else {
      localStorage.setItem('irooms_user_language', JSON.stringify(this.defaultUserLanguage));
      return this.defaultUserLanguage.language;
    }
  }

  setLanguage(newLanguage: 'en' | 'hr') {
    const userLanguage: string = localStorage.getItem('irooms_user_language');
    if (userLanguage) {
      const newUserLanguage: UserLanguage = JSON.parse(userLanguage);
      newUserLanguage.language = newLanguage;
      localStorage.setItem('irooms_user_language', JSON.stringify(newUserLanguage));
    } else {
      const newUserLanguage: UserLanguage = this.defaultUserLanguage;
      newUserLanguage.language = newLanguage;
      localStorage.setItem('irooms_user_language', JSON.stringify(newUserLanguage));
    }
  }

}
