import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'userHasPermission'
})
export class UserHasPermissionPipe implements PipeTransform {

  transform(user: User, permissionId: number): boolean {
    const u = Object.assign(new User(), user)
   return u.havePermission(permissionId)
  }

}
