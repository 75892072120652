  <div class="margin10">
    <!-- <div class="modal-header">
      <h3>Location: {{ locationInput.name }}</h3>
    </div> -->
    <div class="margin5">
      <strong>{{'Location id' | translate}}: </strong>
      {{ location.locationId }}
    </div>
    <div class="margin5">
      <strong>{{'Name' | translate}}: </strong> <input [(ngModel)]="location.name" type="text" />
    </div>
    <div class="margin5">
      <strong>{{'Full location id' | translate}}: </strong>
      <input [(ngModel)]="location.fullLocationId" type="text" />
    </div>
    <div class="margin5">
      <strong>{{'Rules' | translate}}: </strong>{{ location.rules | json }}
    </div>
    <input
      class="option-input-sm"
      type="checkbox"
      (change)="clickAllowEveryCardInSystem($event.currentTarget.checked)"
      [checked]="location.allowEveryCardInSystem"
    />
    <b>{{'Allow every card in system' | translate}}</b>
    <br />
    <input
      class="option-input-sm"
      type="checkbox"
      (change)="clickAllowEveryCard($event.currentTarget.checked)"
      [checked]="location.allowEveryCard"
    />
    <b>{{'Allow every card' | translate}}</b>
    <hr class="margin-top10" />
    <strong>{{'Location location groups' | translate}}:</strong>
    <div>
      <strong>$type:</strong>{{ location.locationLocationGroups.$type | json }}
    </div>
    <div>
      <strong>$values:</strong
      >{{ location.locationLocationGroups.$values | json }}
    </div>
    <hr class="margin-top10" />
    <strong>{{'Permissions' | translate}}:</strong>
    <div><strong>$type:</strong>{{ location.permissions.$type | json }}</div>
    <div>
      <strong>$values:</strong>{{ location.permissions.$values | json }}
    </div>
    <hr class="margin-top10" />

    <div class="modal-button-box">
<!--       <button (click)="openDeleteModal(location)">Delete</button>
 -->      <button (click)="decisionClick(false)">{{'Cancel' | translate}}</button>
      <button
        (click)="decisionClick(true)"
        [ngClass]="[submitted ? 'btn-disabled no-click' : '']"
      >
        {{'Save' | translate}}
      </button>
    </div>
  </div>
