export enum SoftwarePermissionId {
    Login = 1,
    UsersView,
    UsersUpdate,
    RolesView,
    RolesUpdate,
    LocationsRemoteUnlock,
    LocationGroupsUpdate,
    LocationGroupsActivatePresets,
    LocationSettings,
    LocationTags,
    CardsStaffUpdate,
    CardsGuestUpdate,
    AutomationsView,
    AutomationsUpdate,
    AuditLogView,
    AccessLogView,
    AlarmsLogView,
    ValueLogView,
    AnalyticsView,
    AlarmsSosReceive,
    AlarmsRoomBurglaryReceive,
    AlarmsRoomSafeBurglaryReceive,
    AlarmsTemperatureDifferenceReceive,
    AlarmsWaterFloodReceive,
    AlarmsPowerOutageReceive,
    AlarmsDCOutputsFailureReceive,
    AlarmsControllerTemperatureReceive,
    AlarmsWindowOpenTooLongReceive,
    AlarmsDoorOpenTooLongReceive,
    AlarmsRoomSafeCommErrorReceive,
    AlarmsCommunicationErrorReceive,
    Settings,
    SettingsPresets,
    SettingsManageRoomOverview,
    SettingsDefaults,
    SettingsLicensing,
    SettingsEncoders,
    SettingsCardEventLog,
    SettingsSubscription,
    ObjectViewAll
}
