import { Pipe, PipeTransform } from '@angular/core';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getFloorsInSubobject'
})
export class GetFloorsInSubobjectPipe implements PipeTransform {

  transform(fList: LocationGroup[], selectedSubobject: ProjectSubObject): LocationGroup[] {
    const targetObj: string = selectedSubobject.designation.split('/')[0];
    const targetSubobj: string = selectedSubobject.designation.split('/')[1];

    const floorsInObjectAndSuboject = fList.filter((flo)=> {
      return flo.locations.some((location: Location)=> {
        const floorObject = Location.getLocationObject(location)
        const floorSubobject = Location.getLocationSubObject(location)
        return targetObj === floorObject && targetSubobj === floorSubobject
      })
    })
    return floorsInObjectAndSuboject;      
  }
}