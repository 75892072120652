import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'propExistInController'
})
export class PropExistInControllerPipe implements PipeTransform {

  transform(controller: Controller, propFunct: Function, checkForHidden = false, searchValue?: string, propsHr?: Property[]):  boolean {
    if(propsHr && propsHr.length !== 0){
      let exists = false;
      propsHr.find( prop => {
        let target: Property;
        target = propFunct(prop);
        if (searchValue != undefined) {
          if (target && (prop.name.toLowerCase().includes(searchValue.toLowerCase()) || searchValue == '')) {
            exists = true;
            if (checkForHidden && prop.hidden) {
              exists = false;
            }
            return exists;
          }
        } else {
          if (target) {
            exists = true;
            if (checkForHidden && prop.hidden) {
              exists = false;
            }
            return exists;
          }
        }
      })
      return exists;
    }else{
      let exists = false;
      controller.controllerProperties.$values.find( prop => {
        let target: Property;
        target = propFunct(prop);
        if (searchValue != undefined) {
          if (target && (prop.name.toLowerCase().includes(searchValue.toLowerCase()) || searchValue == '')) {
            exists = true;
            if (checkForHidden && prop.hidden) {
              exists = false;
            }
            return exists;
          }
        } else {
          if (target) {
            exists = true;
            if (checkForHidden && prop.hidden) {
              exists = false;
            }
            return exists;
          }
        }
      })
      return exists;
    }
  }
}
