          <div class="flex-row-start-end">
                <div class="flex-row-center-full  ">
                        <div class="flex-column-center-full">
                            <h3 class="center margin-top5 ">
                                {{'Time from' | translate}}
                            </h3>
                            <div class="date-content">
                            <input
                                [owlDateTimeTrigger]="dtFrom"
                                [owlDateTime]="dtFrom"
                                [(ngModel)]="selectedDateTime[0]"
                                [selectMode]="'single'"
                                class="time-input"
                                placeholder="Time"
                            />
                            <owl-date-time
                            #dtFrom
                            [stepMinute]='15'
                            [pickerType]="'timer'"
                            (afterPickerClosed)="onCloseFrom()"
                            ></owl-date-time>
                        </div>
                        </div>
                    </div>
                <div class="flex-row-center-full ">
                    <div class="flex-column-center-full">
                        <h3 class="center margin-top5 ">
                            {{'Time to' | translate}}
                        </h3>
                        <div class="date-content">
                            <input
                              [owlDateTimeTrigger]="dtTo"
                              [owlDateTime]="dtTo"
                              [(ngModel)]="selectedDateTime[1]"
                              [selectMode]="'single'"
                              class="time-input"
                              placeholder="Time"
                            />
                          <owl-date-time
                            #dtTo
                            [stepMinute]='15'
                            [pickerType]="'timer'"
                            (afterPickerClosed)="onCloseTo()"
                          ></owl-date-time>
                      </div>
                    </div>
                </div>
                <ion-button size="default" class="square-secondary" color="primary" (click)="sendTime()">{{'Accept' | translate}}</ion-button>
                <div>
                    <div *ngIf="loading" class="loading-indicator"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>