import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'propIsHvac',
})
export class PropIsHvacPipe implements PipeTransform {

  transform(prop: any): boolean {
    if (!prop) {
      return false
    }
    return Property.isHvacHeatCool(prop)
  }

}
