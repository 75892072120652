import { Pipe, PipeTransform } from '@angular/core';
import { Role } from 'src/app/core/models/role.model';

@Pipe({
  name: 'getRole'
})
export class GetRolePipe implements PipeTransform {

  transform(roles: Role[], roleId: number): Role {
    return roles.find((role)=> {
      return Number(role.roleId) === Number(roleId);
    });
  }

}
