import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Controller } from 'src/app/core/models/controller.model';

@Component({
  selector: 'app-all-props',
  templateUrl: './all-props.component.html',
  styleUrls: ['./all-props.component.scss']
})
export class AllPropsComponent implements OnInit {

  @Input() controllers: Controller[];

  constructor(private popoverController: PopoverController) { }

  ngOnInit(): void {

  }

}
