import { Component,  HostListener, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { PaginationData } from 'src/app/core/models/pagination-data.model';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { ApiAccessLogsService } from '../../services/http/api-access-logs.service';
import { LogStatus } from 'src/app/core/models/logs/log-status.enum';
import { LogStatusNameEn } from 'src/app/core/models/logs/log-status-name-en.map';
import { User } from 'src/app/core/models/user/user.model';
import { UsersService } from 'src/app/modules/users/services/users.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { LogStatusNameHr } from 'src/app/core/models/logs/log-status-name-hr.map';
import { HttpResponse } from '@angular/common/http';
import { Controller } from 'src/app/core/models/controller.model';
import moment from 'moment';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';
import { AlertController } from '@ionic/angular';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';
import { DemoModeData, DemoModeService } from 'src/app/shared/services/demo-mode.service';
import { DEMO_MODE } from 'src/environments/environment';


@Component({
  selector: 'app-access-logs',
  templateUrl: './access-logs.component.html',
  styleUrls: ['./access-logs.component.scss']
})
export class AccessLogsComponent implements OnInit {

  @Input() controllers: Controller[];
  @Input() singleRoom: boolean;
  mainController: Controller;
  accessLogData: AccessLog[] = [];
  paginationData: PaginationData = new PaginationData();

  logStatus = LogStatus;
  logStatusName = LogStatusNameEn;
  prefix: string;

  queryParams = {
    pageNumber: 1,
    pageSize: 50,
    includeArchive: false
  };
  requestBody: SsFilterSort = new SsFilterSort();
  showAdditionalOptions = false;
  loading = false;

  additionalFilter = false;

  users: User[] = [];
  userFilteringSearchValue = '';
  usersInFilter: User[] = [];

  locations: Controller[] = [];
  locationFilteringSearchValue = '';
  locationsInFilter: Controller[] = [];
  localeId: string;
  language: string;
  downloadInProgress = false;

  constructor(
    private apiAccessLogService: ApiAccessLogsService,
    private usersService: UsersService,
    private apiUsers: ApiUsersService,
    private userLanguageService: UserSettingsService,
    private apiProjectService: ApiProjectService,
    private userSettingsService: UserSettingsService,
    private alertController: AlertController,
    private pipe: CustomTranslatePipe,
    private demoModeService: DemoModeService) { }

  ngOnInit(): void {
    if (DEMO_MODE) {
      this.demoModeService.getData().subscribe( (value:DemoModeData) => {
        this.paginationData = value.Pagination
        this.accessLogData = value.AccessLog
        if (this.singleRoom) {
          this.accessLogData = this.accessLogData.filter (data => data.locationId == this.mainController.locationId)
        }
        this.loading = false
      })
}
    this.localeId = this.userSettingsService.getLanguage();
    if (this.localeId === 'hr') {
      this.logStatusName = LogStatusNameHr;
      this.prefix = 'Ulaz - '
    } else {
      this.prefix = 'Entry - '
    }
    this.getUsers();
    if (this.singleRoom) {
      this.mainController = Controller.getMainController(this.controllers)
      const designationFilter: string[] = this.controllers.map((cont: Controller)=> {
        return cont.designation
      })
      this.requestBody.controllerDesignations = designationFilter;
    }

    this.onSubmit(1, 'DSC|TIME');
    this.language = this.userLanguageService.getLanguage()
  }

  getUsers() {
    this.loading = true;
    this.users = this.usersService.getUsers();
    this.apiUsers.getUsers().subscribe((users: User[]) =>  {
      this.users = users;
      this.loading = false;
    });
  }

  // controllerChanged(controller: Controller) {
  //   this.mainController = controller;
  //   this.onSubmit(1, 'DSC|TIME');
  // }

  // getMainController() {
  //   this.controllers.find( (controller: Controller) => {
  //     const lastElement = controller.locationId.substring(controller.locationId.length - 1);
  //     if (lastElement === '0') {
  //       this.mainController = controller;
  //       return;
  //     }
  //   })
  // }

  searchFilterReady(searchFilter: string) {
    this.requestBody.filtering = searchFilter;
    this.onSubmit();
  }

  setDateTimeFromTo(selectedDateTime: any[]) {
    this.requestBody.dateTimeFrom = selectedDateTime[0];
    this.requestBody.dateTimeTo = selectedDateTime[1];
    this.onSubmit();
  }

  // locationFilterSelected(selectedController: Controller) {
  //   const controllerIsInList: boolean = this.locationsInFilter.some((cont)=> {
  //     return cont.locationId === selectedController.locationId
  //   })
  //   if (!controllerIsInList) {
  //     this.locationsInFilter.push(selectedController)
  //     this.requestBody.locationDesignations.push(selectedController.designation);
  //   }
  //   this.locationFilteringSearchValue = '';
  //   this.onSubmit();
  // }

  // onRemoveControllerLocationFromFilter(controller: Controller) {
  //   this.locationsInFilter = this.locationsInFilter.filter( loc => loc.locationId !== controller.locationId);
  //   this.requestBody.locationDesignations = this.requestBody.locationDesignations.filter (locDes => locDes !== controller.designation);
  //   this.onSubmit();
  // }

  usersFilterSelected(user: User) {
    if (!this.usersInFilter.includes(user)) {
      this.usersInFilter.push(user);
      this.requestBody.users.push(user.userId);
    }
    this.userFilteringSearchValue = '';
    this.onSubmit();
  }

  onRemoveUserFromFilter(user: User) {
    this.usersInFilter = this.usersInFilter.filter( element => element.userId !== user.userId);
    this.requestBody.users = this.requestBody.users.filter (element => element !== user.userId);
    this.onSubmit();
   }


   @HostListener('document:click', ['$event'])
   documentClick(event: MouseEvent) {
     const el = event.target as HTMLElement;
     if (!el.classList.contains('user-dropdown') || !el.parentElement.classList.contains('user-dropdown')) {
       this.userFilteringSearchValue = '';
       this.locationFilteringSearchValue = '';
     }
   }

   onSort(sortBy: string) {
    if (this.requestBody.sorting === 'ASC|' + sortBy) {
      this.requestBody.sorting = 'DSC|' + sortBy;
    } else {
      this.requestBody.sorting = 'ASC|' + sortBy;
    }
    this.onSubmit(this.queryParams.pageNumber);
  }


  onSubmit(pageNumber: number = 1, pageSort?: string) {
    this.loading = true;
    if (DEMO_MODE) {
      this.loading = false
    }
    if (pageSort) {
       this.requestBody.sorting = pageSort;
      }
    this.queryParams.pageNumber = pageNumber;

      this.apiAccessLogService.getAccessLogs(this.requestBody, this.queryParams).subscribe((resp: HttpResponse<AccessLog[]>) => {
      this.paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      this.accessLogData = resp.body;
        // console.log(this.accessLogData)
      // filtering for single controller in room
      // if (this.singleRoom) {
      //   this.accessLogData = this.accessLogData.filter( accessData => {
      //     if (accessData.locationName.includes(this.mainController.name)) {
      //       return true;
      //     }
      //   })
      // }
      this.loading = false;
    });
  }

  async confirmDownload(fileType: string) {
    let message = '';
    if (fileType === 'PDF') {
      message = this.pipe.transform('Maximum event number is 5000')
    }
    const alert = await this.alertController.create({
      header: this.pipe.transform('Do you want to download ') + fileType + this.pipe.transform(' file?'),
      message: message,
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Accept'),
          handler: () => {
            this.downloadInProgress = true;
            if (fileType === 'CSV') {
              this.exportToCSV()
            } else if (fileType === 'PDF') {
              this.exportToPDF()
            }

          }
        }
      ],
    });
    await alert.present();

  }

  exportToCSV() {
    this.apiAccessLogService.exportLogToCSV(this.requestBody, this.language).subscribe( response => {
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      const objectURL = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.href = objectURL
      downloadLink.setAttribute('download', 'AccessLogExport.csv');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(objectURL);
      this.downloadInProgress = false;
    })
  }

  exportToPDF() {
    this.apiAccessLogService.exportLogToPDF(this.requestBody, this.language).subscribe( response => {
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      const objectURL = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.href = objectURL
      downloadLink.setAttribute('download', 'AccessLogExport.pdf');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(objectURL);
      this.downloadInProgress = false;
    })
  }

  getTextualValueFromStatus(status: number) {
    if (status == 0) {
      if (this.localeId == 'en') {
        return 'Arrival'
      } else {
        return 'Dolazak'
      }
    } else if (status == 100) {
      if (this.localeId == 'en') {
        return 'Card deponed'
      } else {
        return 'Kartica deponirana'
      }
    } else if (status == 200) {
      if (this.localeId == 'en') {
        return 'Departure'
      } else {
        return 'Odlazak'
      }
    } else if (status == 10) {
      if (this.localeId == 'en') {
        return  'Arrival - driver startup'
      } else {
        return  'Dolazak - driver pokretanje'
      }
      }
      else if (status == 110) {
        if (this.localeId == 'en') {
          return 'Card deponed - driver startup'
        } else {
          return 'Kartica deponirana - driver pokretanje'
        }
      }
      else if (status == 210) {
        if (this.localeId == 'en') {
          return 'Departure - driver startup'
        } else {
          return 'Odlazak - driver pokretanje'
        }
      }
      else {
      if (this.localeId == 'en') {
        return 'Unknown status'
      } else {
        return 'Nepoznat status'
      }
    }
  }
}
