import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-days-modal',
  templateUrl: './select-days-modal.component.html',
  styleUrls: ['./select-days-modal.component.scss']
})
export class SelectDaysModalComponent implements OnInit {

  @Input() modalContent: string;
  numbers: number[];
  selectedDays: number[];



  constructor(private modalController: ModalController) { }

  ngOnInit(): void {
    const splitString = this.modalContent.split(',')
    this.selectedDays = splitString.map(Number)
    this.numbers = Array(32).fill(0).map((x, i) => i).slice(1);
  }

  decisionClick(decision: boolean) {
    if (decision) {
      this.selectedDays = this.selectedDays.filter( element => element != 0);
      this.modalController.dismiss(this.selectedDays);
    } else {
      this.modalController.dismiss();
    }
  }


  isDaySelected(num: number){
    return this.selectedDays.includes(num);
  }

  onClickDay(num: number) {
    const index = this.selectedDays.findIndex((selectedDay: number) => {
      return selectedDay === num;
    });
    if (index === -1) {
      this.selectedDays.push(num);
    } else {
      this.selectedDays.splice(index, 1);
    }
  }


  clearSelectedDays(){
    this.selectedDays = [];
  }

}
