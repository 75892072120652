import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { UserPermissionGuard } from './core/guards/user-permission.guard';
import { LoginComponent } from './pages/login/login.component';
import { LicenseComponent } from './pages/license/license.component';
import { ProjectIdInputComponent } from './pages/project-id-input/project-id-input.component';
import { RestrictedAccessComponent } from './pages/restricted-access/restricted-access.component';


const routes: Routes = [
    { path: '', redirectTo: 'rooms', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'license', component: LicenseComponent },
    { path: 'projectID', component: ProjectIdInputComponent },
    // { path: 'home', canActivate: [AuthGuard], component: HomeComponent },
    { path: 'users', canActivate: [AuthGuard, UserPermissionGuard],
      loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule) },
    { path: 'roles', canActivate: [AuthGuard, UserPermissionGuard],
      loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule) },
    { path: 'rooms', canActivate: [AuthGuard, UserPermissionGuard],
      loadChildren: () => import('./modules/rooms/rooms.module').then(m => m.RoomsModule) },
    { path: 'logs', canActivate: [AuthGuard, UserPermissionGuard],
      loadChildren: () => import('./modules/logs/logs.module').then(m => m.LogsModule) },
    { path: 'alarms',  canActivate: [AuthGuard, UserPermissionGuard],
      loadChildren: () => import('./modules/alarms/alarms.module').then(m => m.AlarmsModule) },
    { path: 'settings', canActivate: [AuthGuard, UserPermissionGuard],
      loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule) },
    { path: 'cards' , canActivate: [AuthGuard, UserPermissionGuard],
    loadChildren: () => import ('./modules/cards/cards.module').then( m => m.CardsModule) },
    // { path: 'locations', canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    // loadChildren: () => import('./modules/locations/locations.module').then(m => m.LocationsModule) },
    { path: 'common-area',  canActivate: [AuthGuard, UserPermissionGuard],
    loadChildren: () => import('./modules/corridors/corridors.module').then(m => m.CorridorsModule) },
    { path: 'schedule', canActivate: [AuthGuard, UserPermissionGuard],
    loadChildren: () => import('./modules/schedule/schedule.module').then(m => m.ScheduleModule) },
    { path: 'groups', canActivate: [AuthGuard, UserPermissionGuard],
    loadChildren: () => import('./modules/groups/groups.module').then(m => m.GroupsModule) },
    { path: 'hvac-mode', canActivate: [AuthGuard, UserPermissionGuard],
     loadChildren: () => import('./modules/hvac-mode/hvac-mode.module').then(m => m.HvacModeModule) },
    { path: 'how-to', canActivate: [AuthGuard, UserPermissionGuard],
    loadChildren: () => import('./modules/how-to/how-to.module').then(m => m.HowToModule) },
    { path: 'restricted-access', component: RestrictedAccessComponent },
    { path: '**', redirectTo: 'rooms' }

  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
