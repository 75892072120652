import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HvacModeType } from 'src/app/core/models/hvac-mode-type.enum';
import { HvacMode } from 'src/app/core/models/hvac-modes/hvac-mode.model';

@Injectable({
  providedIn: 'root'
})
export class HvacModesService {
  private hvacModes$ = new BehaviorSubject<HvacMode[]>([]);
  private activeHvacModeGlobal$ = new BehaviorSubject<HvacModeType>(null);
  constructor() { }

  setHvacModes(hvacModes: HvacMode[]){
    this.hvacModes$.next(hvacModes)
  }

  getHvacModes() {
    return this.hvacModes$.asObservable();
  }


  setActiveHvacModeGlobal(activeHvacModeGlobal: HvacModeType){
    this.activeHvacModeGlobal$.next(activeHvacModeGlobal)
  }

  getActiveHvacModeGlobal() {
    return this.activeHvacModeGlobal$.asObservable();
  }




}
