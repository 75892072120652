import { Pipe, PipeTransform } from "@angular/core";
import { Controller } from "src/app/core/models/controller.model";

@Pipe({
    name: 'isHvacCommonArea'
})
export class IsHvacCommonAreaPipe  implements PipeTransform {
    transform(controller: Controller): boolean {
        return Controller.isHvacCommonArea(controller);
    }
}