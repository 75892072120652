import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'showRelayOutputSelector'
})
export class ShowRelayOutputSelectorPipe implements PipeTransform {

  transform(controllers: Controller[], relayOutputNo): boolean {

    const byRentedprops = [];
    const forcedOnProps = [];
    const forcedOffProps = [];

      controllers.forEach((cont)=> {
        cont.controllerProperties.$values.forEach((prop)=> {
          if (prop.mqttTopic.split('/')[3] !== relayOutputNo.toString()) {
            return
          }
          if (Property.isRelayOutputByRentedOrPresence(prop)) {
            byRentedprops.push(prop)
          } else if (Property.isRelayOutputForceOn(prop)) {
            forcedOnProps.push(prop)
          } else if (Property.isRelayOutputForceOff(prop)) {
            forcedOffProps.push(prop)
          }
        })
      })



    if ((byRentedprops.length > 0 && forcedOnProps.length > 0) ||
    (byRentedprops.length > 0 && forcedOffProps.length > 0) ||
    (forcedOnProps.length > 0 && forcedOffProps.length > 0)) {
      return true
    } else {
      return false
    }



  }

}
