import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'filterLocationsWithProperty'
})
export class FilterLocationsWithPropertyPipe implements PipeTransform {

  transform(locations: Location[], propFunct: Function):  Location[] {
    locations = locations.filter( location => {
      return location.controllers.$values.some(  controller => {
        return controller.controllerProperties.$values.find( prop => {
          let target: Property;
          target = propFunct(prop);
          if (target) {
            return true;
          }
        })
    })
    })
    
    return locations
  }

}


