import { Pipe, PipeTransform } from '@angular/core';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'searchControllers'
})
export class SearchControllersPipe implements PipeTransform {

  transform(location: Location[], keyword: string):  Location[] {
    return location.filter((locations: Location) => {
      return locations.controllers.$values.some((cont) => {
        return cont.name.toLowerCase().includes(keyword.toLowerCase());
      });
    });
  }

}


