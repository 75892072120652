import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';

@Pipe({
  name: 'getSubobjects'
})
export class GetSubobjectsPipe implements PipeTransform {
  transform(availableSubobjects: ProjectSubObject[], selectedObject: ProjectObject, selectedSubobject?: ProjectSubObject):
   ProjectSubObject[] {
    if (selectedSubobject) {
      return [selectedSubobject];
    }
    if (selectedObject) {
      return availableSubobjects.filter((subObj)=> {
        return subObj.projectObjectId === selectedObject.projectObjectId
      })
    }
    return availableSubobjects;

    //
    /* if (subobjNumber === null || subobjNumber === undefined || Number(subobjNumber) === -1) {
      return Project.getSubobjects(project, objNumber, subobjNumber)
    }

     else {
      return [Number(subobjNumber)]
    }
 */
  }
}
