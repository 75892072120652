import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { RoomViewSize, RoomsOverviewService } from 'src/app/shared/services/rooms-overview.service';

@Component({
  selector: 'app-access-control-card',
  templateUrl: './access-control-card.component.html',
  styleUrls: ['./access-control-card.component.scss']
})
export class AccessControlCardComponent implements OnInit {
  @Input() location: Location;
  controllers: Controller[];
  roomViewSize$: Observable<RoomViewSize> = this.roomsOverviewService.getViewSize();
  Property = Property;
  showTags = false

  constructor(private roomsOverviewService: RoomsOverviewService) { }

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values;
  }

}
