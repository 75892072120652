import { Pipe, PipeTransform } from '@angular/core';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { IValueDictionaryItem } from '../components/room-modal-log/room-modal-log.component';

@Pipe({
  name: 'translateValue'
})
export class TranslateValuePipe implements PipeTransform {

  transform(propData: {propertyType: number, value: string}, translateValues: IValueDictionaryItem[], cardTypes: CardType[]): string {
    const propTranslation = translateValues.find((vDictItem: IValueDictionaryItem)=> {
      return (Number(propData.propertyType) >= Number(vDictItem.CodeFrom)) &&  (Number(propData.propertyType) <= Number(vDictItem.CodeTo));
    })

    if (!propTranslation) {
      return propData.value
    }


    if (propTranslation.mode === 'num') {
      return propData.value;
    }

    if (propTranslation.mode === 'bool') {
      if (propData.value.toString() === '1' ) {
        return propTranslation.activeName
      }  else if (propData.value.toString() === '0') {
        return propTranslation.inactiveName
      } else {
        return propData.value;
      }
    }

     if (propTranslation.mode === 'custom(cardType)') {
      const cardType = cardTypes.find((type: CardType)=> {
        return Number(propData.value) === Number(type.code)
      })
      return cardType.name;
    }

    if (propTranslation.mode === 'custom(openTooLong)') {
      if (propData.value.toString() === '1' ) {
        return 'Open'
      }  else if (propData.value.toString() === '0') {
        return 'Closed'
      } else if (propData.value.toString() === '9') {
        return 'Open too long'
      } else {
        return propData.value;
      }
    }


    return propData.value
  }

}
