import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Location, Tag } from 'src/app/core/models/project/location.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-room-tag-popover',
  templateUrl: './room-tag-popover.component.html',
  styleUrls: ['./room-tag-popover.component.scss']
})
export class RoomTagPopoverComponent implements OnInit {

  @Input() location: Location;
  @Input() existingTags: Tag[];
  originalLocation: Location
  newTagName: string;
  showDropdown = false;

  constructor(private popoverController: PopoverController,
              private apiProjectService: ApiProjectService) {}

  ngOnInit(): void {
    this.originalLocation = structuredClone(this.location)

    this.existingTags = this.existingTags.filter ( (tag: Tag) => {
      return !this.location.locationTags.$values.some( value => {
        return value.tag == tag.tag;
      })
    })
  }

  onSave() {
    if (this.newTagName != '' && this.newTagName != undefined) {
      this.addNewTag()
    }
    this.apiProjectService.updateLocation(this.location).subscribe( () => {
      this.popoverController.dismiss();
    });
  }

  onCancel() {
    this.location.locationTags = this.originalLocation.locationTags
    this.popoverController.dismiss();
  }

  addNewTag(tagName?: string) {
      if (tagName) {
        this.newTagName = tagName;
      }
      if (this.newTagName != '' && this.newTagName != undefined) {
        this.location.locationTags.$values.push({tag: this.newTagName});
        this.newTagName = '';
      }

    this.showDropdown = false;
  }

  removeTagFromController(tagToRemove: Tag) {
    this.location.locationTags.$values = this.location.locationTags.$values.filter ( (tag:Tag) => tag.tag != tagToRemove.tag)
    this.existingTags.push(tagToRemove);
  }

  addExistingTagToController(tagToAdd: Tag) {
    this.existingTags = this.existingTags.filter( tag => tag.tag != tagToAdd.tag)
    this.location.locationTags.$values.push({tag: tagToAdd.tag})
  }

  onFocus() {
    this.showDropdown = true;
  }

  onBlur() {
    setTimeout( () =>{
      this.showDropdown = false;
    },200)
  }
}
