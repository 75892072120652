export class Setting { // controller setting
  Id: number;
  // settingName: string;
  // settingValue: string;
  id: number;
  name: string;
  value: string;
  controller?: any;

  constructor() {}

  static offlineTimer(setting: Setting) {
    return setting.name === 'OfflineTimer';
  }
}
