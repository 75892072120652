import { Pipe, PipeTransform } from '@angular/core';
import { Location } from 'src/app/core/models/project/location.model';


@Pipe({
    name: 'filterByControllerType',
  })
export class FilterByControllerType implements PipeTransform {

    transform( locations: Location[],  type: 'isGuestRoom' | 'isAccessControl' | 'isIOCommonArea'| 'isHvacCommonArea',  designation? : string) : Location[] {
        if (!locations) {
            return null;
          }

          locations = locations.filter( loc => {
              const filterFunct = this.getControllerTypeFilterFunction(type)
              return filterFunct(loc);
          })
          if (designation) {
            locations = this.filterByDesignation(locations, designation);
          }
        return locations;
    }

    getControllerTypeFilterFunction(filterName: 'isGuestRoom' | 'isAccessControl' | 'isIOCommonArea'| 'isHvacCommonArea'):
     (location: Location) => boolean {
      switch (filterName) {
        case 'isAccessControl': {
          return Location.isAccessControl;
        }
        case 'isGuestRoom': {
          return Location.isGuestRoom;
        }
        case 'isIOCommonArea': {
          return Location.isIOCommonArea;
        }
        case 'isHvacCommonArea': {
          return Location.isHvacCommonArea;
        }
        default: {
          break;
        }
      }
    }

    filterByDesignation(locations: Location[], designation: string) {
      const targetObject = designation.split('/')[0];
      const targetSubobject = designation.split('/')[1];
      return locations.filter( location => {
        if ((targetObject == Location.getLocationObject(location) || targetObject === '*') && (targetSubobject == Location.getLocationSubObject(location) || targetSubobject === '*')) {
          return true;
        }
      })
    }
}
