<div class="login-full-screen" >
    <div class="login-container">
        <div class="logo"></div>
        <div class="hotel-name">Hotel Name</div>
        <div class="login-title">{{'Enter licence key' | translate}}</div>
  
      <form *ngIf="licensingForm" [formGroup]="licensingForm" class="flex-column flex-center width80 margin-top100" (ngSubmit)="onEnterKey()">
        <div class="content-form-group width100 margin-top100">
            <label for="licenceKey">{{'Licence key' | translate}}</label>
            <input type="text" placeholder="{{'Enter licence key' | translate}}" formControlName="licenceKey" name="licenceKey" [ngClass]="{ 'is-invalid': submitted && licensingForm.controls.licenceKey.errors }">
            <div *ngIf="submitted && licensingForm.controls.licenceKey.errors" class="invalid-input">
              <div *ngIf="licensingForm.controls.licenceKey.errors.required">{{'License key is required' | translate}}</div>
          </div>
        </div>

        <button type="submit" class="btn-big margin-top20">{{'Accept' | translate}}</button>

      </form>  
    </div>
  
  
  
    <div class="flex-column-center-full margin-top30 powered-by">
      <div>powered by</div>
      <div>ADRIA ELECTRONIC</div>
    </div>
  </div>
  