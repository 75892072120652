import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { User } from 'src/app/core/models/user/user.model';
import { ApiProjectService, LicenceData } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-project-id-input',
  templateUrl: './project-id-input.component.html',
  styleUrls: ['./project-id-input.component.scss']
})
export class ProjectIdInputComponent implements OnInit {

  
  user: User = new User();
  projectId: string;
  licenceKey: string;
  licensingForm: UntypedFormGroup;
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder,
             private apiProjectService: ApiProjectService,
             private router: Router,
             private auth: AuthenticationService) { }

  ngOnInit() {
    this.getProjectId();
  }

  getProjectId() {
    this.apiProjectService.getProjectId().subscribe( value => {
      this.projectId = value
      if (this.projectId === '' || this.projectId === null) {
        this.buildForm()
      } else {
        this.router.navigate(['/license']);
      }
    })
  }

  buildForm() {
    this.licensingForm = this.formBuilder.group({
      projectID: [this.projectId, [Validators.required, Validators.maxLength(32)]],
      email: ['', [Validators.required]],
      password : ['', [Validators.required]]
    }); 
}

  onEnterKey() {
    this.submitted = true;
    if (this.licensingForm.valid) {
      this.user.email = this.licensingForm.controls.email.value
      this.user.password = this.licensingForm.controls.password.value
      this.auth.login(this.user).subscribe(
        response => {
          this.apiProjectService.setProjectId(this.licensingForm.controls.projectID.value).subscribe( () => {
            this.router.navigate(['/license']);
            this.auth.logout()
          })
        }
      );
    }

  }
}
