<div class="flex-row-space-evenly margin-top30">
  <div class="width90 flex-row">
    <div class="flex-column width12">
      <ion-list class="flex-row">
        <ion-list-header>{{ "Tags" | translate }}
          <ion-icon *ngIf="signedInUser.havePermission(SoftwarePermissionId.LocationTags)" class="margin-left15 clickable" name="create-outline" (click)="editControllerTags($event)"></ion-icon>
        </ion-list-header>
      </ion-list>
      <div *ngFor="let tag of location.locationTags.$values">
        {{tag.tag}}
      </div>
    </div>


    <div class="flex-column width20">
      <div class="margin-bottom30">
        {{'DOORS' | translate}} {{'AND' | translate}} {{'WINDOWS' | translate}}</div>

      <!-- WINDOWS -->

      <app-modal-view-boolean-prop [controllers]="controllers" [propFilterFunction]="Property.isWindowOpened"
        [activeText]="'Window open'" [inactiveText]="'Window closed'" [iconActive]="'icon-window-open'"
        [iconInactive]="'icon-window-closed'" [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>

      <!-- DOORS -->

      <app-modal-view-boolean-prop [controllers]="controllers" [propFilterFunction]="Property.isDoorOpened"
        [activeText]="'Door open'" [inactiveText]="'Door closed'" [iconActive]="'icon-door_opened_status'"
        [iconInactive]="'icon-door'" [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>
    </div>

    <div class="flex-column width20">
      <div class="margin-bottom30"> HVAC</div>

      <!-- ROOM TEMPERATURE CURRENT -->

      <app-modal-view-numeric-prop [controllers]="controllers"
        [propFilterFunction]="Property.isRoomTemperatureCurrent" [forcePropIndex]="-1">
      </app-modal-view-numeric-prop>

      <!-- ROOM TEMPERATURE SET -->

      <app-modal-view-numeric-prop [controllers]="controllers"
        [propFilterFunction]="Property.isRoomTemperatureSet" [forcePropIndex]="-1">
      </app-modal-view-numeric-prop>

      <!-- HVAC ACTIVE -->

      <app-modal-view-boolean-prop [controllers]="controllers" [propFilterFunction]="Property.isHvacActive"
        [activeText]="'Hvac on'" [inactiveText]="'Hvac off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
        [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>

      <!-- HVAC HEATING/COOLING -->

      <app-modal-view-boolean-prop [controllers]="controllers" [propFilterFunction]="Property.isHvacHeatCool"
        [activeText]="'Cooling mode'" [inactiveText]="'Heating mode'" [iconActive]="'icon-snowflake'"
        [iconInactive]="'icon-snowflake'" [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>

      <!-- HVAC HEAT COOL AUTO -->

      <app-modal-view-boolean-prop [controllers]="controllers"
        [propFilterFunction]="Property.isHvacHeatCoolAuto" [activeText]="'Hvac Auto on'"
        [inactiveText]="'Hvac Auto off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
        [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>

      <!-- HVAC FANCOIL -->
      <app-modal-view-mutex-boolean-prop [controllers]="controllers"
        [mutexPropsFunc]="[Property.isFancoilV1, Property.isFancoilV2, Property.isFancoilV3]" [icon]="'icon-fan'"
        [text]="'Fancoil speed'">
      </app-modal-view-mutex-boolean-prop>


      <!-- ROOM FLOOR HEATING -->

      <app-modal-view-boolean-prop [controllers]="controllers"
        [propFilterFunction]="Property.isRoomFloorHeating" [activeText]="'Room floor heating on'"
        [inactiveText]="'Room floor heating off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
        [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>

      <!-- BATHROOM FLOOR HEATING -->

      <app-modal-view-boolean-prop [controllers]="controllers"
        [propFilterFunction]="Property.isBathroomFloorHeating" [activeText]="'Bathroom floor heating on'"
        [inactiveText]="'Bathroom floor heating off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
        [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>

      <!-- BATHROOM CURRENT TEMPERATURE -->

      <app-modal-view-numeric-prop [controllers]="controllers"
        [propFilterFunction]="Property.isBathroomTemperatureCurrent" [forcePropIndex]="-1">
      </app-modal-view-numeric-prop>


      <!-- BATHROOM SET TEMPERATURE -->

      <app-modal-view-numeric-prop [controllers]="controllers"
        [propFilterFunction]="Property.isBathroomTemperatureSet" [forcePropIndex]="-1">
      </app-modal-view-numeric-prop>

      <!-- CO2 -->

      <app-modal-view-numeric-prop [controllers]="controllers"
        [propFilterFunction]="Property.isCO2Measurement" [sufix]="'ppm'" [icon]="'icon-co2'" [forcePropIndex]="-1">
      </app-modal-view-numeric-prop>

      <!-- CO2 -->

      <app-modal-view-numeric-prop [controllers]="controllers" [propFilterFunction]="Property.isHumidity"
        [sufix]="'%'" [icon]="'icon-humidity'" [forcePropIndex]="-1">
      </app-modal-view-numeric-prop>

      <!-- BATHROOM RADIATOR -->

      <app-modal-view-boolean-prop [controllers]="controllers"
        [propFilterFunction]="Property.isBathroomRadiator" [activeText]="'Bathroom radiator on'"
        [inactiveText]="'Bathroom radiator off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
        [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>

      <!-- ROOM RADIATOR -->

      <app-modal-view-boolean-prop [controllers]="controllers" [propFilterFunction]="Property.isRoomRadiator"
        [activeText]="'Room radiator on'" [inactiveText]="'Room radiator off'" [iconActive]="'icon-temp'"
        [iconInactive]="'icon-temp'" [forcePropIndex]="-1">
      </app-modal-view-boolean-prop>

      <!-- RELAY OUTPUT PROPS -->

      <ng-container *ngIf="controllers | findProperty : Property.isRelayOutputStatus: null : true as relayOutputStatusProps">
        <ng-container *ngFor="let relayOutputStatusProp of relayOutputStatusProps">
          <div class="status-container-small flex-row-center-v margin-bottom12 width230">
            <!-- RELAY OUTPUT ON -->
            <ng-container *ngIf="relayOutputStatusProp | propIsActive; else relayOutputOff">

                <div class="icon-settings icon-background-on font-size19"></div>
                <div class="status-text-on">{{ relayOutputStatusProp| getRelayOutputStatusName | translate }} {{'on' | translate}}</div>
            </ng-container>
            <!-- RELAY OUTPUT OFF -->
            <ng-template #relayOutputOff>
              <div class="icon-settings icon-background-off font-size19"></div>
              <div class="status-text-off">{{ relayOutputStatusProp| getRelayOutputStatusName | translate }} {{'off' | translate}}</div>
            </ng-template>
          </div>
    </ng-container>
    </ng-container>

    <!-- DAIKIN -->
      <div *ngIf="controllers | findProperty : Property.isDaikinUnitOnOff : null : true as props">
        <div *ngFor="let prop of props; let i=index">
          <div class="margin-bottom10">
            {{'Daikin unit' | translate}} {{i+1}}</div>
          <!-- DAIKIN UNIT ON /OFF -->
          <app-modal-view-boolean-prop [controllers]="controllers"
            [propFilterFunction]="Property.isDaikinUnitOnOff" [activeText]="'Daikin unit on'"
            [inactiveText]="'Daikin unit off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
            [forcePropIndex]="i">
          </app-modal-view-boolean-prop>
          <!-- DAIKIN UNIT SET TEMPERATURE -->
          <app-modal-view-numeric-prop [controllers]="controllers"
            [propFilterFunction]="Property.isDaikinUnitSetTemperature" [forcePropIndex]="i">
          </app-modal-view-numeric-prop>
          <!-- DAIKIN UNIT MEASURED TEMPERATURE -->
          <app-modal-view-numeric-prop [controllers]="controllers"
            [propFilterFunction]="Property.isDaikinUnitMeasuredTemperature" [forcePropIndex]="i">
          </app-modal-view-numeric-prop>
          <!-- DAIKIN UNIT STATUS -->
       <!--    <app-daikin-unit-status [controllers]="controllers" [forcePropIndex]="i">
          </app-daikin-unit-status> -->
          <app-display-dictionary-prop-in-room-modal
          [controllers]="controllers"
          [forcePropIndex]="i"
          [findPropFunction]="Property.isDaikinUnitStatus"
          [icon]="'icon-temp'"
          [dictionary]="daikinUnitStatusDictionary"
          >
          </app-display-dictionary-prop-in-room-modal>
          <!-- DAIKIN UNIT FAN SPEED -->
      <!--     <app-daikin-unit-fan-speed [controllers]="controllers" [forcePropIndex]="i">
          </app-daikin-unit-fan-speed> -->
          <app-display-dictionary-prop-in-room-modal
          [controllers]="controllers"
          [forcePropIndex]="i"
          [findPropFunction]="Property.isDaikinUnitFanSpeed"
          [icon]="'icon-fan'"
          [dictionary]="daikinUnitFanSpeedDictionary"
          >
          </app-display-dictionary-prop-in-room-modal>
          <!-- DAIKIN UNIT OPERATION MODE -->
       <!--    <app-daikin-unit-operation-mode [controllers]="controllers" [forcePropIndex]="i">
          </app-daikin-unit-operation-mode> -->
          <app-display-dictionary-prop-in-room-modal
          [controllers]="controllers"
          [forcePropIndex]="i"
          [findPropFunction]="Property.isDaikinUnitMode"
          [icon]="'icon-temp'"
          [dictionary]="daikinUnitOperationModeDictionary"
          >
          </app-display-dictionary-prop-in-room-modal>
        </div>
      </div>

      <!-- LG -->
      <div *ngIf="controllers | findProperty : Property.isLgUnitOnOff: null : true as props">
        <div *ngFor="let prop of props; let i=index">
          <div class="margin-bottom10">
            {{'Lg unit' | translate}} {{i+1}}</div>
          <!-- LG UNIT ON /OFF -->
          <app-modal-view-boolean-prop [controllers]="controllers"
            [propFilterFunction]="Property.isLgUnitOnOff" [activeText]="'Lg unit on'"
            [inactiveText]="'Lg unit off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
            [forcePropIndex]="i">
          </app-modal-view-boolean-prop>
          <!-- LG UNIT SET TEMPERATURE -->
          <app-modal-view-numeric-prop [controllers]="controllers"
            [propFilterFunction]="Property.isLgUnitSetTemperature" [forcePropIndex]="i">
          </app-modal-view-numeric-prop>
          <!-- LG UNIT MEASURED TEMPERATURE -->
          <app-modal-view-numeric-prop [controllers]="controllers"
            [propFilterFunction]="Property.isLgUnitMeasuredTemperature" [forcePropIndex]="i">
          </app-modal-view-numeric-prop>
          <!-- LG UNIT STATUS -->
         <!--  <app-daikin-unit-status [controllers]="controllers" [forcePropIndex]="i">
          </app-daikin-unit-status> -->
          <app-display-dictionary-prop-in-room-modal
          [controllers]="controllers"
          [forcePropIndex]="i"
          [findPropFunction]="Property.isLgUnitStatus"
          [icon]="'icon-temp'"
          [dictionary]="lgUnitStatusDictionary"
          >
          </app-display-dictionary-prop-in-room-modal>
          <!-- LG UNIT FAN SPEED -->
       <!--    <app-daikin-unit-fan-speed [controllers]="controllers" [forcePropIndex]="i">
          </app-daikin-unit-fan-speed> -->
          <app-display-dictionary-prop-in-room-modal
          [controllers]="controllers"
          [forcePropIndex]="i"
          [findPropFunction]="Property.isLgUnitFanSpeed"
          [icon]="'icon-fan'"
          [dictionary]="lgUnitFanSpeedDictionary"
          >
          </app-display-dictionary-prop-in-room-modal>
          <!-- LG UNIT OPERATION MODE -->
       <!--    <app-daikin-unit-operation-mode [controllers]="controllers" [forcePropIndex]="i">
          </app-daikin-unit-operation-mode> -->
          <app-display-dictionary-prop-in-room-modal
          [controllers]="controllers"
          [forcePropIndex]="i"
          [findPropFunction]="Property.isLgUnitOperationMode"
          [icon]="'icon-temp'"
          [dictionary]="lgUnitOperationModeDictionary"
          >
          </app-display-dictionary-prop-in-room-modal>
        </div>
      </div>

      <!--VRV -->

      <ng-container *ngIf="
        controllers | findProperty : Property.isVrvOnOff: null : true as vrvOnOffProps
      ">
        <ng-container *ngIf="
          controllers | findProperty : Property.isVrvSpeed: null : true as vrvSpeedProps
        ">
          <ng-container *ngIf="vrvOnOffProps.length > 0">
            <div class="status-container-small flex-row-center-v margin-bottom12 width230">
              <!-- VRV ON -->
              <ng-container *ngIf="vrvOnOffProps[0] | propIsActive; else vrvOff">
                <!-- VRV HIGH -->
                <ng-container *ngIf="vrvSpeedProps | propIsActive; else vrvLow">
                  <div class="icon-fan icon-background-on font-size19"></div>
                  <div class="status-text-on">{{ "Vrv high" | translate }}</div>
                </ng-container>
                <!-- VRV LOW -->
                <ng-template #vrvLow>
                  <div class="icon-fan icon-background-on font-size19"></div>
                  <div class="status-text-on">{{ "Vrv low" | translate }}</div>
                </ng-template>
              </ng-container>
              <!-- VRV OFF -->
              <ng-template #vrvOff>
                <div class="icon-fan icon-background-off font-size19"></div>
                <div class="status-text-off">{{ "Vrv off" | translate }}</div>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="flex-column width15">
      <ion-button *ngIf="toggleHvacExist"
      class="margin5 width100"
      color="light"
      (click)="toggleHvac()"
      size="small"
      ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
      {{'Toggle HVAC' | translate}}</ion-button>

      <ion-button
      class="margin5 width100"
      color="light"
      (click)="syncHvac()"
      size="small"
      ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
      {{'Settings sync' | translate}}</ion-button>

      <ng-container *ngIf="controllers | findProperty:  Property.isHvacHeatCool: null : true as hvacHeatCoolProps">
        <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCoolAuto: null : true as hvacHCAutoProps">
          <!-- HEAT/COOL/AUTO -->
          <div *ngIf="hvacHCAutoProps.length > 0" class="flex-row-space-between width100">
            <ion-button class="margin-bottom5 margin-top5 width30" size="small"
              [disabled]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
              [color]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive)?'primary':'light'"
              (click)="setHvacMode(0)">
              {{'Heat' | translate}}
            </ion-button>
            <ion-button class="margin-bottom5 margin-top5 width30"  size="small"
              [disabled]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
              [color]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive)?'primary':'light'"
              (click)="setHvacMode(1)">
              {{'Cool' | translate}}
            </ion-button>
            <ion-button class="margin-bottom5 margin-top5 width30"
              size="small"
              [disabled]="(hvacHCAutoProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
              [color]="(hvacHCAutoProps | propIsActive)?'primary':'light'"
              (click)="setHvacMode(2)">
              {{'Auto' | translate}}
            </ion-button>
          </div>
          <!-- HEAT/COOL -->
          <div *ngIf="hvacHCAutoProps.length === 0 && hvacHeatCoolProps.length > 0" class="flex-row-space-between width100">
            <ion-button class="margin5 width45" size="small"
              [disabled]="!(hvacHeatCoolProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
              [color]="!(hvacHeatCoolProps | propIsActive)?'primary':'light'"
              (click)="setHvacMode(0)">
              {{'Heat' | translate}}
            </ion-button>
            <ion-button class="margin-bottom5 margin-top5 width45" size="small"
              [disabled]="(hvacHeatCoolProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
              [color]="(hvacHeatCoolProps | propIsActive)?'primary':'light'"
              (click)="setHvacMode(1)">
               {{'Cool' | translate}}
            </ion-button>
          </div>
        </ng-container>
      </ng-container>

      <div style="width: 300px;">
        <div>{{'Equipment ignore' |translate}}</div>
        <app-boolean-input
              *ngIf="
            controllers | propExistInControllers : Property.isIgnoreWindow : true
          "
              [controllers]="controllers"
              [findPropFunction]="Property.isIgnoreWindow"
              >{{ "Ignore window" | translate }}</app-boolean-input
            >
            <app-boolean-input
            *ngIf="
          controllers | propExistInControllers : Property.isIgnoreCardTray : true
        "
            [controllers]="controllers"
            [findPropFunction]="Property.isIgnoreCardTray"
            >{{ "Ignore card tray" | translate }}</app-boolean-input
          >
            <ion-item lines="none" class="item-background-color">
              <ion-checkbox
                (ionChange)="changeControllerPooling($event)"
                justify="start"
                labelPlacement="end"
                class="margin-right5"
                [checked]="stopControllerPolling"
              ><span class="ion-text-wrap">
            {{ "Stop controller polling" | translate }}</span></ion-checkbox
          >
        </ion-item>
      </div>
    </div>

    <div class="flex-column width22 margin-left100">
      <div class="alarm-box-yellow">
        <div class="flex-row-center-v margin-bottom5 width100">
          <div class="icon-alert font-size20 yellow"></div>
          <div class="margin-left20 margin-top5"> {{'Warnings' | translate}}</div>
        </div>

        <div class="flex-row margin-left20" *ngIf="
          (controllers | findProperty: Property.isHVACLargeTempDiffRoom: null : true | propIsActive) ||
          (controllers | findProperty: Property.isDoorLockBatteryLow: null : true | propIsActive) ||
          (controllers | findProperty: Property.isControllerTempHigh: null : true | propIsActive) ||
          ((controllers | findProperty: Property.isWindowOpenTooLongAlarm: null : true | propIsActive) && !(location | locationHasTag: 'low window')) ||
          (controllers | findProperty: Property.isDoorOpenTooLongAlarm: null : true | propIsActive) ||
          (controllers | findProperty: Property.isSafeCommError: null : true | propIsActive) ||
          ((controllers | findProperty: Property.isLowWindowOpenTooLong: null : true | propIsActive) && (location | locationHasTag: 'low window')) ||
          (controllers | findProperty: Property.isFuse: null : true | propIsActive) ||
          (controllers | findProperty: Property.isPowerOutage: null : true | propIsActive) ||
          (controllers | findProperty: Property.isWind: null : true | propIsActive) ||
          (controllers | findProperty: Property.isCO2: null : true | propIsActive) ||
          (controllers | findProperty: Property.isHVACLargeTempDiffBathroom: null : true | propIsActive)
        ">

          <ng-container *ngIf="controllers | findProperty: Property.isDoorLock: null : true as hvacLargeTempDiffRoomProps">
            <ng-container *ngFor="let hvacLargeTempDiffRoomProp of hvacLargeTempDiffRoomProps">
              <div *ngIf="hvacLargeTempDiffRoomProp | propIsActive">
                <div class="prop-title">{{ getAlarmName(hvacLargeTempDiffRoomProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="controllers | findProperty: Property.isDoorLockBatteryLow: null : true as doorLockBatteryLowProps">
            <ng-container *ngFor="let doorLockBatteryLowProp of doorLockBatteryLowProps">
              <div *ngIf="doorLockBatteryLowProp | propIsActive">
                <div class="prop-title">{{ getAlarmName(doorLockBatteryLowProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="controllers | findProperty: Property.isControllerTempHigh: null : true as contTemperatureHighProps">
            <ng-container *ngFor="let contTempProp of contTemperatureHighProps">
              <div *ngIf="contTempProp | propIsActive">
                <div class="prop-title">{{ contTempProp.name }}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="controllers | findProperty: Property.isWindowOpenTooLongAlarm: null : true as windowOpenTooLongProps">
            <ng-container *ngFor="let windowOpenTooLongProp of windowOpenTooLongProps">
              <div *ngIf="(windowOpenTooLongProp | propIsActive)  && !(location | locationHasTag: 'low window')">
                <div class="prop-title">
                  {{ getAlarmName(windowOpenTooLongProp) | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="controllers| findProperty: Property.isDoorOpenTooLongAlarm: null : true as doorOpenTooLongProps">
            <ng-container *ngFor="let doorOpenTooLongProp of doorOpenTooLongProps">
              <div *ngIf="doorOpenTooLongProp | propIsActive">
                <div class="prop-title">
                  {{ getAlarmName(doorOpenTooLongProp) | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="controllers | findProperty: Property.isSafeCommError: null : true as safeCommErrorProps">
            <ng-container *ngFor="let safeCommErrorProp of safeCommErrorProps">
              <div *ngIf="safeCommErrorProp | propIsActive">
                <div class="prop-title">{{ getAlarmName(safeCommErrorProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="controllers | findProperty: Property.isLowWindowOpenTooLong: null : true as lowWinOpenTooLongProps">
            <ng-container *ngFor="let lowWinOpenTooLongProp of lowWinOpenTooLongProps">
              <div *ngIf="(lowWinOpenTooLongProp | propIsActive) && (location | locationHasTag: 'low window')">
                <div class="prop-title">{{ getAlarmName(lowWinOpenTooLongProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="controllers | findProperty: Property.isFuse: null : true as fuseProps">
            <ng-container *ngFor="let fuseProp of fuseProps">
              <div *ngIf="fuseProp | propIsActive">
                <div class="prop-title">{{ fuseProp.name | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="controllers | findProperty: Property.isPowerOutage: null : true as powerOutageProps">
            <ng-container *ngFor="let powerOutageProp of powerOutageProps">
              <div *ngIf="powerOutageProp | propIsActive">
                <div class="prop-title">{{ powerOutageProp.name }}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="controllers | findProperty: Property.isWind: null : true as windProps">
            <ng-container *ngFor="let windProp of windProps">
              <div *ngIf="windProp | propIsActive">
                <div class="prop-title">{{ windProp.name }}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="controllers | findProperty: Property.isCO2: null : true as co2Props">
            <ng-container *ngFor="let co2Prop of co2Props">
              <div *ngIf="co2Prop | propIsActive">
                <div class="prop-title">{{ co2Prop.name }}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="controllers| findProperty: Property.isHVACLargeTempDiffBathroom: null : true as hvacLargeTempDiffBathroomProps">
            <ng-container *ngFor="let hvacLargeTempDiffBathroomProp of hvacLargeTempDiffBathroomProps">
              <div *ngIf="hvacLargeTempDiffBathroomProp | propIsActive">
                <div class="prop-title">{{ getAlarmName(hvacLargeTempDiffBathroomProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    </div>
</div>
