<nav *ngIf="projectReady$ | async; else headerSpacer" class="flex-row header">
  <ng-container *ngIf="{value : mqttQueueLength$ | async} as mqttQueueLength">
    <ng-container *ngIf="User.isAdria(user)">Mqtt queue:{{mqttQueueLength.value}}</ng-container>
    <ng-container *ngIf="!User.isAdria(user) && mqttQueueLength.value > 0">
      <div class="loading-indicator loading-indicator-small" style="position: absolute;" [title]="'Processing incoming messages...' | translate"><div></div><div></div><div></div><div></div></div>
    </ng-container>
  </ng-container>

     <!-- <div class="header-title-cFontainer flex-row">
        <a class="margin-left10 margin-top10" href="https://www.ae.hr"><i class="i-logo"></i></a>
        <p class="header-title" [routerLink]="['/home']">ACCESS CONTROL</p>
    </div> -->
    <!-- <audio #audioOption allow="autoplay">
      <source src='../../../../assets/sounds/beep-beep.mp3' type="audio/mp3">
  </audio> -->
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ng-container *ngIf="mqttDown;else mqttWorking">
      <div class="to-right mob-to-middle">
        <div class="alert-box blob flex-row-center-full danger" >
            <p >{{ 'Real-time updates not working' | translate }}</p>
        </div>
    </div>
    </ng-container>
    <ng-template #mqttWorking>
      <div class="to-right mob-to-middle" *ngIf="alarms | getLatestHighestPriorityAlarm as alarm">
          <div *ngIf="unconfirmedAlerts > 0" class="alert-box blob flex-row-center-full clickable" [ngClass]="alarms | getAlarmsLevel" routerLink="/alarms/unconfirmed">
              <ng-container *ngIf="(unconfirmedAlerts > 1)">
                <p >{{alarms | getUnconfirmedAlarmText : alarm}} ({{ totalUnconfirmedAlarms | async }} ALARMS) </p>
              </ng-container>
              <p *ngIf="unconfirmedAlerts === 1">{{ alarms | getUnconfirmedAlarmText }}</p>
          </div>
      </div>
    </ng-template>
    <div class="to-right mob-to-middle flex-row">
      <h3 *ngIf="!soundAvailable" class="red margin-top8 margin-right10 uppercase">{{'Sound is disabled' | translate}}</h3>
        <div *ngIf="cardSyncActive"
          style="margin-top: 3.5px; margin-right: 2px;"
          class="margin-top2 icon-refresh font-size20"
          title="{{'Sync in progress...' | translate}}">
        </div>
        <div *ngIf="{value : activeHvacModeGlobal$ | async} as activeHvacModeGlobal" (click)="onClickHvacMode()" >
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Heating"
            class="icon-sun font-size22 margin-top2"
            [ngClass]="{'clickable': user | userHasPermission : swPermissions.LocationGroupsActivatePresets}"
            [title]="'Heating'| translate"></div>
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Cooling"
            class="icon-snowflake font-size22 margin-top2"
            [ngClass]="{'clickable': user | userHasPermission : swPermissions.LocationGroupsActivatePresets}"
            [title]="'Cooling'| translate"></div>
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Mixed"
            class="margin-top2 icon-hvac font-size24"
            [ngClass]="{'clickable': user | userHasPermission : swPermissions.LocationGroupsActivatePresets}"
            [title]="'Cooling/Heating' | translate">
          </div>
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Auto"
            class="margin-top2 icon-auto-mode font-size24"
            [ngClass]="{'clickable': user | userHasPermission : swPermissions.LocationGroupsActivatePresets}"
            [title]="'Auto' | translate">
          </div>
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Auto" class="font-size16 margin-top10" [title]="'Auto'">Auto</div>
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Unknown" class="font-size24 margin-top8" [title]="'Unknown'">?</div>
        </div>

        <div *ngIf="platform.platforms().includes('desktop')" class="tooltip margin-top5">
            <i [ngClass]="[cardReaderConnected ? 'icon-reader font-size24' : 'icon-reader_deny font-size24']"></i>
            <span class="bottom" *ngIf="cardReaderConnected">{{'Card reader connected' | translate}}</span>
            <span class="bottom" *ngIf="!cardReaderConnected">{{ 'Card reader disconnected' | translate }}</span>
        </div>
        <!-- <div *ngIf="user | userHavePermissionForAnyAlarms"
            routerLink="/alarms/unconfirmed" class="clickable margin-top8 outline-none tooltip">
            <i class="icon-alert font-size19"></i>
            <span *ngIf="unconfirmedAlerts > 0" class="alarm-active-dot"></span>
            <span class="bottom">{{ unconfirmedAlerts > 0 ? 'Unconfirmed alerts' : 'No unconfirmed alerts' }}</span>
        </div> -->
        <div class="vertical-line"></div>
        <div class="header-dropdown" (click)="dropdownOpened=!dropdownOpened; langDropdownOpened= false; objectDropdownOpened = false">
            <div *ngIf="user" class="text-tooltip">
                <p class="header-user">
                    {{user?.firstName | textSizeFilterPipe : 20}} {{user?.lastName | textSizeFilterPipe : 12}}
                    <span class="bottom"
                    *ngIf="user?.firstName?.length > 20 || user?.lastName?.length > 20">{{user?.fullName}}</span>
                    <i class="header-arrow" [ngClass]="[dropdownOpened ? 'up' : 'down']"></i>
                </p>
            </div>
        </div>

        <div class="header-dropdown" (click)="langDropdownOpened=!langDropdownOpened; dropdownOpened= false; objectDropdownOpened = false">
          <div *ngIf="currentLang">
              <p class="header-user"  *ngIf="currentLang==='hr'">HR
                  <i class="header-arrow" [ngClass]="[langDropdownOpened ? 'up' : 'down']"></i>
              </p>
              <p class="header-user" *ngIf="currentLang==='en'">EN
                  <i class="header-arrow" [ngClass]="[langDropdownOpened ? 'up' : 'down']"></i>
              </p>
          </div>
        </div>
        <div class="header-dropdown" (click)="objectDropdownOpened=!objectDropdownOpened; dropdownOpened= false; langDropdownOpened= false;">
          <p class="header-user" >
            <ng-container *ngIf="objectsFilter$ | async as objFilter">
              <!-- <span *ngIf="objFilter.length === 0">All</span> -->
              <span *ngIf="objFilter.length > 0 && (objects$ | async).length > 1" style="cursor: pointer;">
                <ng-container *ngIf="(objects$ | async) | getObjectsByIds: objFilter as obj">
                  {{obj[0]?.name}}
                </ng-container>
              </span>
              <span *ngIf="objFilter.length > 0 && (objects$ | async).length <= 1" style="cursor: default;">
                <ng-container *ngIf="(objects$ | async) | getObjectsByIds: objFilter as obj">
                  {{obj[0]?.name}}
                </ng-container>
              </span>
            </ng-container>

            <i *ngIf="(objects$ | async).length > 1" class="header-arrow" [ngClass]="[objectDropdownOpened ? 'up' : 'down']"></i>
        </p>
        </div>
        <div *ngIf="dropdownOpened" class="header-dropdown-content" style="margin-right: 200px;"  [@enterAnimation]>
            <a routerLink="/users/{{user.userId}}" (click)="dropdownOpened=false">{{'View Profile' | translate}}</a>
            <a class="header-link" (click)="logout()" (click)="dropdownOpened=false">{{'Logout' | translate}}</a>
        </div>
        <div  *ngIf="langDropdownOpened" class="header-dropdown-content" style="margin-right: 150px;" [@enterAnimation]>
          <a class="header-link" i18n="@@croatian" (click)="setLang('hr')">{{'Croatian' | translate}}</a>
          <a class="header-link" i18n="@@english" (click)="setLang('en')">{{'English' | translate}}</a>
        </div>

        <div *ngIf="objectDropdownOpened && (objects$ | async).length > 1" class="header-dropdown-content" [@enterAnimation]>
          <ng-container *ngIf="objects$ | async as objects">
            <a class="header-link" *ngFor="let object of objects" (click)="selectObject(object)">
              {{'Object' | translate}} {{object.name}}
            </a>
          </ng-container>
          <!-- <a class="header-link" (click)="selectObject(null)">{{'All objects' | translate}}</a> -->
        </div>
    </div>
</nav>
<ng-template #headerSpacer>
  <div class="spacer"></div>
</ng-template>

<div *ngIf="licenceData && showExpireMessage" class="licence-expire"> {{'Licence will expire on' | translate}} {{licenceData.validTo | date:'dd.MM.yyyy. HH:mm.'}} {{'Please contact support.' | translate}}</div>
