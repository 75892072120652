import { Pipe, PipeTransform } from '@angular/core';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';

@Pipe({
  name: 'getUnconfirmedAlarmText'
})
export class GetUnconfirmedAlarmTextPipe implements PipeTransform {

  transform(alarms: AlarmAlert[], alarm?: AlarmAlert): unknown {
    let target: AlarmAlert;
    if (!alarm) {
      target = alarms.find((al) => !al.confirmed);
    } else {
      target = alarm;
    }
    if (target.message) {
      return target.message;
    } else if (target.description) {
      return target.description;
    } else {
      return '';
    }
  }
}

