<div class="flex-column overflow-auto margin25">
    <div class="content-header flex-row hvac-groups">
        <h1 class="margin-left25">{{'HVAC Mode' | translate}}</h1>
        <div class="to-right flex-row-center-full single-group">
          <h3>{{'Change all' | translate}}</h3>
          <div *ngIf="!hvacIsLoading(0)">
              <button class="icon-snowflake font-size15 blue cold-btn" (click)="onSwitchAllToCoolingClick()"></button>
              <button class="icon-fire font-size23 red fire-btn" (click)="onSwitchAllToHeatingClick()"></button>
          </div>
          <div *ngIf="hvacIsLoading(0)" class="loading-indicator margin-bottom10"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>

    <div class="flex-column group-container width100">
      <ion-list class="width100">
        <ion-list-header>{{'Groups' | translate}}</ion-list-header>
        <ion-item *ngFor="let group of HVACGroups$ | async" class="flex-row-center-full single-group">

            <div *ngIf="!hvacIsLoading(group.id)">
                <button class="icon-snowflake font-size15 blue cold-btn" (click)="onSwitchGroupToCoolingClick(group)"></button>
                <button class="icon-fire font-size23 red fire-btn" (click)="onSwitchGroupToHeatingClick(group)"></button>
            </div>
            <div> {{ group.name }}</div>
            <div *ngIf="hvacIsLoading(group.id)" class="loading-indicator margin-bottom10"><div></div><div></div><div></div><div></div></div>
        </ion-item>
      </ion-list>
    </div>
</div>
