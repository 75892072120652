import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MessagingService } from '../../../core/services/messaging.service';
import { insertRemoveTrigger } from '../../animations/animations';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  animations: [insertRemoveTrigger],
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit, OnDestroy {

  message = '';
  alarmAlert?: AlarmAlert;
  messageSubscription: Subscription;
  alarmMessageSubscription: Subscription;
  showMessage = false;
  showAlarmMessage = false;

  constructor(private messagingService: MessagingService) { }

  ngOnInit() {
    this.getMessage();
    this.getAlarmAlert();
  }

  getMessage() {
    this.message = this.messagingService.getMessage();
    // added setTimeout because of expression changed after it was checked error in dev mode
    this.messageSubscription = this.messagingService.messageChanged.subscribe(message => setTimeout(() => {
        this.message = message;
        if (message !== '') {
          this.showMessage = true;
          setTimeout(() => this.removeMessage(), 5000);
        }
      }, 0));
  }

  getAlarmAlert() {
    this.alarmAlert = this.messagingService.getAlarmMessage();
    this.alarmMessageSubscription = this.messagingService.alarmMessageChanged.subscribe((alarmAlert: AlarmAlert) => {
      this.alarmAlert = alarmAlert;
    },);
  }

  removeMessage() {
    this.messagingService.setMessage('');
    this.showMessage = false;
  }

  ngOnDestroy() {
    if(this.messageSubscription){
      this.messageSubscription.unsubscribe();
    }
    if(this.alarmMessageSubscription){
      this.alarmMessageSubscription.unsubscribe();
    }
  }
}
