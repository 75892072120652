<ng-container
  *ngIf="controllers | findProperty : findPropFunction: null : true as props"
>
  <div *ngIf="forcePropIndex === -1">
    <div
      *ngFor="let prop of props"
      class="status-container-small flex-row-center-v margin-bottom12 width230"
    >
      <ng-container
        *ngIf="prop | getDictionaryValue : dictionary: updated as dictionaryValue"
      >
        <div
          class="{{ icon }} {{
            dictionaryValue.iconOn
              ? 'icon-background-on'
              : 'icon-background-off'
          }} font-size19"
        ></div>
        <div
          class="{{
            dictionaryValue.iconOn ? 'status-text-on' : 'status-text-off'
          }} "
        >
          {{ dictionaryValue.text | translate }}
        </div>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="forcePropIndex != -1"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <ng-container
      *ngIf="
        props[forcePropIndex]
          | getDictionaryValue : dictionary: updated as dictionaryValue
      "
    >
      <div
        class="{{ icon }} {{
          dictionaryValue.iconOn ? 'icon-background-on' : 'icon-background-off'
        }} font-size19"
      ></div>
      <div
        class="{{
          dictionaryValue.iconOn ? 'status-text-on' : 'status-text-off'
        }} "
      >
        {{ dictionaryValue.text | translate }}
      </div>
    </ng-container>
  </div>
</ng-container>
