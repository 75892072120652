<ng-container *ngIf="prop | getDictionaryValue : dictionary : updated as dictionaryValue">
  <div
  [title]="dictionaryValue.text | translate"
  [ngClass]="[
    displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
  ]"
  class="full-height"
>
  <div
    style="margin-left: inherit;"
    class="{{dictionaryValue.iconOn?'icon-background-on ':'icon-background-off'}}"
    [ngClass]="[icon]"
  ></div>

  <div *ngIf="displaySize === 'large'" class="{{dictionaryValue.iconOn?'status-text-on':'status-text-off'}}">
    {{ dictionaryValue.textShort | translate }}
  </div>
</div>
</ng-container>
