import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getTypeForPresetProp'
})
export class GetTypeForPresetPropPipe implements PipeTransform {

  transform(property: Property): 'int' | 'bool' | 'hvacHeatingCoolingAuto' | 'burglaryDetected' | 'relayOutput' {
    if (Property.isBurglaryDetectedDoor(property) || Property.isBurglaryDetectedWindow(property)
      || Property.isBurglaryDetectedByPIR(property)) {
      return 'burglaryDetected'
    } else if (this.propIsAnyRelayOutput(property)) {
      return 'relayOutput'
    }else if (property.mqttTopic.startsWith('hvac/heatingcoolingauto')) {
      return 'hvacHeatingCoolingAuto'
    } else if (property.mqttTopic.startsWith('hvac/temp/') || property.mqttTopic.startsWith('hvac/co2')) {
      return 'int'
    } else {
      return 'bool'
    }
  }

  propIsAnyRelayOutput (prop: Property) {
    return Property.isRelayOutputByRentedOrPresence(prop) ||
    Property.isRelayOutputControl(prop)  ||
    Property.isRelayOutputForceOff(prop)  ||
    Property.isRelayOutputForceOn(prop)  ||
    Property.isRelayOutputInCooling(prop)  ||
    Property.isRelayOutputStatus(prop)
  }

}

