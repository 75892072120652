import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Controller } from 'src/app/core/models/controller.model';
import { LocationsFilter } from 'src/app/core/models/locations-filter.model';
import { Location } from 'src/app/core/models/project/location.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-access-control-modal-service',
  templateUrl: './access-control-modal-service.component.html',
  styleUrls: ['./access-control-modal-service.component.scss'],
})
export class  AccessControlModalServiceComponent implements OnInit {
  @Input() controllers: Controller[];
  locations$: Observable<Location[]> = this.projectService.getFilteredLocations();
  selectedLocation: Location;
  selectedController: Controller;

  constructor(
    private projectService: ProjectService,
    private apiProjectService: ApiProjectService,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
  }

  selectLocation(locationId: number) {
    this.selectedLocation = null;
    this.selectedController = null;
    this.changeDetectionRef.detectChanges();
    this.locations$.pipe(take(1)).subscribe((locations) => {
      this.selectedLocation = locations.find((loc) => {
        return loc.fullLocationId.toString() === locationId.toString();
      });
    });
  }

  selectController(controller: Controller) {
    this.selectedLocation = null;
    this.selectedController = null;
    this.changeDetectionRef.detectChanges();
    this.selectedController = controller;
  }

  cancelSelection() {
    this.selectedLocation = null;
    this.selectedController = null;
  }
}
