import { Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Setting } from 'src/app/core/models/project/setting.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiLocationGroupsService, LocationGroup } from '../../services/api-location-groups.service';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { LocationPresetCrossRef } from 'src/app/core/models/cross-ref.model';
import { ButtonsSelectComponent } from '../buttons-select/buttons-select.component';
import { PopoverController } from '@ionic/angular';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { Location } from 'src/app/core/models/project/location.model';
import { FORBID_HEAT_COOL_CHANGE } from 'src/environments/environment';

export interface SettingsPropData  {
    name: string,
    codeFrom: number,
    codeTo: number,
    codeFromConstraintA?: number,
    codeToConstraintA?: number,
    codeFromConstraintB?: number,
    codeToConstraintB?: number,
    adminProp: boolean
}

@Component({
  selector: 'app-room-modal-settings',
  templateUrl: './room-modal-settings.component.html',
  styleUrls: ['./room-modal-settings.component.scss']
})
export class RoomModalSettingsComponent implements OnInit {
  @Input() location: Location;
  allControllersInLocation: Controller[];
  isAccessControl = Controller.isAccessControl;
  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  intervalForDoorsActive: boolean;
  defaultSettings: DefaultsPreset[];
  defaultPresetsSubscription: Subscription;
  // stopControllerPolling = false;
  controllerPollingSubscription: Subscription;
  notPollingControllers: Controller[] = [];

  locationCrossRef: LocationPresetCrossRef[] =[];
  defaultPresets: DefaultsPreset[] = [];
  hvacModeLocationGroups: LocationGroup[] = [];
  props: Property[] = [];

  Property = Property;
  renderNumberInputs = true;
  logedInUser: User;
  settings$ = this.settingsService.getRoomModalPropSettings();
  bathroomHasTemps: boolean;
  settingsSubscription: Subscription;

  toggleHvacExist = false;

  ignorePublishingList = [];
  loadingPublishing = false;
  // optionsRoomRadiator = ['active by rented', 'active by presence'];
  // optionsBathroomRadiator = ['active by rented ', 'active by presence '];
  selectedOptionRoom: string;
  selectedOptionBathroom: string;
  reqInProgress = false;
  updated = new Date()
  pipeSubscription: Subscription;
  FORBID_HEAT_COOL_CHANGE = FORBID_HEAT_COOL_CHANGE;

  constructor(
    private settingsService: SettingsService,
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService,
    private apiSettingsService: ApiSettingsService,
    private apiLocationGroupsService: ApiLocationGroupsService,
    private popoverController: PopoverController,
    private currentUserStoreService: CurrentUserStoreService,
    ) {}

  ngOnInit(): void {
    this.allControllersInLocation = this.location.controllers.$values;
    this.selectController(this.allControllersInLocation[0]);
    this.apiSettingsService.getDefaultPresets().subscribe();
    this.defaultPresetsSubscription = this.settingsService.getDefaultPresets().subscribe( value => {
      this.defaultSettings = value;
    })
    this.isControllerPolled();
    this.getLocationPreseCrossRef();
    this.getDefaultPresets();
    this.getHvacModeLocationGroups();
    this.logedInUser = this.currentUserStoreService.getUser()
    this.toggleHvacExist = this.isPropertyInController(Property.isHvacToggle)
    this.checkIfBathroomHasTemps();
    this.apiProjectService.getNotPublishingList().subscribe((list)=> {
      this.ignorePublishingList = list;
      this.loadingPublishing = false;
    });
    this.getRoomRentedValue();
    this.getBathroomRentedValue();

    this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
    })
  }

  checkIfBathroomHasTemps() {
    this.settingsSubscription = this.settings$.subscribe((settings) => {
      settings.bathroom.forEach((setting) => {
          this.props = this.selectedControllers.reduce((allFilteredProps, controller)=> {
          const filteredPropsOnController = controller.controllerProperties.$values.filter((prop: Property) => {
            return (prop.type >= Number(setting.codeFrom) && prop.type <= Number(setting.codeTo))
          });
          allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
          return allFilteredProps;
        },[]);
      })
    })
    if(this.props.length > 0){
      this.bathroomHasTemps = true;
    }else{
      this.bathroomHasTemps = false;
    }
  }

  isControllerPolled() {
    this.notPollingControllers = this.projectService.getNotPolledControllers()
   /*  this.apiProjectService.getNotPolledControllers().subscribe( value => {
      value.forEach( cont => {
        if (cont.object === this.controllers[0].object && cont.subObject === this.controllers[0].subObject
          && cont.zone === this.controllers[0].zone && cont.subZone === this.controllers[0].subZone) {
          this.stopControllerPolling = true;
        }
      })
    }) */
    this.apiProjectService.getNotPolledControllers().subscribe()
    this.controllerPollingSubscription = this.projectService.notPolledControllersChanged.subscribe( (conts) => {
      this.notPollingControllers = conts;
    })
  }

  async changeResetDefaultPropertiesOnCheckout(event: any) {
    if (event.target.checked) {
      this.location.restoreDefaultOnCheckout = true;
      this.apiProjectService.updateLocation(this.location).subscribe();
    } else {
      this.location.restoreDefaultOnCheckout = false;
      this.apiProjectService.updateLocation(this.location).subscribe();
    }
  }

  getLocationPreseCrossRef() {
    this.apiSettingsService.getLocationPresetCrossReferences().subscribe( value => {
      this.locationCrossRef = value;
    })
  }

  getDefaultPresets() {
    this.apiSettingsService.getDefaultPresets().subscribe( value => {
      this.defaultPresets = value;
    })
  }

  getHvacModeLocationGroups() {
    this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.HvacModeGroup).subscribe( value => {
      this.hvacModeLocationGroups = value;
    })
  }

  armRoom() {
   /*  let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isRoomArmed);
        return property;
      }
    ); */

    const parentController: Controller = this.selectedControllers[0]
    const property: Property = parentController.controllerProperties.$values.find(Property.isRoomArmed);

    if (!property) {
      return
    }

    const designation = parentController.designation;
    const propertyId = property.id;
    const value = 1;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  resetMinibar() {
    /* let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isMinibarWasOpenedStatus);
        return property;
      }
    ); */
    const parentController: Controller = this.selectedControllers[0]
    const property: Property = parentController.controllerProperties.$values.find(Property.isMinibarWasOpenedStatus);

    if (!property) {
      return
    }

    const designation = parentController.designation;
    const propertyId = property.id;
    const value = 1;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  setHvacMode(hvacModeNewValue: 0 | 1 | 2) {
    /* const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacHeatCool);
        return property;
      }
    ); */

    const parentController: Controller = this.selectedControllers[0]
    const property: Property = parentController.controllerProperties.$values.find(Property.isHvacHeatCool);

    if (!property) {
      return
    }

    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, hvacModeNewValue)
      .subscribe();
  }

  async openPopoverToSelectPreset(ev) {
    const popover = await this.popoverController.create({
      component: ButtonsSelectComponent,
      cssClass: 'button-select',
      showBackdrop: false,
      event: ev,
      componentProps: {
        locationCrossRef: this.locationCrossRef,
        defaultPresets: this.defaultPresets,
        hvacModeLocationGroups: this.hvacModeLocationGroups,
        controller: this.selectedControllers[0]
      }
    });
    await popover.present();

    popover.onDidDismiss().then( (value) => {
      if (value.data) {
        this.apiSettingsService.activateDefaultPreset(value.data, this.selectedControllers[0].designation, true).subscribe()
      }
    })

}

  selectController(controller: Controller) {
    this.renderNumberInputs = false;
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
    setTimeout(() => { // render is reset to reinit child components and restart ngoinit and ngodestroy of child components
      this.renderNumberInputs = true;
    }, 0);

  }


  isPropertyInController(target: any) {
    let property: Property;
    const active: boolean = this.selectedControllers.some(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(target);
        return property;
      }
    );

    return active
  }

  isSettingInController(target: any) {
    let setting: Setting;
    const active: Controller = this.selectedControllers.find(
      (controller: Controller) => {
        setting = controller.controllerSettings.$values.find(target);
        return setting;
      }
    );

    if(setting && setting.value === '1') {
      return false;
    } else {
      return active
    }
  }

  syncHvacSettings() {
    this.apiProjectService.syncHvacSettings(this.selectedControllers[0].id).subscribe()
  }

  toggleHvac() {
    /* const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacToggle);
        return property;
      }
    ); */
    const parentController: Controller = this.selectedControllers[0]
    const property: Property = parentController.controllerProperties.$values.find(Property.isHvacToggle);

    if (!property) {
      return
    }

    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, 1)
      .subscribe();
  }

  changeControllerPooling(event: any,) {
    const contToSend = structuredClone(this.selectedControllers[0])
    if (event.target.checked) {
      this.apiProjectService.stopPolling(contToSend).subscribe()
    } else {
      this.apiProjectService.resumePolling(contToSend).subscribe()
    }
  }

  changeIgnoreBurglary(event: any, properties: Property[]) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.cancelBubble = true;
    event.stopPropagation();
    this.loadingPublishing = true;
    if (!event.target.checked) {
      this.apiProjectService.stopPublishing(properties[0].id).subscribe(this.afterChangePublishingObserver())
    } else {
      this.apiProjectService.resumePublishing(properties[0].id).subscribe(this.afterChangePublishingObserver())
      this.apiProjectService.changeProperty(this.selectedControllers[0].designation,Number(properties[0].id), 1).subscribe();
    }
  }

  afterChangePublishingObserver(){
    return {next: ()=> {
      this.apiProjectService.getNotPublishingList().subscribe({
        next: (list)=> {
          this.ignorePublishingList = list;
          this.loadingPublishing = false;
        },
        error: ()=> {
          this.loadingPublishing = false;
        }})
    },
    error: ()=> {
      this.loadingPublishing = false;
    }}
    }

  onSelectRoomRadiatorBy(option: 'active by rented' | 'active by presence'){
    this.popoverController.getTop().then(v => v ? this.popoverController.dismiss() : null)
    this.reqInProgress = true;
    const designation = Controller.getMainController(this.selectedControllers).designation;
    let property: Property;
    this.selectedControllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isRadiatorByRented);
        return property;
      }
    );
    if (!property) {
      return
    }
    const propertyId = property.id;
    if(option === 'active by rented'){
      this.apiProjectService.changeProperty(designation, propertyId, 1).subscribe(() => this.reqInProgress = false);
    }else if(option === 'active by presence'){
      this.apiProjectService.changeProperty(designation, propertyId, 0).subscribe(() => this.reqInProgress = false);
    }
  }

  onSelectBathroomRadiatorBy(option: 'active by rented' | 'active by presence'){
    this.popoverController.getTop().then(v => v ? this.popoverController.dismiss() : null)
    this.reqInProgress = true;
    const designation = Controller.getMainController(this.selectedControllers).designation;
    let property: Property;
    this.selectedControllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isBathroomRadiatorByRented);
        return property;
      }
    );
    if (!property) {
      return
    }
    const propertyId = property.id;
    if(option === 'active by rented'){
      this.apiProjectService.changeProperty(designation, propertyId, 1).subscribe(() => this.reqInProgress = false);
    }else if(option === 'active by presence'){
      this.apiProjectService.changeProperty(designation, propertyId, 0).subscribe(() => this.reqInProgress = false);
    }
  }

  getRoomRentedValue(){
    let property: Property;
    this.selectedControllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isRadiatorByRented);
        return property;
      }
    );
    if(property !== undefined && property !== null){
      if(property.value === '0'){
        this.selectedOptionRoom = 'active by presence'
      }else if(property.value === '1'){
        this.selectedOptionRoom = 'active by rented'
      }
    }
  }

  getBathroomRentedValue(){
    let property: Property;
    this.selectedControllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isBathroomRadiatorByRented);
        return property;
      }
    );
    if(property !== undefined && Property !== null){
      if(property.value === '0'){
        this.selectedOptionBathroom = 'active by presence '
      }else if(property.value === '1'){
        this.selectedOptionBathroom = 'active by rented '
      }
    }
  }

  activateProp (findPropFunction: (property: Property) => boolean, value){
    this.popoverController.getTop().then(v => v ? this.popoverController.dismiss() : null)
    let property: Property;
    const parentController: Controller = this.selectedControllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(findPropFunction);
        return property;
      }
    );
    if (parentController && property) {
      const designation = parentController.designation;
      const propertyId = property.id;
      this.apiProjectService
        .changeProperty(designation, propertyId, value)
        .subscribe(() => {
          // this.reqInProgress = false;
        });
    }
  }

  activateRelayOutputProp (findPropFunction: (property: Property) => boolean, value, outputStatusNumber: string){
    this.popoverController.getTop().then(v => v ? this.popoverController.dismiss() : null)
    let property: Property;
    const parentController: Controller = this.selectedControllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find((prop)=> {
          return findPropFunction(prop) && prop.mqttTopic.endsWith(outputStatusNumber)
        });
        return property;
      }
    );
    if (parentController && property) {
      const designation = parentController.designation;
      const propertyId = property.id;
      this.apiProjectService
        .changeProperty(designation, propertyId, value)
        .subscribe(() => {
          // this.reqInProgress = false;
        });
    }
  }

  toggleRelayOutputValue(event: any, findPropFunction: (property: Property) => boolean, relayStatusNumber) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.cancelBubble = true;
    event.stopPropagation();
    let property: Property;
    const parentController: Controller = this.selectedControllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find((prop)=> {
          return findPropFunction(prop) && prop.mqttTopic.endsWith(relayStatusNumber)
        });
        return property;
      }
    );
    if (parentController && property) {
      const designation = parentController.designation;
      const propertyId = property.id;
      if (!event.target.checked) {
        this.apiProjectService.changeProperty(designation, propertyId, 1).subscribe();
      } else {
        this.apiProjectService.changeProperty(designation, propertyId, 0).subscribe();
      }

    }
  }

  setCO2SetPoint(value, CO2SetPointProp: Property) {
    const parentController: Controller = this.selectedControllers[0]
    const designation = parentController.designation;
    this.apiProjectService.changeProperty(designation, CO2SetPointProp.id, value).subscribe();
  }

  syncRekuperator(){
    const cont = Controller.getMainController(this.selectedControllers);
    const rekPropsIds = cont.controllerProperties.$values.filter(prop =>
        Property.isRelayOutputByRentedOrPresence(prop) ||
        Property.isRelayOutputForceOn(prop) ||
        Property.isRelayOutputForceOff(prop) ||
        Property.isRelayOutputInCooling(prop) ||
        Property.isRelayOutputControl(prop)).map(prop => prop.id);
    this.apiProjectService.syncControllerProperties(rekPropsIds).subscribe(value => {
    });
  }

  syncCo2SetPoint(){
    const cont = Controller.getMainController(this.selectedControllers);
    const rekPropsIds = cont.controllerProperties.$values.filter(prop =>
        Property.isCO2SetPoint(prop)).map(prop => prop.id);
    this.apiProjectService.syncControllerProperties(rekPropsIds).subscribe(value => {
    });
  }

  onDestroy(){
    if (this.defaultPresetsSubscription) {
      this.defaultPresetsSubscription.unsubscribe();
    }
    if (this.controllerPollingSubscription) {
      this.controllerPollingSubscription.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
    if(this.pipeSubscription) {
      this.pipeSubscription.unsubscribe();
    }

  }


}
