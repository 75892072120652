<div class="flex-row" >
        <div class="flex-column padding20">
            <h3>{{'Heating' | translate}}</h3>
            <div *ngIf="heatingPresets.length == 0">{{'No preset for active (heating) mode. Create a preset to apply settings' | translate}}</div>
            <ion-button *ngFor="let preset of heatingPresets" class="margin-bottom5" (click)="sendPreset(preset)">
                {{preset.name}}
            </ion-button>
        </div>
        <div class="flex-column padding20">
            <h3>{{'Cooling' | translate}}</h3>
            <div *ngIf="coolingPresets.length == 0">{{'No preset for active (cooling) mode. Create a preset to apply settings' | translate}}</div>
            <ion-button *ngFor="let preset of coolingPresets" class="margin-bottom5" (click)="sendPreset(preset)">
                {{preset.name}}
            </ion-button>
        </div>
</div>