import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { MessagingService } from '../services/messaging.service';
import { UserService } from '../services/user.service';
import { ILoginResponse } from '../models/login-response.interface';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';
import { ToastController } from '@ionic/angular';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(private authService: AuthenticationService,
                private userService: UserService,
                private messagingService: MessagingService,
                private userSettingsService: UserSettingsService,
                private toastController: ToastController,
                private pipe: CustomTranslatePipe
                ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(catchError(err => {
            const errorMessage = err.statusText;
            if (err.status === 401) {
                 if (request.url.includes("refresh") || request.url.includes("login")) {
                    // We do another check to see if refresh token failed
                    // In this case we want to logout user and to redirect it to login page
                    if (request.url.includes("refresh")) {
                        this.authService.logout(true);
                        this.messagingService.setMessage("Your session has expired. Please login again to continue");
                    } else {
                        this.messagingService.setMessage("Wrong username or password/pin");
                    }

                    return throwError(errorMessage);
                }

                if (this.authService.refreshTokenInProgress) {
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => {
                           return next.handle(this.addAuthenticationToken(request))
                        })
                    )
                } else {
                    this.authService.setRefreshTokenInProgress(true);

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null);

                    if (!this.authService.getAccessToken() && !this.authService.getRefreshToken()) {
                        this.authService.logout(true);
                        return throwError(errorMessage);
                    }

                    // Call auth.refreshAccessToken(this is an Observable that will be returned)
                    return this.authService
                        .refreshAccessToken().pipe(
                            switchMap((response: ILoginResponse) => {
                                //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                                // for the next time the token needs to be refreshed
                                this.userService.setUser(response.user);
                                this.authService.setAccessToken(response.tokens.accessToken);
                                this.authService.setRefreshToken(response.tokens.refreshToken);
                                this.authService.setRefreshTokenInProgress(false);
                                this.refreshTokenSubject.next(response);
                                return next.handle(this.addAuthenticationToken(request));
                            }),
                            catchError((err: any) => {
                                this.authService.setRefreshTokenInProgress(false);
                                this.authService.logout(true);
                                return throwError(err.statusText);
                            })
                            );
                        }
            } else {
                if (!request.url.includes('9986')) {
                    if (err.status === 0) {
                        this.messagingService.setMessage('No connection');
                    } else if (err.status === 422) {
                        if (err.error) {
                            const msg = this.localisationForErrorMessage(err.error)
                            /* if (msg) {
                                this.messagingService.setMessage(msg);
                            } else {
                                this.messagingService.setMessage(err.error)
                            } */
                            if(!request.headers.has('skip-interceptor')){
                              this.presentErrorToast(msg? msg : err.error)
                            }
                        } else {
                            // this.messagingService.setMessage('Invalid data');
                            this.presentErrorToast('Invalid data')
                        }
                    } else {
                      this.messagingService.setMessage(err.error);
                    }
                }
                return throwError(err);
            }
        }));
    }

    async presentErrorToast(msg) {
      const toast = await this.toastController.create({
        message: `${this.pipe.transform('An error has occurred')}. ${msg}` ,
        color: 'danger',
        position:'middle',
        buttons: [ {
          text: 'Dismiss',
          role: 'cancel'
        }],
      });
      await toast.present();
    }

    addAuthenticationToken(request) {
        const accessToken = this.authService.getAccessToken();
        if (!accessToken) {
            return request;
        }
        return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + this.authService.getAccessToken()
            }
        });
    }

    localisationForErrorMessage(message: string) {
        const localeId = this.userSettingsService.getLanguage()
        if (message == 'User does not exist!') {
            if (localeId == 'en') {return message;} else {return 'Korisnik ne postoji!'}
          }
        else if (message == 'Max card position reached') {
            if (localeId == 'en') {return message;} else {return 'Dosegnut max broj kartica'}
        }
        else if (message == 'Valid to is expired') {
            if (localeId == 'en') {return message;} else {return 'Validno do je isteklo'}
        }
        else if (message == 'Card with that UID already exists!') {
            if (localeId == 'en') {return "Card is already in use!";} else {return 'Kartica je već u upotrebi!'}
        }
        else if (message == 'User with that ID already exists!') {
            if (localeId == 'en') {return message;} else {return 'Korisnik s tim UID-om postoji!'}
        }
        else if (message == 'First name is too long (max. is 128 chars).') {
            if (localeId == 'en') {return message;} else {return 'Ime je predugo (max. je 128 znakova).'}
        }
        else if (message == 'Last name is too long (max. is 128 chars).') {
            if (localeId == 'en') {return message;} else {return 'Prezime je predugo (max. je 128 znakova).'}
        }
        else if (message == 'Email is too long (max. is 320 chars).') {
            if (localeId == 'en') {return message;} else {return 'Email je predug (max. je 320 znakova).'}
        }
        else if (message == 'First name cannot be null') {
            if (localeId == 'en') {return message;} else {return 'Ime ne može biti null'}
        }
        else if (message == 'Last name cannot be null') {
            if (localeId == 'en') {return message;} else {return 'Prezime ne može biti null'}
        }
        else if (message == 'Problem with updating controllers') {
            if (localeId == 'en') {return message;} else {return 'Problem s ažuriranjem kontrolera'}
        } else {
            return undefined;
        }
    }
}
