<div class="alert-container" *ngIf="anyAlertForDisplay">
    <div *ngFor="let alarm of alarms; let i = index;">
        <div *ngIf="alarm.displayAlert && displayAlert(i)" class="alert-box"
            [ngClass]="{
                info: alarm.level === 1,
                warning: alarm.level === 2,
                danger: alarm.level === 3,
                critical: alarm.level === 4
            }" @insertRemoveTrigger>
            <div class="flex-column to-right">
                <p>{{ alarm.time | date:'dd.MM.yyyy. HH:mm' }}</p>
                <p>{{ alarm.message }}</p>
            </div>
            <i class="icon-x font-size12" (click)="confirmAlarm(i)"></i>
        </div>
    </div>
</div>
