import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-display-dictionary-prop-in-room-modal',
  templateUrl: './display-dictionary-prop-in-room-modal.component.html',
  styleUrls: ['./display-dictionary-prop-in-room-modal.component.scss']
})
export class DisplayDictionaryPropInRoomModalComponent implements OnInit, OnDestroy {
  @Input({required:true}) controllers: Controller[];
  @Input({required:true}) forcePropIndex: number;
  @Input({required:true}) findPropFunction: (property: Property) => boolean;
  @Input() icon = 'icon-temp';
  @Input({required:true}) dictionary;
  updatedSub: Subscription;
  updated;
  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.updatedSub = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
    })
  }

  ngOnDestroy () {
    if (this.updatedSub) {
      this.updatedSub.unsubscribe();
    }
  }
}
