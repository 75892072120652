import { Pipe, PipeTransform } from '@angular/core';

import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'commonAreasExistInProject'
})
export class CommonAreasExistInProject implements PipeTransform {

  transform(locations: Location[]): boolean {
        return locations.some( loc => Location.isAccessControl(loc) || Location.isHvacCommonArea(loc) || Location.isIOCommonArea(loc))
  }
}
