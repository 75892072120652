<ng-container
  *ngIf="controllers | findProperty : propFilterFunction: null : true as numericProps"
>
<div *ngIf="forcePropIndex===-1">
  <div
    *ngFor="let numericProp of numericProps"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <div class="{{icon}} icon-background-off font-size19"></div>
    <div>
      <span class="status-text-off">{{ numericProp?.value }} {{sufix}}</span>
      <span class="status-text-off-small">{{
        numericProp.name | translate
      }}</span>
    </div>
  </div>
</div>
<div
  *ngIf="forcePropIndex!=-1"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <div class="{{icon}} icon-background-off font-size19"></div>
    <div>
      <span class="status-text-off">{{ numericProps[forcePropIndex]?.value }} {{sufix}}</span>
      <span class="status-text-off-small">{{
        numericProps[forcePropIndex]?.name | translate
      }}</span>
    </div>
  </div>
</ng-container>
