import { Pipe, PipeTransform } from '@angular/core';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'floorHaveAcLocationsPipe'
})
export class FloorHaveAcLocations implements PipeTransform {

  transform(floor: LocationGroup): boolean {
        return floor.locations.some( loc => Location.isAccessControl(loc))
  }
}
