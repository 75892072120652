<form [formGroup]="timeConditionForm">
  <div class="container-card padding15">
    <div>{{ 'start_automation_at' | translate }}:</div>
    <div class="items-grid-container">
      <div
        class="single-grid-item-container"
        (click)="selectSpecialStartTimeType(0)"
      >
        <div
          [ngClass]="[
            timeConditionForm.controls.specialStartTime.value === 0
              ? 'icon-selected'
              : 'icon-not-selected'
          ]"
          class="grid-item-icon icon-noun_clock font-size30"
        ></div>
        <div class="grid-item-icon-text">{{ 'custom_time' | translate }}</div>
      </div>
      <div
        class="single-grid-item-container"
        (click)="selectSpecialStartTimeType(1)"
      >
        <div
          class="grid-item-icon icon-sunrise font-size30"
          [ngClass]="[
            timeConditionForm.controls.specialStartTime.value === 1
              ? 'icon-selected'
              : 'icon-not-selected'
          ]"
        ></div>
        <div class="grid-item-icon-text">{{ 'sunrise' | translate }}</div>
      </div>
      <div
        class="single-grid-item-container"
        (click)="selectSpecialStartTimeType(2)"
      >
        <div
          class="grid-item-icon icon-sunset font-size30"
          [ngClass]="[
            timeConditionForm.controls.specialStartTime.value === 2
              ? 'icon-selected'
              : 'icon-not-selected'
          ]"
        ></div>
        <div class="grid-item-icon-text">{{ 'sunset' | translate }}</div>
      </div>
    </div>
    <div
      [ngSwitch]="timeConditionForm.controls.specialStartTime.value"
      class="margin-top20"
    >
      <div *ngSwitchCase="0" class="margin-bottom10">

        <input
          [min]="earliestStartTime"
          type="text"
          [owlDateTimeTrigger]="dtStartTime"
          [owlDateTime]="dtStartTime"
          formControlName="startTime"
          placeholder="{{'date_and_time' | translate}}"
          class="input width170"
        />

        <owl-date-time
          #dtStartTime
          (afterPickerClosed)="onCloseTimer()"
          [pickerMode]="'popup'"
        ></owl-date-time>
      </div>
      <div *ngSwitchCase="1" class="flex-row margin-bottom10">
        <div
          *ngIf="beforeOrAfter === 'after' || beforeOrAfter === 'before'"
          class="flex-row flex-center"
        >
          <input
            class="input width-50"
            type="number"
            formControlName="startTimeOffsetMinutes"
            min="1"
          />
          <div class="text-label">{{ 'minutes' | translate }}</div>
        </div>

        <select
          [(ngModel)]="beforeOrAfter"
          [ngModelOptions]="{standalone: true}"
          (change)="onChangeBeforeOrAfterSpecialTime($event.target.value)"
          class="select width140"
          [ngClass]="[beforeOrAfter === 'at' ? 'margin-right116' : '']"
        >
          <option value="at">{{ 'at_sunrise' | translate }}</option>
          <option value="before">{{ 'before_sunrise' | translate }}</option>
          <option value="after">{{ 'after_sunrise' | translate }}</option>
        </select>
      </div>
      <div *ngSwitchCase="2" class="flex-row margin-bottom10">
        <div
          *ngIf="beforeOrAfter === 'after' || beforeOrAfter === 'before'"
          class="flex-row flex-center"
        >
          <input
            class="input width-50"
            type="number"
            formControlName="startTimeOffsetMinutes"
            min="1"
          />
          <div class="text-label">{{ 'minutes' | translate }}</div>
        </div>
        <select
          [(ngModel)]="beforeOrAfter"
          [ngModelOptions]="{standalone: true}"
          (change)="onChangeBeforeOrAfterSpecialTime($event.target.value)"
          class="select width140"
          [ngClass]="[beforeOrAfter === 'at' ? 'margin-right116' : '']"
        >
          <option value="at">{{ 'at_sunset' | translate }}</option>
          <option value="before">{{ 'before_sunset' | translate }}</option>
          <option value="after">{{ 'after_sunset' | translate }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="container-card padding15">
    <div>{{ 'repeat_automation' | translate }}:</div>
    <!-- <select
      class="select"
      [(ngModel)]="repeatOptions"
      [ngModelOptions]="{standalone: true}"
      (change)="onChangeRepeatOptions($event.target.value)">
      <option value="noRepeat">{{ 'dont_repeat' | translate }}</option>
      <option value="regularly">{{ 'repeat_regulary' | translate }}</option>
      <option value="custom">{{ 'repeat_at_custom_intervals' | translate }}</option>
      <option value="specific">{{ 'repeat_on_specific_days' | translate }}</option>
    </select> -->
    <div class="flex-row-center-vertical margin-top5">
      <input
        class="clickable"
        [(ngModel)]="repeatOptions"
        [ngModelOptions]="{ standalone: true }"
        value="noRepeat"
        id="noRepeatId"
        type="radio"/>
      <label
        class="margin-left5 clickable"
        for="noRepeatId">
        {{'dont_repeat' | translate}}</label>
    </div>
    <div class="flex-row-center-vertical margin-top5">
      <input
        class="clickable"
        [(ngModel)]="repeatOptions"
        [ngModelOptions]="{ standalone: true }"
        value="regularly"
        (change)="onChangeRepeatOptions($event.target.value)"
        id="regularlyId"
        type="radio"/>
        <label
          class="margin-left5 clickable"
          for="regularlyId">
          {{'repeat_regularly' | translate}}</label>
    </div>
    <div class="flex-row-center-vertical margin-top5">
      <input
        class="clickable"
        [(ngModel)]="repeatOptions"
        [ngModelOptions]="{ standalone: true }"
        value="custom"
        (change)="onChangeRepeatOptions($event.target.value)"
        id="customId"
        type="radio"/>
        <label
          class="margin-left5 clickable"
          for="customId">
          {{'repeat_at_custom_intervals' | translate}}</label>
    </div>
    <div class="flex-row-center-vertical margin-top5">
      <input
        class="clickable"
        [(ngModel)]="repeatOptions"
        [ngModelOptions]="{ standalone: true }"
        value="specific"
        (change)="onChangeRepeatOptions($event.target.value)"
        id="specificId"
        type="radio"/>
        <label
          class="margin-left5 clickable"
          for="specificId">
          {{'repeat_on_specific_days' | translate}}</label>
    </div>
    <div class="margin-top10">
    <div *ngIf="repeatOptions === 'regularly'" class="flex-row align-center ">
      <div>{{ 'every' | translate }} </div>
      <select class="select" formControlName="repeatType">
        <option *ngFor="let type of repeatTypes | slice:1:7" [value]="type.value">
          {{ type.text }}
        </option>
      </select>
    </div>
    <div *ngIf="repeatOptions === 'custom'" class="flex-row align-center">
      <div>{{ 'every' | translate }} </div>
      <input
        type="number"
        class="input width-75"
        min=1
        formControlName="repeatParameter"
        [ngClass]="{ 'is-invalid': submitted && timeConditionForm.controls.repeatParameter.errors }"
        >
      <select class="select" formControlName="repeatType">
        <option *ngFor="let type of repeatTypes | slice:7:13" [value]="type.value">
          {{ type.text }}
        </option>
      </select>
    </div>

    <div *ngIf="repeatOptions === 'specific'">
    <div class="flex-row align-center">
      <ion-button size="default" class="square-secondary" color="ocean-blue" (click)="openSelectDaysModal()">{{ 'select_days' | translate }}</ion-button>
      <div
        *ngIf="submitted && timeConditionForm.controls.repeatParameter.errors"
        [ngClass]="{ 'invalid-input': submitted && timeConditionForm.controls.repeatParameter.errors }">
        {{ 'no_days_selected' | translate }}
      </div>
    </div>
    </div>
  </div>

  </div>
</form>
<div class="container-card padding15">
  <input
        class="option-input-sm"
        type="checkbox"
        [(ngModel)]="includeValidToFrom"
        (change)="toggleIncludeAutoValidTo()">
        <label >{{ 'limit_valid_time' | translate }}</label>
  <div *ngIf="includeValidToFrom" class="margin-top15">

  <div class="flex-row align-center">
    <label class="text-label45" >{{ 'From' | translate }}:</label>
    <div class="date-time-container">
      <input
        [min]="earliestStartTime"
        type="text"
        [owlDateTimeTrigger]="dtFrom"
        [owlDateTime]="dtFrom"
        [selectMode]="'rangeFrom'"
        (dateTimeInput)="dateTimeInput($event)"
        [(ngModel)]="selectedDateTime"
        placeholder="{{'date_and_time' | translate}}"
        class="input width170"

      />
      <span
        class="icon-clear font-sizw22"
        *ngIf="
          selectedDateTime[0] !== undefined && selectedDateTime[0] !== null
        "
        (click)="onClearDateTime(0)"
      >
      </span>
    </div>

    <i class="icon-calendar font-size32 margin-bottom2" [owlDateTimeTrigger]="dtFrom"></i>

    <owl-date-time
      #dtFrom
      (afterPickerClosed)="onCloseFrom()"
      [pickerMode]="'popup'"
    ></owl-date-time>
  </div>
  <div class="flex-row align-center">
    <label
      class="text-label45 margin-bottom5"
      >
      {{ 'To' | translate }}:
    </label>
    <div class="date-time-container">
      <input
        [min]="earliestStartTime"
        type="text"
        [owlDateTimeTrigger]="dtTo"
        [owlDateTime]="dtTo"
        (dateTimeInput)="dateTimeInput($event)"
        placeholder="{{'date_and_time' | translate}}"
        [selectMode]="'rangeTo'"
        [(ngModel)]="selectedDateTime"
        class="input width170"
      />
      <span
        class="icon-clear font-sizw22"
        *ngIf="
          selectedDateTime[1] !== undefined && selectedDateTime[1] !== null
        "
        (click)="onClearDateTime(1)"
      >
      </span>
    </div>

    <i class="icon-calendar font-size32 margin-bottom2" [owlDateTimeTrigger]="dtTo"></i>

    <owl-date-time
      #dtTo
      (afterPickerClosed)="onCloseTo()"
      [pickerMode]="'popup'"
    ></owl-date-time>
  </div>
</div>
</div>

<div class="margin-top20 flex-row flex-end margin-bottom10">
  <ion-button size="default" class="square-secondary margin-right5" color="ocean-blue" (click)="onCancel()">{{ 'cancel' | translate }}</ion-button>
  <ion-button size="default" class="square-secondary" (click)="onApply()" color="ocean-blue">{{ 'apply' | translate }}</ion-button>
</div>
