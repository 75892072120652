import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IMqttMessage } from 'ngx-mqtt';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { User } from 'src/app/core/models/user/user.model';
import { AlarmSubscriptionsService } from './alarm-subscriptions.service';
import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { ProjectService } from '../../project/services/project.service';




@Injectable({
    providedIn: 'root'
})
export class AlarmAlertsService {
    alarmsInitialized = false;
    mqttBase: string;
    mqttAlarmsSubscription = new Subscription();
    alarms: AlarmAlert[] = [];
    alarmsChanged = new Subject<AlarmAlert[]>();
    alarmAdded = new Subject<AlarmAlert>();
    unconfirmedAlarms = new BehaviorSubject<number>(0);
    alarmsIncomming = new BehaviorSubject<boolean>(false);
    showLevelFourModal$ = new BehaviorSubject<boolean>(false);

    constructor(
      private currentUserStoreService: CurrentUserStoreService,
      private alarmSubscriptionsService: AlarmSubscriptionsService,
      private messagingService: MessagingService
      ) {}

    getAlarms(): AlarmAlert[] {
        return this.alarms.slice();
    }

   /*  getAlarmsSize() {
        return this.alarmsSize$;
    }

    setAlarmsSize(value: boolean) {
        this.alarmsSize$.next(value);
    } */

    setAlarms(alarms: AlarmAlert[]) {
        this.alarms = alarms;
        this.alarmsChanged.next(this.alarms);
    }

    addAlarm(alarm: AlarmAlert) {
        this.messagingService.setMessage(alarm.message);
        this.messagingService.setAlarmAlert(alarm);
        this.alarms.push(alarm);
        this.alarmAdded.next(alarm);
        this.alarmsChanged.next(this.alarms);
    }

    updateAlarm(i: number, alarm: AlarmAlert) {
        this.alarms[i] = alarm;
        this.alarmsChanged.next(this.alarms);
    }

    // initAlarmsService() {
    //     if (this.alarmsInitialized) {
    //         return;
    //     }
    //     this.api.getBaseMqttSubscription().subscribe(mqttBase => {
    //          this.mqttBase = mqttBase;
    //          this.subscribeToMqttAlarms();
    //         });
    //     this.alarmsInitialized = true;
    // }

    handleMqttAlarms(message: IMqttMessage) {
            const alertParts = message.payload.toString().split('|');
            let newAlarm;
            const alrmTypeId = message.topic.split('/').at(-1)
            if (+alertParts[2].length === 1) {
                newAlarm = {
                    time: new Date(alertParts[0]),
                    message: alertParts[1],
                    level: +alertParts[3],
                    confirmed: false,
                    displayAlert: true,
                    alarmTypeId: alrmTypeId

                };
            } else {
                newAlarm = {
                    time: new Date(alertParts[0]),
                    message: alertParts[1],
                    level: +alertParts[3][0],
                    confirmed: false,
                    displayAlert: true,
                    alarmTypeId: alrmTypeId}
            }
            const alarmType = this.alarmSubscriptionsService.getAlarmTypes().find((aType: AlarmType)=> {
              return aType.alarmTypeId.toString() === alrmTypeId.toString();
            })
            if (alarmType?.permissions?.$values[0]?.permissionId) {
              const user: User = Object.assign(new User(), this.currentUserStoreService.getUser());
              // check if user has permission to view alarm and if he has no perm ignore alarm
              const permId = alarmType.permissions.$values[0].permissionId;
              const userHasPermissionToViewAlarm = user.havePermission(permId);
              if (!userHasPermissionToViewAlarm) {
                return;
              }
            }
            this.addAlarm(newAlarm);
            if(newAlarm.level === 4){
              this.showLevelFourModal$.next(true);
            }
            // added this if because there is only one lvl4 message and it is not being saved to unconfirmed alarms
            if(newAlarm.level !== 4){
              this.setUnconfirmedAlarmsNumber(this.unconfirmedAlarms.getValue() + 1);
            }
            this.alarmsIncomming.next(true);
           }


    confirmAlarm(id: number) {
        this.alarms = this.alarms.filter( element => element.alarmLogId !== id);
        this.alarmsChanged.next(this.alarms);
    }

    unsubscribeFromMqttAlarms() {
        this.mqttAlarmsSubscription.unsubscribe();
        this.mqttAlarmsSubscription = new Subscription();
        this.alarmsInitialized = false;
    }

    getUnconfirmedAlarmsNumber() {
        return this.unconfirmedAlarms.asObservable()
    }

    setUnconfirmedAlarmsNumber(totalCount: number) {
        this.unconfirmedAlarms.next(totalCount)
    }
}
