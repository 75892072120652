<div class="sidebar">
  <div class="sidebar-links-container">
    <!-- ROOMS -->
    <a
      [ngClass]="[
        true
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-column-center-full"
      routerLink="/rooms"
      routerLinkActive="sidebar-active-link"

    >
      <i
        [ngClass]="[
          true
            ? 'icon-rooms font-size23 white'
            : 'icon-rooms font-size23 gray'
        ]"
      ></i>
      <p class="text-align-center">{{'Rooms' | translate}}</p>
    </a>
    <!-- COMMON AREAS -->
    <a
    [ngClass]="[
      true
        ? 'sidebar-link'
        : 'sidebar-link-disabled'
    ]"
    class="flex-column-center-full"
    routerLink="/common-area"
    routerLinkActive="sidebar-active-link"

  >
    <i
      [ngClass]="[
        true
          ? 'icon-corridor font-size23 white'
          : 'icon-corridor font-size23 gray'
      ]"
    ></i>
    <p class="text-align-center">{{'Common areas' | translate}}</p>
  </a>
      <!-- GROUPS-->
      <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.LocationGroupsUpdate)
        || signedInUser.havePermission(swPermissions.LocationGroupsActivatePresets)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-column-center-full"
      routerLink="/groups"
      routerLinkActive="sidebar-active-link"

    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.LocationGroupsUpdate)
        || signedInUser.havePermission(swPermissions.LocationGroupsActivatePresets)
            ? 'icon-group font-size23 white'
            : 'icon-group font-size23 gray'
        ]"
      ></i>
      <p class="text-align-center">{{'Groups' | translate}}</p>
    </a>
<!-- CARDS -->
    <a
    [ngClass]="[
      signedInUser.havePermission(swPermissions.CardsStaffUpdate) ||
      signedInUser.havePermission(swPermissions.CardsGuestUpdate)
        ? 'sidebar-link'
        : 'sidebar-link-disabled'
    ]"
    class="flex-column-center-full"
    routerLink="/cards"
    routerLinkActive="sidebar-active-link"

  >
    <i
      [ngClass]="[
         signedInUser.havePermission(swPermissions.CardsStaffUpdate) ||
        signedInUser.havePermission(swPermissions.CardsGuestUpdate)
          ? 'icon-card font-size25 white'
          : 'icon-card font-size25 gray'
      ]"
    ></i>
    <p class="text-align-center">{{'Cards' | translate}}</p>
  </a>

<!-- AUTOMATIONS -->
  <a
  [ngClass]="[
    signedInUser.havePermission(swPermissions.AutomationsView)
      ? 'sidebar-link'
      : 'sidebar-link-disabled'
  ]"
  class="flex-column-center-full"
  routerLink="/schedule"
  routerLinkActive="sidebar-active-link"

>
  <i
    [ngClass]="[
      signedInUser.havePermission(swPermissions.AutomationsView)
        ? 'icon-automation white font-size23'
        : 'icon-automation gray font-size23'
    ]"
  ></i>
  <p class="text-align-center" >{{'Automations' | translate}}</p>
</a>
<!-- ACCESS LOG -->
    <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.AccessLogView)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-column-center-full"
      routerLink="/logs/access"
      routerLinkActive="sidebar-active-link"

    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.AccessLogView)
            ? 'icon-access-log white font-size23'
            : 'icon-access-log gray font-size23'
        ]"
      ></i>
      <p class="text-align-center">{{'Access log' | translate}}</p>
    </a>
    <!-- ALARM LOG -->
    <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.AlarmsLogView)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-column-center-full"
      routerLink="/logs/alarms"
      routerLinkActive="sidebar-active-link"

    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.AlarmsLogView)
            ? 'icon-alarm_log white font-size23'
            : 'icon-alarm_log gray font-size23'
        ]"
      ></i>
      <p class="text-align-center">{{'Alarm log' | translate}}</p>
    </a>
    <!-- ANALYTICS -->
    <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.AnalyticsView)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-column-center-full"
      routerLink="/settings/analytics"
      routerLinkActive="sidebar-active-link"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.AnalyticsView)
            ? 'icon-analytics font-size30 white'
            : 'icon-analytics font-size30 gray'
        ]"
      ></i>
      <p class="text-align-center">{{ "Analytics" | translate }}</p>
    </a>
    <!-- SETTINGS -->
    <a
    [ngClass]="[
      signedInUser.havePermission(swPermissions.Settings)
        ? 'sidebar-link'
        : 'sidebar-link-disabled'
    ]"
    class="flex-column-center-full"
    routerLink="/settings"
    routerLinkActive="sidebar-active-link"
    [routerLinkActiveOptions]="{ exact: true }"

  >
    <i
      [ngClass]="[
        signedInUser.havePermission(swPermissions.Settings)
          ? 'icon-settings font-size23 white'
          : 'icon-settings font-size23 gray'
      ]"
    ></i>
    <p class="text-align-center">{{'Settings' | translate}}</p>
  </a>
  <!-- HOW TO -->
  <a
    [ngClass]="[
    true
        ? 'sidebar-link'
        : 'sidebar-link-disabled'
    ]"
    class="flex-column-center-full"
    routerLink="/how-to"
    routerLinkActive="sidebar-active-link"
    [routerLinkActiveOptions]="{ exact: true }"

  >
    <i
      [ngClass]="[
        true
          ? 'icon-question font-size23 white'
          : 'icon-question font-size23 gray'
      ]"
    ></i>
    <p class="text-align-center">{{'How to' | translate}}</p>
  </a>

  <!-- HOW TO -->
  <a
    [ngClass]="[
    true
        ? 'sidebar-link'
        : 'sidebar-link-disabled'
    ]"
    class="flex-column-center-full"
    (click)="onReportIssue()"
    routerLinkActive="sidebar-active-link"
    [routerLinkActiveOptions]="{ exact: true }"

  >
    <i
      style="font-weight: 600;"
      [ngClass]="[
        true
          ? 'icon-technician font-size23 white'
          : 'icon-technician font-size23 gray'
      ]"
    ></i>
    <p class="text-align-center">{{'Report an issue' | translate}}</p>
  </a>

  </div>
</div>
