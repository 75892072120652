import { Injectable } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor() { }

  private keyRooms = 'scrollPositionRooms';
  private keyCommonAreas = 'scrollPositionCommonAreas';

  private contentSubject = new BehaviorSubject<IonContent | null>(null);
  public content$ = this.contentSubject.asObservable();

  setContent(content: IonContent | null): void {
    this.contentSubject.next(content);
  }

  saveScrollPositionRooms(position: number): void {
    localStorage.setItem(this.keyRooms, position.toString());
  }

  getSavedScrollPositionRooms(): string {
    const savedPosition = localStorage.getItem(this.keyRooms);
    return savedPosition;
  }

  saveScrollPositionCommonAreas(position: number): void {
    localStorage.setItem(this.keyCommonAreas, position.toString());
  }

  getSavedScrollPositionCommonAreas(): string {
    const savedPosition = localStorage.getItem(this.keyCommonAreas);
    return savedPosition;
  }
}
