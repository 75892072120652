import { Component,  EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { ModalController } from '@ionic/angular';
import { TimedConditon } from 'src/app/core/models/automation/timed-condition.model';
import { SelectDaysModalComponent } from '../select-days-modal/select-days-modal.component';

@Component({
  selector: 'app-time-condition',
  templateUrl: './time-condition.component.html',
  styleUrls: ['./time-condition.component.scss']
})
export class TimeConditionComponent implements OnInit {

  earliestStartTime: Date = new Date();
  defaultOffsetMin = 30;
  timeConditionForm: UntypedFormGroup;
  beforeOrAfter: 'before' |'at' | 'after';
  repeatOptions: 'noRepeat' | 'regularly'| 'custom' | 'specific' = 'noRepeat';
  submitted = false;
  includeValidToFrom = false;

  selectDaysModalOpen = false;
  modalContent: number[] = [1, 15];


  @ViewChild('dtStartTime', { static: false }) dtStartTime: OwlDateTimeComponent<any>;
  @Output() timeCondition$ = new EventEmitter<TimedConditon>();
  selectedDateTime: Date[] = [];
  @Input() inputTimeCondition: TimedConditon;

  @ViewChild('dtFrom', { static: false }) dtFrom: OwlDateTimeComponent<any>;
  @ViewChild('dtTo', { static: false }) dtTo: OwlDateTimeComponent<any>;

  repeatTypes = [
    {value: 0 , text: 'Don\'t repeat'},
    {value: 1 , text: 'Minute'},
    {value: 2 , text: 'Hour'},
    {value: 3 , text: 'Day'},
    {value: 4 , text: 'Week'},
    {value: 5 , text: 'Month'},
    {value: 6 , text: 'Year'},
    {value: 7 , text: 'Minutes'},
    {value: 8 , text: 'Hours'},
    {value: 9 , text: 'Days'},
    {value: 10 , text: 'Weeks'},
    {value: 11 , text: 'Months'},
    {value: 12 , text: 'Years'},
    {value: 13 , text: 'Custom Days'}
  ];


  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalController: ModalController
    ) { }

  ngOnInit(): void {

    if (!this.inputTimeCondition) {
    this.timeConditionForm = this.formBuilder.group({
      automationConditionType: 1,
      startTime: [this.earliestStartTime],
      specialStartTime: [0],
      startTimeOffsetMinutes: [0],
      repeatType: [0],
      repeatParameter: [],
      activeFrom: [undefined],
      activeTo: [undefined],
    });
  } else {
    this.earliestStartTime = this.inputTimeCondition.startTime as Date;
    this.timeConditionForm = this.formBuilder.group({
      automationConditionType: 1,
      startTime: [this.inputTimeCondition.startTime],
      specialStartTime: [this.inputTimeCondition.specialStartTime],
      startTimeOffsetMinutes: [Math.abs(this.inputTimeCondition.startTimeOffsetMinutes)],
      repeatParameter: [this.inputTimeCondition.repeatParameter],
      repeatType: [this.inputTimeCondition.repeatType],
      activeFrom: [this.inputTimeCondition.activeFrom],
      activeTo: [this.inputTimeCondition.activeTo],
    });
    if (this.inputTimeCondition.activeFrom || this.inputTimeCondition.activeTo ) {
      this.includeValidToFrom = true;
    }
    this.selectedDateTime[0] = this.inputTimeCondition.activeFrom as Date;
    this.selectedDateTime[1] = this.inputTimeCondition.activeTo as Date;
    if (this.inputTimeCondition.startTimeOffsetMinutes === 0) {
      this.beforeOrAfter = 'at';
    } else if (this.inputTimeCondition.startTimeOffsetMinutes > 0) {
      this.beforeOrAfter = 'after';

    }else if (this.inputTimeCondition.startTimeOffsetMinutes < 0) {
      this.beforeOrAfter = 'before';
    }

    if (this.inputTimeCondition.repeatType === 0) {
      this.repeatOptions = 'noRepeat';
    } else if (this.inputTimeCondition.repeatType >= 1 && this.inputTimeCondition.repeatType <= 6) {
      this.repeatOptions = 'regularly';
    } else if (this.inputTimeCondition.repeatType >= 7 && this.inputTimeCondition.repeatType <= 12) {
      this.repeatOptions = 'custom';
      this.timeConditionForm.controls.repeatParameter.setValidators([Validators.required, Validators.min(1)]);
      this.timeConditionForm.controls.repeatParameter.updateValueAndValidity();
    } else if (this.inputTimeCondition.repeatType === 13) {
      this.repeatOptions = 'specific';
      this.timeConditionForm.controls.repeatParameter.setValidators([Validators.required]);
      this.timeConditionForm.controls.repeatParameter.updateValueAndValidity();
    }
  }

  }


  selectSpecialStartTimeType(value: number) {
    if (value !== this.timeConditionForm.controls.specialStartTime.value) {
      this.beforeOrAfter = 'at';
      this.timeConditionForm.controls.specialStartTime.setValue(value);
      if (value === 0) {
        this.timeConditionForm.controls.startTime.setValue(new Date());
      }
    }
  }

  onChangeBeforeOrAfterSpecialTime(value: 'before' |'at' | 'after') {

    if (value === 'at') {
      this.timeConditionForm.controls.startTimeOffsetMinutes.setValue(0);
    } else {
      this.timeConditionForm.controls.startTimeOffsetMinutes.setValue(this.defaultOffsetMin);
    }
  }

  onChangeRepeatOptions(value: 'noRepeat' | 'regularly'| 'custom' | 'specific') {
    switch (value) {
      case 'noRepeat': {
        this.timeConditionForm.controls.repeatType.setValue(0);
        this.timeConditionForm.controls.repeatParameter.setValue([]);
        this.timeConditionForm.controls.repeatParameter.clearValidators();
        this.timeConditionForm.controls.repeatParameter.updateValueAndValidity();
        break;
      }case 'regularly': {
        this.timeConditionForm.controls.repeatType.setValue(1);
        this.timeConditionForm.controls.repeatParameter.setValue([]);
        this.timeConditionForm.controls.repeatParameter.clearValidators();
        this.timeConditionForm.controls.repeatParameter.updateValueAndValidity();
        break;
      }case 'custom': {
        const defaultCustomValue = 5;
        this.timeConditionForm.controls.repeatType.setValue(7);
        this.timeConditionForm.controls.repeatParameter.setValue([defaultCustomValue]);
        this.timeConditionForm.controls.repeatParameter.setValidators([Validators.required, Validators.min(1)]);
        this.timeConditionForm.controls.repeatParameter.updateValueAndValidity();
        break;
      }case 'specific': {
        this.timeConditionForm.controls.repeatType.setValue(13);
        this.timeConditionForm.controls.repeatParameter.setValue([]);
        this.timeConditionForm.controls.repeatParameter.setValidators(Validators.required);
        this.timeConditionForm.controls.repeatParameter.updateValueAndValidity();
        break;
      }
      default: {
        break;
      }
     }
  }

  onClearDateTime(index: number) {
    if (index === 0 ) {
      this.timeConditionForm.controls.activeFrom.setValue(null);
    } else if (index === 1) {
      this.timeConditionForm.controls.activeTo.setValue(null);
    }
    this.selectedDateTime[index] = null;
    this.selectedDateTime = this.selectedDateTime.slice();
  }

  dateTimeInput(event) {
    this.timeConditionForm.controls.activeFrom.setValue(event.value[0]);
    this.timeConditionForm.controls.activeTo.setValue(event.value[1]);
  }



  onCloseTimer() {
    const newTime: Date = this.dtStartTime.selected;
    this.timeConditionForm.controls.startTime.setValue(newTime);
  }

  onCloseFrom() {
    this.selectedDateTime = [this.dtFrom.selecteds[0], this.dtFrom.selecteds[1]];
    this.timeConditionForm.controls.activeFrom.setValue(this.dtFrom.selecteds[0]);
    this.timeConditionForm.controls.activeTo.setValue(this.dtFrom.selecteds[1]);
  }

  onCloseTo() {
    this.selectedDateTime = [this.dtTo.selecteds[0], this.dtTo.selecteds[1]];
    this.timeConditionForm.controls.activeFrom.setValue(this.dtTo.selecteds[0]);
    this.timeConditionForm.controls.activeTo.setValue(this.dtTo.selecteds[1]);
  }


  async openSelectDaysModal() {
    const modal = await this.modalController.create({
      component: SelectDaysModalComponent,
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        modalContent: this.timeConditionForm.controls.repeatParameter.value
      }
    });
    modal.onDidDismiss()
      .then((resp) => {
        if (resp.data) {
          this.timeConditionForm.controls.repeatParameter.setValue(resp.data);
        }
      }
      );

    return await modal.present();
  }


  toggleIncludeAutoValidTo() {
    if (this.includeValidToFrom === false) {
      this.timeConditionForm.controls.activeFrom.setValue(null);
      this.timeConditionForm.controls.activeTo.setValue(null);
      this.selectedDateTime[0] = null;
      this.selectedDateTime[1] = null;
      this.selectedDateTime = this.selectedDateTime.slice();
    } else {
      const newDate = new Date();
      this.timeConditionForm.controls.activeFrom.setValue(newDate);
      this.selectedDateTime[0] = newDate;
      this.selectedDateTime = this.selectedDateTime.slice();
    }
  }


  onCancel() {
    this.timeCondition$.emit();
  }

  onApply() {
    this.submitted = true;

    if (this.timeConditionForm.valid) {
      if (this.beforeOrAfter === 'before') {
        const offset = Math.abs(this.timeConditionForm.controls.startTimeOffsetMinutes.value) * -1;
        this.timeConditionForm.controls.startTimeOffsetMinutes.setValue(offset);
      } else if (this.beforeOrAfter === 'at'){
        this.timeConditionForm.controls.startTimeOffsetMinutes.setValue(0);
      }
      if (!this.timeConditionForm.controls.activeFrom.value) {
        this.timeConditionForm.controls.activeFrom.setValue(new Date());
      }
      const newTimeCondition: TimedConditon = Object.assign(new TimedConditon(), this.timeConditionForm.value);
      this.timeCondition$.emit(newTimeCondition);
    }
  }
}



