import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'sortFanCoilSpeedProps',
})
export class SortFanCoilSpeedPropsPipe implements PipeTransform {
  transform(fancoilSpeedProperties: Property[]): Property[] {
    // sorts props from highest v to lowest v3->v2->v1
    return fancoilSpeedProperties.sort((a,b)=> {
      return b.type-a.type
    }
  );
  }

}
