import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'propIsActive',
  pure: false
})
export class PropIsActivePipe implements PipeTransform {

  transform(properties: any): boolean {
    if (!Array.isArray(properties)) {
      properties = [properties];
    }
    if(properties?.length === 0) {
      return false;
    }
    return properties.some((prop)=> {
      return Property.isActive(prop)
    })

  }

}
