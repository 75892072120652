import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiLocationGroupsService, LocationGroup } from '../../../../shared/services/api-location-groups.service';

@Component({
  selector: 'app-hvac-mode-modal',
  templateUrl: './hvac-mode-modal.component.html',
  styleUrls: ['./hvac-mode-modal.component.scss']
})
export class HvacModeModalComponent implements OnInit {

  HVACGroups$: Observable<LocationGroup[]>;
  loading: Array<number> = [];

  constructor(private apiLocationGroupsService: ApiLocationGroupsService,
              private apiProjectService: ApiProjectService,
              private alertController: AlertController) { }

  ngOnInit(): void {
    this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.HvacModeGroup).subscribe( value => {
      this.HVACGroups$ = of(value);
    });

  }

  async onSwitchGroupToCoolingClick(group: LocationGroup) {
    const alert = await this.alertController.create({
      header: 'Cooling',
      message:`Switch group ${group.name} to cooling mode?`,
      buttons: [ {
        text: 'Cancel',
        role: 'cancel',
      },
        {
          text: 'Confirm',
          handler: () => {
            this.swithToCooling(group.id)
          }
        }
      ],
    });
    await alert.present();

  }

  async onSwitchGroupToHeatingClick(group: LocationGroup) {
    const alert = await this.alertController.create({
      header: 'Heating',
      message:`Switch group ${group.name} to heating mode?`,
      buttons: [ {
        text: 'Cancel',
        role: 'cancel',
      },
        {
          text: 'Confirm',
          handler: () => {
            this.switchToHeating(group.id)
          }
        }
      ],
    });
    await alert.present();
  }

  async onSwitchAllToCoolingClick() {
    const alert = await this.alertController.create({
      header: 'Cooling',
      message:`Change every HVAC to cooling mode?`,
      buttons: [ {
        text: 'Cancel',
        role: 'cancel',
      },
        {
          text: 'Confirm',
          handler: () => {
            this.swithToCooling(0)
          }
        }
      ],
    });
    await alert.present();

  }

  async onSwitchAllToHeatingClick() {
    const alert = await this.alertController.create({
      header: 'Heating',
      message:`Change every HVAC to heating mode?`,
      buttons: [ {
        text: 'Cancel',
        role: 'cancel',
      },
        {
          text: 'Confirm',
          handler: () => {
            this.switchToHeating(0)
          }
        }
      ],
    });
    await alert.present();
  }


  swithToCooling(id: number) {
    this.loading.push(id);
    this.apiProjectService.changeGroup(id,1,5200).subscribe({
      next: (resp) => {
      this.loading = this.loading.filter( element => element !== id);
    },
    error: (error) => {
      this.loading = this.loading.filter( element => element !== id);
    }});
  }

  switchToHeating(id: number) {
    this.loading.push(id);
    this.apiProjectService.changeGroup(id,0,5200).subscribe({
      next: (resp) => {
      this.loading = this.loading.filter( element => element !== id);
    },
    error: (error) => {
      this.loading = this.loading.filter( element => element !== id);
    }});
  }

  hvacIsLoading(id: number) {
    return this.loading.includes(id);
  }
}

