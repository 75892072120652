import { Pipe, PipeTransform } from '@angular/core';
import { CardType } from 'src/app/core/models/card/card-type.model';

@Pipe({
  name: 'getCardTypeNameByCode'
})
export class GetCardTypeNameByCodePipe implements PipeTransform {

  transform(typeCode, cardTypes: CardType[]): string {
    if (typeCode === null || typeCode === undefined) {
      return 'None'
    }
    const cardType = cardTypes.find((type: CardType)=> {
      return Number(typeCode) === Number(type.code)
    })
    if (cardType) {
      return cardType.name;
    } else return 'Unknown'
  }

}
