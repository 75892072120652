import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';

@Pipe({
  name: 'getObjectsByIds'
})
export class GetObjectsByIdsPipe implements PipeTransform {

  transform(objects: ProjectObject[], objectIds: number[]): ProjectObject[] {
    if (objectIds.length === 0 ) { // filter off
      return objects;
    }
    return objects.filter((obj)=> { // filter on
      return objectIds.includes(obj.projectObjectId);
    });
  }

}
