import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user/user.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { SoftwarePermissionId } from '../models/permissions/software-permission-id.enum';
import { Platform } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';
import { CurrentUserStoreService } from '../services/current-user-store.service';
import { DEMO_MODE } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class UserPermissionGuard  {

    constructor(private currentUserStoreService: CurrentUserStoreService,
                private authService: AuthenticationService,
                private messagingService: MessagingService,
                private router: Router,
                public platform: Platform) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if(DEMO_MODE) {
          return true;
        }
        let user = this.currentUserStoreService.getUser();
        if (user === undefined) {
            if (this.authService.isValid()) {
                await lastValueFrom(this.authService.getUserByToken()).then(u => {user = u; }).catch(err => {});
            } else {
                if (!this.authService.getAccessToken() && !this.authService.getRefreshToken()) {
                    this.messagingService.setMessage('Access denied. Please login')
                    return false;
                }
                if (!this.authService.refreshTokenInProgress) {
                    this.authService.setRefreshTokenInProgress(true);
                    await lastValueFrom(this.authService.refreshAccessToken()).then(r => user = r.user).catch(() => {});
                    this.authService.setRefreshTokenInProgress(false);
                }
                await lastValueFrom(this.authService.refreshChanged.pipe(take(2))).then(val => {
                    user = this.currentUserStoreService.getUser()}).catch(err => {});
            }
            return this.validate(user, state);
        } else {
            return this.validate(user, state);
        }
    }

    validate(user: User, state: RouterStateSnapshot) {
        const splitedUrl = state.url.split('/');
        splitedUrl.shift();
        if (this.userContains(user, splitedUrl)) {
            return true;
        } else {
            this.router.navigateByUrl('/restricted-access');
            return false;
        }
    }

    userContains(user: User, wantedRoute: string[]) {
        if (User.isAdria(user)) {
          return true;
        }
        let requiredPermissionId: number[];
        switch (wantedRoute[0]) {
            case 'rooms': {
              return true;
            }
            case 'common-area' : {
              return true;
            }
            case 'groups' : {
              requiredPermissionId = [
                SoftwarePermissionId.LocationGroupsActivatePresets,
                SoftwarePermissionId.LocationGroupsUpdate];
              break;
            }
            case 'cards': {
              requiredPermissionId = [
                SoftwarePermissionId.CardsGuestUpdate,
                SoftwarePermissionId.CardsStaffUpdate];
              break;
            }
            /* case 'automation': {
              requiredPermissionId = SoftwarePermissionId.AutomationsView;
              break;
            } */
            case 'schedule' : {
              requiredPermissionId = [SoftwarePermissionId.AutomationsView];
              break;
            }
            case 'logs': {
              if (wantedRoute.length === 2 && wantedRoute[1] === 'audit') {
                requiredPermissionId = [SoftwarePermissionId.AuditLogView];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'access') {
                requiredPermissionId = [SoftwarePermissionId.AccessLogView];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'alarms') {
                requiredPermissionId = [SoftwarePermissionId.AlarmsLogView];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'card-events') {
                requiredPermissionId = [SoftwarePermissionId.SettingsCardEventLog];
              }
              break;
            }
            case 'users': {
                requiredPermissionId = [SoftwarePermissionId.UsersView];
                if (+wantedRoute[1] === user.userId && wantedRoute.length === 2) { // logged in user profile
                    return true;
                } else if (+wantedRoute[1] === user.userId && wantedRoute[2] === 'permissions') { // logged in user own permission
                    return false;
                } else if (wantedRoute[2] === 'permissions') {
                  requiredPermissionId = [SoftwarePermissionId.UsersView];
                } else if (+wantedRoute[1] === 0) {
                  requiredPermissionId = [SoftwarePermissionId.UsersUpdate];
                } else if (wantedRoute[2] === 'cards') {
                  requiredPermissionId = [ SoftwarePermissionId.CardsStaffUpdate];
                }
                if (wantedRoute.length === 6 && wantedRoute[4] === 'rules') {
                  requiredPermissionId = [SoftwarePermissionId.UsersUpdate];
                }
                if (wantedRoute.length === 4 && wantedRoute[3] === 'permissions') {
                  requiredPermissionId = [SoftwarePermissionId.UsersUpdate];
                }
                break;
            }
            case 'alarms': {
                requiredPermissionId = [
                  SoftwarePermissionId.AlarmsRoomBurglaryReceive,
                  SoftwarePermissionId.AlarmsSosReceive,
                  SoftwarePermissionId.AlarmsRoomBurglaryReceive,
                  SoftwarePermissionId.AlarmsRoomSafeBurglaryReceive,
                  SoftwarePermissionId.AlarmsTemperatureDifferenceReceive,
                  SoftwarePermissionId.AlarmsWaterFloodReceive,
                  SoftwarePermissionId.AlarmsPowerOutageReceive,
                  SoftwarePermissionId.AlarmsDCOutputsFailureReceive,
                  SoftwarePermissionId.AlarmsControllerTemperatureReceive,
                  SoftwarePermissionId.AlarmsWindowOpenTooLongReceive,
                  SoftwarePermissionId.AlarmsDoorOpenTooLongReceive,
                  SoftwarePermissionId.AlarmsRoomSafeCommErrorReceive,
                  SoftwarePermissionId.AlarmsCommunicationErrorReceive
                ]
                // if (wantedRoute.length === 2 && wantedRoute[1] === 'unconfirmed') {
                //     requiredPermissionId = SoftwarePermissionId.AlarmsReceive;
                // }
                break;
            }
            case 'roles': {
                requiredPermissionId = [SoftwarePermissionId.RolesView];
                if (wantedRoute.length === 2 && wantedRoute[1] === '0') {
                    requiredPermissionId = [SoftwarePermissionId.RolesUpdate];
                }
                else if (wantedRoute.length === 2 && typeof (+wantedRoute[1]) === 'number') {
                    requiredPermissionId = [SoftwarePermissionId.RolesView];
                }
                break;
            }
            case 'settings': {
              requiredPermissionId = [SoftwarePermissionId.Settings];
              if (wantedRoute.length === 2 && wantedRoute[1] === 'views') {
                requiredPermissionId = [SoftwarePermissionId.SettingsManageRoomOverview];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'other-settings') {
                requiredPermissionId = [SoftwarePermissionId.SettingsDefaults];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'encoders') {
              requiredPermissionId = [SoftwarePermissionId.SettingsEncoders];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'presets') {
                requiredPermissionId = [SoftwarePermissionId.SettingsPresets];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'hvac-mode-cross-reference') {
                requiredPermissionId = [SoftwarePermissionId.SettingsPresets];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'alert-subscriptions') {
                requiredPermissionId = [SoftwarePermissionId.SettingsSubscription];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'licensing') {
                requiredPermissionId = [SoftwarePermissionId.SettingsLicensing];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'analytics') {
                requiredPermissionId = [SoftwarePermissionId.AnalyticsView];
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'project-details') {
                return User.isAdria(user);
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'controller-settings') {
                return User.isAdria(user);
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'controllers-overview') {
                return User.isAdria(user);
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'pms-options') {
                return User.isAdria(user);
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'drivers') {
                return User.isAdria(user);
              } else if (wantedRoute.length === 2 && wantedRoute[1] === 'hide-properties') {
                return User.isAdria(user);
              }
                break;
            }
          case 'hvac-mode' : {
            requiredPermissionId = [SoftwarePermissionId.LocationGroupsActivatePresets];
            break;
        }

            case 'how-to' : {
              return true;
            }
            default: {
                return false;
            }
        }
        /* userHasPermission = user?.roles?.some(role => role?.permissions?.some(permission => {
          if (requiredPermissionId === SoftwarePermissionId.AlarmsRoomBurglaryReceive) {
            return this.permissionIsAnyAlarm(permission.permissionId);
          } else {
            return permission.permissionId === requiredPermissionId;
          }
          }
        ));
        if (!userHasPermission) {
          userHasPermission = user?.permissions?.some(permission => {
            if (requiredPermissionId === SoftwarePermissionId.AlarmsRoomBurglaryReceive) {
              return this.permissionIsAnyAlarm(permission.permissionId);
            } else {
              return permission.permissionId === requiredPermissionId;
            }
           });
        } */
        return User.hasAnyOfRequiredPermissions(user, requiredPermissionId);
    }


   /*  permissionIsAnyAlarm(permissionId: number): boolean {
      return permissionId === SoftwarePermissionId.AlarmsSosReceive ||
      permissionId === SoftwarePermissionId.AlarmsRoomBurglaryReceive ||
      permissionId === SoftwarePermissionId.AlarmsRoomSafeBurglaryReceive ||
      permissionId === SoftwarePermissionId.AlarmsTemperatureDifferenceReceive ||
      permissionId === SoftwarePermissionId.AlarmsWaterFloodReceive ||
      permissionId === SoftwarePermissionId.AlarmsPowerOutageReceive ||
      permissionId === SoftwarePermissionId.AlarmsDCOutputsFailureReceive ||
      permissionId === SoftwarePermissionId.AlarmsControllerTemperatureReceive ||
      permissionId === SoftwarePermissionId.AlarmsWindowOpenTooLongReceive ||
      permissionId === SoftwarePermissionId.AlarmsDoorOpenTooLongReceive ||
      permissionId === SoftwarePermissionId.AlarmsRoomSafeCommErrorReceive ||
      permissionId === SoftwarePermissionId.AlarmsCommunicationErrorReceive;
    } */
}
