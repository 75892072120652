import { Component, OnInit } from '@angular/core';
import { User } from '../../../core/models/user/user.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { PROJECT_ID } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  signedInUser: User;
  swPermissions = SoftwarePermissionId;
  id: number = 0;
  url;

  constructor(
    private currentUserStoreService: CurrentUserStoreService,
    ) { }

  ngOnInit() {
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
  }

  onReportIssue(){
    if(PROJECT_ID){
      // this.id = PROJECT_ID;
      this.url = `https://help.ae.hr/bug_report_page.php?project_id=${PROJECT_ID}`;
    }else{
      this.url = 'https://help.ae.hr/login_select_proj_page.php?ref=bug_report_page.php';
    }
    // const url = `https://help.ae.hr/bug_report_page.php?project_id=${this.id}`;
    window.open(this.url);
  }

}
