import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  elemsThatAreLoading = new BehaviorSubject<string[]>([]);

  constructor() { }

  addToLoading(id: string) {
    setTimeout( () => {
      this.removeIfNoResponse(id);
    }, 10000);
    const elementsThatAreLoading = this.elemsThatAreLoading.getValue().slice();
    if (!elementsThatAreLoading.includes(id)) {
      elementsThatAreLoading.push(id);
      this.elemsThatAreLoading.next(elementsThatAreLoading);
    }
  }

  removeIfNoResponse(id: string) {
    let elementsThatAreLoading = this.elemsThatAreLoading.getValue().slice();
    if (elementsThatAreLoading.includes(id)) {
      elementsThatAreLoading = elementsThatAreLoading.filter( element => element !== id);
      this.elemsThatAreLoading.next(elementsThatAreLoading);
    }
  }

  removeFromLoading(id: string) {
    setTimeout( () => {
      let elementsThatAreLoading = this.elemsThatAreLoading.getValue().slice();
      elementsThatAreLoading = elementsThatAreLoading.filter( element => element !== id);
      this.elemsThatAreLoading.next(elementsThatAreLoading);
    }, 500);
  }

  getLoadingElements() {
    return this.elemsThatAreLoading.asObservable();
  }
}
