import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideHumidityAndCo2'
})
export class HideHumidityAndCo2Pipe implements PipeTransform {

  transform(values: {name: string, series: any []}[]): {name: string, series: any []}[] {
    return values.filter((val)=> {
            return val.name !=='CO2Measurment' && val.name !=='Humidity'
            // value names taken from 'assets/cntproptypes.json'
    });
  }

}
