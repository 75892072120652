import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { OutputStatusNameEn } from 'src/app/core/models/common-area/output-mode-name-en.map';
import { OutputStatusNameHr } from 'src/app/core/models/common-area/output-mode-name-hr.map';
import { OutpuModeStatus } from 'src/app/core/models/common-area/output-mode.enum';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { OutputModeCopyPasteComponent } from 'src/app/shared/components/output-mode-copy-paste/output-mode-copy-paste.component';
import { PropertyCopyService } from 'src/app/shared/services/property-copy.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { RoomTagPopoverComponent } from 'src/app/shared/components/room-tag-popover/room-tag-popover.component';
import { Location, Tag } from 'src/app/core/models/project/location.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';


@Component({
  selector: 'app-io-common-area-modal-settings',
  templateUrl: './io-common-area-modal-settings.component.html',
  styleUrls: ['./io-common-area-modal-settings.component.scss'],
})
export class  IOCommonAreaModalSettingsComponent implements OnInit, OnDestroy {
  @Input() location: Location
  controllers: Controller[];
  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  intervalForDoorsActive: boolean;

  outputModesEnum = OutpuModeStatus;
  outputModesEnumName = OutputStatusNameEn;
  stopControllerPolling: boolean = false;
  controllerPollingSubscription: Subscription;
  loggedInUser: User;
  allTags: Tag[] = []


  isLight = Property.isLight;
  isOutputMode = Property.isOutputMode;
  isOutputStartTimeT1 = Property.isOutputStartTimeT1;
  isOutputEndTimeT1 = Property.isOutputEndTimeT1;
  isOutputStartTimeT2 = Property.isOutputStartTimeT2;
  isOutputEndTimeT2 = Property.isOutputEndTimeT2;
  isLuxomat = Property.isLuxomat;
  isLuxomatBrodcast = Property.isLuxomatBrodcast;
  SoftwarePermissionId = SoftwarePermissionId;

  constructor(private apiProjectService: ApiProjectService,
              private projectService: ProjectService,
              private propertyCopyService: PropertyCopyService,
              private modalController: ModalController,
              private currentUserStoreService: CurrentUserStoreService,
              private popoverController: PopoverController,
              @Inject(LOCALE_ID) private localeId: string) {}

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values;
    this.selectController(this.controllers[0]);
    if (this.localeId === 'hr') {
      this.outputModesEnumName = OutputStatusNameHr;
    }
    this.isControllerPolled();
    this.loggedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
    this.apiProjectService.getAllTags().subscribe( value => {
      this.allTags = value;
    })
  }

  isControllerPolled() {
    this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    this.apiProjectService.getNotPolledControllers().subscribe( value => {
      value.forEach( cont => {
        if (cont.object === this.controllers[0].object && cont.subObject === this.controllers[0].subObject
            && cont.zone === this.controllers[0].zone && cont.subZone === this.controllers[0].subZone) {
          this.stopControllerPolling = true;
        }
      })
    })
    this.controllerPollingSubscription = this.projectService.notPolledControllersChanged.subscribe( () => {
      this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    })
  }


  selectController(controller: Controller) {
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
  }

  modeSelected(event: any, outputMode: Property) {
    this.apiProjectService
    .changeProperty(this.selectedControllers[0].designation, outputMode.id, event.target.value)
    .subscribe();
  }

  lightOnOff(light: Property) {
    if (+light.value !== 1) {
      this.apiProjectService
      .changeProperty(this.selectedControllers[0].designation, light.id, 1)
      .subscribe();
    } else {
      this.apiProjectService
      .changeProperty(this.selectedControllers[0].designation, light.id, 0)
      .subscribe();
    }
  }

  getStatusName(status: number) {
    return this.outputModesEnumName.get(status);
  }

  outputModeWithInterval(property: Property) {
    if (+property.value === this.outputModesEnum.TimeInterval ||
       +property.value === this.outputModesEnum.TimeIntervalAndPIR || +property.value === this.outputModesEnum.LuxomatAndTimeInterval) {
      return true;
    }
  }

  getDenyFromProperty(outputMode: Property, tStart: Function) {
    const outputModeNum = outputMode.mqttTopic.split('/')[3];
    const prop = this.controllers[0].controllerProperties.$values.find( p => {
      if (tStart(p) && p.mqttTopic.split('/')[4] === outputModeNum) {
        return true;
      }
    })
    if (prop) {
      return prop;
    }
  }

  getDenyToProperty(outputMode: Property, tEnd: Function) {
    const outputModeNum = outputMode.mqttTopic.split('/')[3];
    const prop = this.controllers[0].controllerProperties.$values.find( p => {
      if (tEnd(p) && p.mqttTopic.split('/')[4] === outputModeNum) {
        return true;
      }
    })
    if (prop) {
      return prop;
    }
  }

  async editControllerTags(ev) {
    const popover = await this.popoverController.create({
      component: RoomTagPopoverComponent,
      cssClass: 'tags-popover',
      event: ev,
      showBackdrop: false,
      componentProps: {
        location: this.location,
        existingTags: this.allTags
      }
    });
    await popover.present();

    popover.onDidDismiss().then( () => {
    })
  }



  procesTimeObject(data: any) {
    const timeFrom = data.time[0].getHours() * 60 + data.time[0].getMinutes();
    this.setFromTime(Math.floor(timeFrom / 15), data.denyFrom);

    const timeTo = data.time[1].getHours() * 60 + data.time[1].getMinutes();
    this.setToTime(Math.floor(timeTo / 15), data.denyTo)

  }

  setFromTime(value: number, property: Property) {
    const designation = this.selectedControllers[0].designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  setToTime(value: number, property: Property) {
    const designation = this.selectedControllers[0].designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }


  async copyProperty(outputMode: Property) {
    this.propertyCopyService.saveProperty(outputMode);

    const modal = await this.modalController.create({
      component: OutputModeCopyPasteComponent,
      cssClass: 'cards-popover',
      backdropDismiss: true,
      showBackdrop: true,
    });


    return await modal.present();
  }

  changeControllerPooling(event: any) {
    const contToSend = structuredClone(this.controllers[0])
    if (event.target.checked) {
      this.apiProjectService.stopPolling(contToSend).subscribe()
    } else {
      this.apiProjectService.resumePolling(contToSend).subscribe()
    }
  }

  syncHvac() {
    this.apiProjectService.syncHvacSettings(this.selectedControllers[0].id).subscribe()
  }

  ngOnDestroy(): void {
    if (this.controllerPollingSubscription) {
      this.controllerPollingSubscription.unsubscribe();
    }
  }


}
