<div class="margin10">
  <div class="margin5">
    <strong>Id: </strong>
    {{ controller.id }}
  </div>
  <div class="margin5">
    <strong>{{'Name' | translate }}: </strong> <input [(ngModel)]="controller.name" type="text" />
  </div>
<!--   <input
    class="option-input-sm"
    type="checkbox"
    (change)="clickIsGuestRoom($event.currentTarget.checked)"
    [checked]="controller.isGuestRoom"
  />
  <b>Is guest room</b>
  <br />
  <input
    class="option-input-sm"
    type="checkbox"
    (change)="clickIsAccessControl($event.currentTarget.checked)"
    [checked]="controller.isAccessControl"
  />
  <b>Is access control</b> -->
  <br />
  <input
    class="option-input-sm"
    type="checkbox"
    (change)="
      clickIsRestoreDefaultPropertiesOnCheckout($event.currentTarget.checked)
    "
    [checked]="controller.restoreDefaultOnCheckout"
  />
  <b>{{'Restore default properties on checkout' | translate}}</b>
  <hr class="margin-top10" />

  <div class="margin5">
    <strong>{{'Location id' | translate}}: </strong>
    {{ controller.locationId }}
  </div>
  <div class="margin5">
    <strong>{{'Driver Prefix' | translate}}: </strong>
    {{ controller.driverPrefix }}
  </div>
  <div class="margin5">
    <strong>{{'Object' | translate}}: </strong>
    {{ controller.object }}
  </div>
  <div class="margin5">
    <strong>{{'Subobject' | translate}}: </strong>
    {{ controller.subObject }}
  </div>
  <div class="margin5">
    <strong>{{'Zone' | translate}}: </strong>
    {{ controller.zone }}
  </div>
  <div class="margin5">
    <strong>{{'Subzone' | translate}}: </strong>
    {{ controller.subZone }}
  </div>

  <div class="margin5">
    <strong>{{'Ip address' | translate}}: </strong>
    {{ controller.ipAddress }}
  </div>
  <div class="margin5">
    <strong>{{'Ip port' | translate}}: </strong>
    {{ controller.ipPort }}
  </div>

  <input
    class="option-input-sm"
    type="checkbox"
    disabled
    [checked]="controller.isOnline"
  />
  <b>{{'Is online' | translate}}</b>

  <br />
  <input
    class="option-input-sm"
    type="checkbox"
    disabled
    [checked]="controller.localCommandCooling"
  />
  <b>{{'Local command cooling' | translate}}</b>

  <br />
  <input
    class="option-input-sm"
    type="checkbox"
    disabled
    [checked]="controller.localCommandPanelBlocked"
  />
  <b>{{'Local command panel blocked' | translate}}</b>

  <hr class="margin-top10" />
  <div class="modal-button-box">
    <button (click)="decisionClick(false)">{{'Cancel' | translate}}</button>
    <button
      (click)="decisionClick(true)"
      [ngClass]="[submitted ? 'btn-disabled no-click' : '']"
    >
      {{'Save' | translate}}
    </button>
  </div>
</div>
