import { OutpuModeStatus } from './output-mode.enum';

export const OutputStatusNameEn = new Map<number, string>([
    [OutpuModeStatus.Off, 'Off'],
    [OutpuModeStatus.On, 'On'],
    [OutpuModeStatus.Luxomat, 'Luxomat'],
    [OutpuModeStatus.TimeInterval, 'Time interval'],
    [OutpuModeStatus.LuxomatAndTimeInterval, 'Luxomat and time interval'],
    [OutpuModeStatus.OutputControl, 'Remote control'],
    [OutpuModeStatus.PIR, 'Presence in room'],
    [OutpuModeStatus.KeyboardIN1, 'Keyboard'],
    [OutpuModeStatus.SwitchIN1, 'Switch'],
    [OutpuModeStatus.LuxomatAndPIR, 'Luxomat and PIR'],
    [OutpuModeStatus.TimeIntervalAndPIR, 'Time interval and PIR']
    ]);
