export enum LogStatus {
    UnknownStatus = -1,
    RejectDoorNotFound = 0,
    RejectCardNotFound = 1,
    RejectCardNotYetValid = 4,
    RejectCardExpired = 5,
    RejectCardBlacklisted = 6,
    RejectNoPermission = 8,
    RejectByPermissionRule = 9,
    RejectByCounter = 10,
    RejectByAntiPassback = 11,
    StudentCardRegistered = 12,
    ScheduledDoorUnlock = 13,
    ScheduledDoorLock = 14,
    SynchronizedFromController = 15,
    AccessGranted = 16,
    ExitGrantedByConfiguration = 17,
    EntryGrantedByConfiguration = 18,
    Exit = 19
}
