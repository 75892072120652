import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { CardOnHolderService } from 'src/app/core/services/card-on-holder.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { VIRTUAL_CARD } from 'src/environments/environment';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() content: any;
  @Input() loading = false;
  @Input() guestCard: boolean;
  @Input() cardType: string;
  @Input() burgRooms: Controller[] = [];
  @Output() decision: EventEmitter<boolean> = new EventEmitter();
  cardOnHolder = false;
  cardHolderSubscription: Subscription;

  VIRTUAL_CARD = VIRTUAL_CARD;
  constructor(private cardOnHolderService: CardOnHolderService,
              private cardsService: CardsService) { }

  ngOnInit(): void {
    this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    this.cardHolderSubscription = this.cardOnHolderService.cardOnHolderChanged.subscribe(()=> {
      this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    })
  }

  decisionClick(choice: boolean) {
    this.decision.emit(choice);
  }

  createVirtualCard() {
    switch (this.cardType.toLowerCase()){
      case 'rfid':
        this.cardsService.setLastCardOnHolderUid(this.generateRandomNumber(8).toString());
        break
      case 'mifare':
        this.cardsService.setLastCardOnHolderUid(this.generateRandomNumber(8).toString());
        break
      case 'salto':
        this.cardsService.setLastCardOnHolderUid(this.generateRandomNumber(14).toString());
        break;
    }
    this.cardOnHolderService.setCardOnHolder(true);
    this.cardOnHolderService.setCardOnHolder(false);
  }

  generateRandomNumber(size: number): number {
    const min = Math.pow(10, size - 1);
    const max = Math.pow(10, size) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnDestroy() {
    if (this.cardHolderSubscription) {
      this.cardHolderSubscription.unsubscribe();
    }
  }
}
