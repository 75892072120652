import { Pipe, PipeTransform } from "@angular/core";
import { Controller } from "src/app/core/models/controller.model";

@Pipe({
    name: 'isGuestRoom'
})
export class IsGuestRoomPipe  implements PipeTransform {
    transform(controller: Controller): boolean {
        return Controller.isGuestRoom(controller);
    }
}