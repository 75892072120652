import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Setting } from 'src/app/core/models/project/setting.model';

@Pipe({
  name: 'canIntervalBeSetPipe'
})
export class CanIntervalBeSetPipe implements PipeTransform {

  transform(controllers: Controller[], settingFunct: Function): boolean {
    let check = false;
    controllers.find( (controller: Controller) => {
         return controller.controllerSettings.$values.find( setting => {
          let target: Setting;
          target = settingFunct(setting)
          if (target && target.value === '1') {
            check = true;
          } 
        });
      }
    );

    return check;
  }

}
