import { Injectable } from '@angular/core';
import { IMqttMessage } from 'ngx-mqtt';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { BehaviorSubject } from 'rxjs';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { RESET_MQTT_QUEUE_AT_COUNT, USE_DALI } from 'src/environments/environment';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { StoredFilter } from '../models/project/stored-filter.model';
import { LocationsFilter } from '../models/locations-filter.model';
import { CurrentUserStoreService } from '../services/current-user-store.service';

@Injectable({
  providedIn: 'root'
})
export class MqttMessageQueueService {
  private ignoreMqttMessages = false;
  private mqttMessageQueue: IMqttMessage[] = [];
  private queueLength$ = new BehaviorSubject<number>(0)


  constructor(
    private projectService: ProjectService,
    private apiProjectService: ApiProjectService,
    private apiDaliProjectService: ApiDaliProjectService,
    private currentUserStoreService: CurrentUserStoreService
  ) {
  }

  async processQueue() {
    while (!this.ignoreMqttMessages) {
      if (this.mqttMessageQueue.length>0) {
        const msg: IMqttMessage = this.mqttMessageQueue.shift();
        this.setQueueLength(this.mqttMessageQueue.length)
        this.handleMqttMessage(msg);
      }
      // sleep
      await new Promise(r => setTimeout(r, 2));
    }
  }

  public addMqttMessageToQueue(mqttMessage: IMqttMessage) {
    if (this.ignoreMqttMessages) {
      return;
    }
    this.mqttMessageQueue.push(mqttMessage);
    this.setQueueLength(this.mqttMessageQueue.length)

    if (typeof RESET_MQTT_QUEUE_AT_COUNT === 'number'
    && RESET_MQTT_QUEUE_AT_COUNT > 0 && (this.queueLength$.getValue() > RESET_MQTT_QUEUE_AT_COUNT)) {
      this.refreshDataAndResetMqttQueue()
    }
  }

  refreshDataAndResetMqttQueue() {
    if (USE_DALI) {
      this.apiDaliProjectService.getProject().subscribe();
    }
    this.apiProjectService.getAllLocationsFat().subscribe();
    const filtersForUser = StoredFilter.getGuestRoomFilterFromStorage(this.currentUserStoreService.getUser().userId);
    const filter = filtersForUser ? filtersForUser.filters : new LocationsFilter([1])
    this.apiProjectService.getFilteredLocationsFat(filter).subscribe();
    this.emptyMqttQueue();
  }


  private handleMqttMessage(mqttMessage: IMqttMessage) {
    this.projectService.updateFilteredProjectByMqtt(mqttMessage);
    this.projectService.updateFullProjectByMqtt(mqttMessage)
  }

  setIgnoreMqttMessages(value: boolean) {
    this.ignoreMqttMessages = value;
  }


  setQueueLength(value: number) {
    this.queueLength$.next(value)
  }

  getQueueLength() {
    return this.queueLength$.asObservable();
  }

  emptyMqttQueue() {
    this.mqttMessageQueue = [];
    this.setQueueLength(0);
  }

}
