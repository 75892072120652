import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getBurglaryIncludeName'
})
export class GetBurglaryIncludeNamePipe implements PipeTransform {

  transform(property: Property, props: Property[]): string {
    if (Property.isBurglaryDetectedDoor(property)) {
      return 'Ignore burglary by door'
    } else if (Property.isBurglaryDetectedByPIR(property)) {
      return 'Ignore burglary by pir'
    } else if ( Property.isBurglaryDetectedWindow(property)) {
      return 'Ignore burglary by window'
    }
    return property.name;
  }

}
