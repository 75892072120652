import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { API_BASE_URL } from 'src/environments/environment';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { AuditLog } from 'src/app/core/models/logs/audit-log.model';


@Injectable({
    providedIn: 'root'
})
export class ApiAuditService {

  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient) {}

    getAuditLogsRequest(body: SsFilterSort, pageData) {
        const requestParams = new HttpParams()
        .set('PageSize', pageData.pageSize)
        .set('PageNumber', pageData.pageNumber)
        .set('IncludeArchive', pageData.includeArchive);

        return this.http.post<AuditLog[]>(
          API_BASE_URL + '/AuditLogs',
           body ,
            {
              params: requestParams,
              headers: this.headers,
              observe: 'response'
            }).pipe(
            map(logs => {
                return logs;
            }),
        );
    }
}
