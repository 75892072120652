import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getSubtitleForPresetProps'
})
export class GetSubtitleForPresetPropsPipe implements PipeTransform {

  transform(prevProp: Property, currentProp: Property): string {
    if (!prevProp && currentProp.mqttTopic.startsWith('hvac/')) {
      return 'Hvac'
    } else if (!prevProp.mqttTopic.startsWith('equipment/ignore') && currentProp.mqttTopic.startsWith('equipment/ignore')) {
      return 'Equipment ignore'
    } /* else if (!prevProp.mqttTopic.startsWith('equipment/relayoutput') && currentProp.mqttTopic.startsWith('equipment/relayoutput')) {
      return 'Relay outputs'
    }  */else if ((/* prevProp.mqttTopic.startsWith('equipment/relayoutput') || */ prevProp.mqttTopic.startsWith('equipment/ignore'))
        && (currentProp.mqttTopic.startsWith('equipment/') /*&&  !currentProp.mqttTopic.startsWith('equipment/relayoutput') */
        && !currentProp.mqttTopic.startsWith('equipment/ignore'
        ))) {
      return 'Equipment'
    }
    else if (!prevProp.mqttTopic.startsWith('access/lock') && currentProp.mqttTopic.startsWith('access/lock')) {
      return 'Access control'
    } else if ((!prevProp.mqttTopic.startsWith('security/burglary/') && !prevProp.mqttTopic.startsWith('access/burglary/')) &&
    (currentProp.mqttTopic.startsWith('security/burglary/') || currentProp.mqttTopic.startsWith('access/burglary/'))) {
      return 'Burglary ignore'
    }
    return undefined
  }

}

