import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Card } from 'src/app/core/models/card/card.model';
import { Controller } from 'src/app/core/models/controller.model';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { User } from 'src/app/core/models/user/user.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiRolesService } from 'src/app/modules/roles/services/http/api-roles.service';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { UsersService } from 'src/app/modules/users/services/users.service';

import { BACKUP_CARD_YEARS_ACTIVE } from 'src/environments/environment';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { enterAnimation } from 'src/app/shared/animations/animations';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { EditCardModalComponent } from 'src/app/modules/rooms/components/edit-card-modal/edit-card-modal.component';
import { LocationGroup, ApiLocationGroupsService } from 'src/app/shared/services/api-location-groups.service';
import { LocationGroupsService } from 'src/app/shared/services/location-groups.service';
import { CardOnHolderService } from 'src/app/core/services/card-on-holder.service';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { Location } from 'src/app/core/models/project/location.model';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';

@Component({
  selector: 'app-access-control-modal-cards',
  templateUrl: './access-control-modal-cards.component.html',
  animations: [enterAnimation],
  styleUrls: ['./access-control-modal-cards.component.scss'],
})
export class AccessControlModalCardsComponent implements OnInit, OnDestroy/* , AfterViewInit */  {
  // @ViewChild('selectContainerRooms') selectContainerRooms: SelectContainerComponent;
  @Input() location: Location;
  controllers: Controller[];

  mainController: Controller;
  popoverOpened = false;
  cards: Card[] = [];
  guestCards: Card[] = [];
  staffCards: Card[] = [];
  roomSwap = false;
  // openModal = false;

  permissions: Permission[];
  permissionsSubscription: Subscription;

  accessControlIds: string[] = [];
  floorList$: Observable<LocationGroup[]> = this.locationGroupsService.getFloorList();

  // newRoom: Controller[] = [];
  newArrayOfLocations: Location[] = [];

  movingGuest: User;

  // selectedObjectRooms;
  // selectedSubObjectRooms;

  guestAddedSubscription: Subscription;

  cardOnHolder: boolean;
  cardOnHolderSubscription: Subscription;
  openCardOnReaderModal = false;
  cardExist = false;
  userBeingCreated = false;
  // showStaffCards = false
  activePopup: string;
  userToShowInPopup: User;
  tablesReady = false;

  signedInUser: User;
  swPermissions = SoftwarePermissionId;
  lastGuestCardOnHolder$ = this.cardsService.getLastGuestCardOnHolder()

  cardSyncActiveSubscription: Subscription;
  cardSyncActive = false;


  constructor(private modalController: ModalController,
              private apiUsersService: ApiUsersService,
              private usersService: UsersService,
              private apiLocationGroupsService: ApiLocationGroupsService,
              private projectService: ProjectService,
              private locationGroupsService: LocationGroupsService,
              private rolesService: RolesService,
              private apiRolesService: ApiRolesService,
              private cardOnHolderService: CardOnHolderService,
              private cardsService: CardsService,
              private currentUserStoreService: CurrentUserStoreService,
              private alertController: AlertController,
              private pipe: CustomTranslatePipe,
              ) {}

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values;
    this.getMainController();
    this.getCardsByLocation();
    this.getPermissions();
    this.getCardOnHolderStatus();
    this.getIsCardSyncActive();
    this.guestAddedSubscription = this.usersService.newGuestAdded.subscribe( () => {
      this.getCardsByLocation();
    })
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
  }

  getCardOnHolderStatus() {
    this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    this.cardOnHolderSubscription = this.cardOnHolderService.cardOnHolderChanged.subscribe(cardOnHolder =>
      this.cardOnHolderChanged(cardOnHolder));
  }

  cardOnHolderChanged(cardOnHolder: boolean) {
    this.cardOnHolder = cardOnHolder;
    if (this.openCardOnReaderModal === true && this.cardOnHolder === true) {
    }
  }

  getIsCardSyncActive(){
    this.cardSyncActiveSubscription = this.projectService.cardSyncActive$.subscribe(value => {
      this.cardSyncActive = value;
    });
  }

  checkIfcardExists() {
    return this.cardOnHolderService.cardByUidExists(this.cardsService.lastCardsOnHolderUid);
  }


  getPermissions() {
    this.permissions = this.rolesService.getPermissions();
    this.apiRolesService.getPermissions().subscribe();
    this.permissionsSubscription = this.rolesService.permissionsChanged.subscribe(() => {
      this.permissions = this.rolesService.getPermissions();
    });
  }

  getCardsByLocation() {
    this.apiUsersService.getCardsByLocation(this.location.locationId).subscribe( (value: Card[]) => {
      this.cards = value;
      this.guestCards = this.cards.filter( card => card.type === 1);
      this.staffCards = this.cards.filter( card => card.type !==1);
      // this.guestCards = this.guestCards.sort((a,b) => (+this.getCardPosition(a)) - (+this.getCardPosition(b)))
      this.tablesReady = true;
    })
  }


  getMainController() {
    this.mainController = Controller.getMainController(this.controllers)
  }


  async deleteGuest(user: User) {
    // this.apiUsersService.deleteUser(user.userId).subscribe( () => {
    //   this.getCardsByLocation();
    // });

    const alert = await this.alertController.create({
      header: this.pipe.transform('Confirm delete'),
      message: this.pipe.transform('Delete card?'),
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Delete'),
          handler: () => {
              this.apiUsersService.deleteUser(user.userId).subscribe({
                next:() => {
                  this.getCardsByLocation();
                },
                error: ()=> {}
            });
          }
        }
      ],
    });
    await alert.present();
  }

  syncGuest(uid: string) {
    this.apiUsersService.syncCard(uid).subscribe();
  }

  groupControllersByLocation(mainController: Controller, arrayOfLocations: Location[]) {
    return this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.AccessGroup).pipe(
      map( value => {
        value.forEach( group => {
          if (group.locations.some( loc => loc.controllers.$values.some( cont => cont.designation == mainController.designation))) {
            group.locations.forEach( location => {
              if (Location.isAccessControl(location)) {
                arrayOfLocations.push(location);
              }
            })
          }
        })
      })
    )
  }


  onCardOnreaderModalDecision(decision) {
    this.openCardOnReaderModal = false;
 }

 onModalDecisionCardExist(decision) {
  this.cardExist = false;
}


  showAccess(event: Event, card: Card) {
    if (event.type === 'mouseenter') {
      this.activePopup = card.uid;
      if (!this.userToShowInPopup) {
        this.apiUsersService.getUser(card.userId).subscribe( user => {
          this.userToShowInPopup = user;
          this.userToShowInPopup.permissions = user.permissions.filter( (perm: Permission) => {
            const locId = perm.locationId.toString()
              const cont = this.projectService.getControllerByLocationId(locId)
              if (cont) {
                if (Controller.isAccessControl(cont) && cont.name !== this.location.name) {
                  return true;
                }
              }
          })
        })
      }
    } else if (event.type === 'mouseleave') {
      this.activePopup = undefined;
      this.userToShowInPopup = undefined;
    }
  }

  showAccessPopup(uid: string) {
    if (this.activePopup === uid) {
      return true;
    }
  }

  async editCard(userId: number) {
    const modal = await this.modalController.create({
      component: EditCardModalComponent,
      cssClass: 'cards-popover',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps:  {
        location: this.location,
        userId: userId
        }
    });
    return await modal.present();
  }

  ngOnDestroy() {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
    if (this.guestAddedSubscription) {
      this.guestAddedSubscription.unsubscribe();
    }
    if (this.cardOnHolderSubscription) {
      this.cardOnHolderSubscription.unsubscribe();
    }
    if (this.cardSyncActiveSubscription) {
      this.cardSyncActiveSubscription.unsubscribe();
    }
    this.cardsService.setLastGuestCardOnHolder(undefined);
  }

}
