

<div class="grid">
    <video *ngIf="doorsClosing || doorOpen()" width="800" height="600" class="grid-video" autoplay [ngClass]="!doorOpen() ? 'push-to-back' : ''">
        <source src="../../../../../assets/videos/door-open.mp4" type="video/mp4">
        Your browser does not support the video tag.
    </video>
    
    <video *ngIf="airConditioning()" class="grid-air-conditioning" autoplay loop>
        <source src="../../../../../assets/videos/air-conditioning.mp4" type="video/mp4"> 
    </video>

    <video *ngIf="doorsOpening || !doorOpen()" width="800" height="600" class="grid-video" autoplay [ngClass]="doorOpen() ? 'push-to-back' : ''">
    <source src="../../../../../assets/videos/door-closed.mp4" type="video/mp4">
    Your browser does not support the video tag.
    </video>

</div>