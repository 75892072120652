<ion-item lines="none" (click)="toggleValue($event)" class="item-background-color">
  <ion-checkbox
    justify="start"
    labelPlacement="end"
    class="margin-right5"
    [disabled]="reqInProgress"
    [checked]="
    targetValue || (controllers | findProperty: findPropFunction: null | propIsActive)
    "
  ><ng-content></ng-content></ion-checkbox>
</ion-item>
