import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'locationNameFilter'
})
export class LocationNameFilterPipe implements PipeTransform {

  transform(controllers: Controller[], searchText: string): Controller[] {
    if (!controllers) {
        return [];
    }
    if (!searchText) {
        return controllers;
    }
    return controllers.filter(cont => {
        return cont.name.toLowerCase().includes(searchText.toLowerCase());
    });
}

}
