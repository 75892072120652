<div class="padding15" style="align-items: start; width: 1000px;">
  <h2>{{ 'All properties' | translate}}</h2>
    <div class="margin-top15" style="border-bottom: solid 1px; border-color: lightgray;" *ngFor="let controller of controllers">
      <div class="flex-column margin-bottom10" style="max-height: 200px; align-content: flex-start;">
        <span style="font-size: 20px; font-weight: bold; margin-bottom: 12px;">{{controller.name}}</span>
          <div class="margin-right30" *ngFor="let prop of controller.controllerProperties.$values" >
            <div>
              {{prop.name}} -> {{ prop.value }}
            </div>
          </div>
      </div>
    </div>
</div>
