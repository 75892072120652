import { Injectable } from '@angular/core';
import { Automation } from 'src/app/core/models/automation/automation.model';
import { Subject } from 'rxjs';
import { Action } from 'src/app/core/models/automation/action';


@Injectable({
    providedIn: 'root'
})
export class AutomationService {

    private automations: Array<Automation> = [];
    private actions: Array<Action>;

    actionsChanged = new Subject<Action[]>();
    automationsChanged = new Subject<Automation[]>();

    constructor() {}

    setAutomations(automations: Automation[]) {
        this.automations = automations;
        this.automationsChanged.next(this.automations);
    }

    getAutomations() {
        return this.automations.slice();
    }

    addAutomation(automation: Automation) {
        let newAutomation: Automation = automation;
        newAutomation.automationThen.automationCustomActions = automation.automationThen.automationCustomActions.$values;
        this.automations.push(newAutomation);
        this.automationsChanged.next(this.automations);
    }

    updateAutomation(automation: any) {
        const index = this.automations.findIndex( element => element.id === automation.id);
        if (automation.automationThen.automationCustomActions.$values) {
            let newAutomation: Automation = automation;
            newAutomation.automationThen.automationCustomActions = automation.automationThen.automationCustomActions.$values;
            this.automations.splice(index, 1, newAutomation);
        } else {
            this.automations.splice(index, 1, automation);
        }
        this.automationsChanged.next(this.automations);
    }

    deleteAutomation(id: string) {
        this.automations = this.automations.filter( value => value.id !== id);
        this.automationsChanged.next(this.automations);
    }

    setActions(actions: Action[]) {
        this.actions = actions;
        this.actionsChanged.next(this.actions);
    }

    getActions() {
        return this.actions;
    }

}
