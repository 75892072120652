<div class="flex-column-center-full padding30 content-form-group" (keyup.enter)="onConfirm()">
    <h3>{{'How many days is the guest staying' | translate}}?</h3>
    <form *ngIf="stayDaysForm" [formGroup]="stayDaysForm">
        <input autofocus #daysInput type="text" formControlName="stayDays"  [ngClass]="{ 'is-invalid': submitted && stayDaysForm.controls.stayDays.errors }">
        {{daysInput.focus()}} <!-- focuses on daysinput -->
        <div *ngIf="submitted && stayDaysForm.controls.stayDays.errors" class="invalid-input">
            <div *ngIf="stayDaysForm.controls.stayDays.errors.required">{{'Number of days is required' | translate}}</div>
            <div *ngIf="stayDaysForm.controls.stayDays.errors.pattern">{{'Value must me a number' | translate}}</div>
        </div>
    </form>
    <div class="flex-row margin-top5">
        <ion-button
        size="default"
        class="square-secondary margin-right5"
        color="ocean-blue"
        (click)="onCancel()"
        >{{ "Cancel" | translate }}</ion-button>
      <ion-button
        size="default"
        class="square-secondary margin-right5"
        color="ocean-blue"
        (click)="onConfirm()"
        >{{ "Confirm" | translate }}</ion-button>
    </div>
</div>


