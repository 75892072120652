import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';

@Pipe({
  name: 'getObjects'
})
export class GetObjectsPipe implements PipeTransform {
  transform(availableObjects: ProjectObject[], selectedObject: ProjectObject): ProjectObject[] {
    if (!availableObjects) {
      return []
    }
    if (!selectedObject) {
      return availableObjects
    }
    return [selectedObject]
    //
    /* if (selectedObject < 0) {
      return Project.getObjects(project)
  } else {
    return [selectedObject]
  } */
}
}
