import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { OwlDateTimeComponent } from "@danielmoncada/angular-datetime-picker";
import { Controller } from "src/app/core/models/controller.model";
import { Property } from "src/app/core/models/project/property.model";

@Component({
    selector: 'app-select-time',
    templateUrl: './select-time.component.html',
    styleUrls: ['./select-time.component.scss'],
  })
export class SelectTimeComponent implements OnInit {

    @ViewChild('dtFrom') dtFrom: OwlDateTimeComponent<any>;
    @ViewChild('dtTo') dtTo: OwlDateTimeComponent<any>;
    @Input() controllers: Controller[];
    @Output() setTime$: EventEmitter<any[]> = new EventEmitter <any[]>();
    selectedDateTime: Date[] = [];
    denyFrom: Property;
    denyTo: Property;
    loading: boolean = false;

    isACDoorGuestDenyFrom = Property.ACDoorGuestDenyFrom;
    isACDoorGuestDenyTo = Property.ACDoorGuestDenyTo;


    constructor() { }

    ngOnInit(): void {
        const parentController: Controller = this.controllers.find(
          (controller: Controller) => {
            this.denyFrom = controller.controllerProperties.$values.find(this.isACDoorGuestDenyFrom);
            return this.denyFrom;
          }
        );

        this.denyTo = parentController.controllerProperties.$values.find(this.isACDoorGuestDenyTo)

        if ((this.denyFrom.value !== '100' && this.denyFrom.value !== '') && (this.denyTo.value !== '100' && this.denyTo.value !== '')) {
            const denyFromTime = this.denyFrom.value * 15;
            const denyToTime = this.denyTo.value * 15;

            const denyFromHours = Math.floor(denyFromTime / 60);
            const denyFromMinutes = denyFromTime - (denyFromHours * 60);
            const denyToHours = Math.floor(denyToTime / 60);
            const denyToMinutes = denyToTime - (denyToHours * 60);

            this.selectedDateTime =
            [new Date( new Date().setHours(denyFromHours,denyFromMinutes)), new Date( new Date().setHours(denyToHours,denyToMinutes))]
        }else {
            this.selectedDateTime = [new Date( new Date().setHours(0,0)), new Date( new Date().setHours(0,0))]
        }

    }


    onCloseFrom() {
        this.selectedDateTime[0] = this.dtFrom.selected
    }

    onCloseTo() {
        this.selectedDateTime[1] = this.dtTo.selected
    }


    sendTime() {
        this.setTime$.emit(this.selectedDateTime);
        this.loading = true;
        setTimeout( () => {
            this.loading = false;
        }, 1000)
      }

}
