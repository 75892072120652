import { Pipe, PipeTransform } from '@angular/core';
import { DesignationType } from 'src/app/core/models/designation-type.enum';
import { HvacMode } from 'src/app/core/models/hvac-modes/hvac-mode.model';

@Pipe({
  name: 'filterHvacModes'
})
export class FilterHvacModesPipe implements PipeTransform {

  transform(hvacModes: HvacMode[], selectedGroupDesignationType: DesignationType, selectedGroup): HvacMode[] {
      if (!selectedGroup) {
        return []
      }
      return hvacModes.filter((hvacMode: HvacMode) => {
      let selectedGroupId;
      if (selectedGroupDesignationType === DesignationType.LocationGroup) {
        selectedGroupId = selectedGroup.id;
      }
      if (selectedGroupDesignationType === DesignationType.ProjectObject) {
        selectedGroupId = selectedGroup.projectObjectId;
      }
      if (selectedGroupDesignationType === DesignationType.ProjectSubobject) {
        selectedGroupId = selectedGroup.projectSubobjectId;
      }
      return hvacMode.designationType === selectedGroupDesignationType && hvacMode.designationId === selectedGroupId
    })
  }

}
