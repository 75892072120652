<div class="search-wrapper margin-left10 mr-t">
  <input
  class="search-input"
  type="text"
  (keyup)="onFilter()"
  (keyup.enter)="onEnter()"
  [(ngModel)]=searchFilter
  placeholder="{{'Search' | translate}}">
  <div class="icon-search font-size22" *ngIf="searchFilter===''"></div>
  <div class="icon-clear font-size22" *ngIf="searchFilter!==''" (click)="onClear()" ></div>
</div>
