
    <div class="log-title backgroundBlue flex-row-center-h">
      {{'Room log' | translate}}
  </div>

  <div class="flex-row flex-row-space-evenly margin-left20 margin-top10 margin-right20">
      <ion-item class="width100">
        <ion-segment [(ngModel)]="activeCard"  (ngModelChange)="cardClicked($event)" >
          <ion-segment-button *ngIf="signedInUser | userHasPermission : SoftwarePermissionId.ValueLogView" class="width50" value="temperature">
            <ion-label class="ion-text-wrap">{{'Temperature log' | translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button *ngIf="signedInUser | userHasPermission : SoftwarePermissionId.AlarmsLogView" class="width50" value="alarm">
            <ion-label class="ion-text-wrap">{{'Alarm log' | translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button *ngIf="signedInUser | userHasPermission : SoftwarePermissionId.ValueLogView" class="width50" value="fullGraph">
            <ion-label class="ion-text-wrap">{{'Value log' | translate}}</ion-label>
          </ion-segment-button>
        </ion-segment>
    </ion-item>
    </div>

  <div class="content-container" *ngIf="activeCard === 'temperature'">
      <div class="flex-row-center-v gap5 margin-top10 margin-left20">
        <ion-button color="primary" size="small" (click)="showLast24hours()">24 H</ion-button>
        <ion-button color="primary" size="small" (click)="showLast7days()">7 D</ion-button>
        <ion-button color="primary" size="small" (click)="showLast30days()">30 D</ion-button>
        <ion-button color="primary" size="small" (click)="showCustom()">{{'Custom' | translate}}</ion-button>
        <div class="flex-row margin-left10">
        <select [(ngModel)]="selectedController" (ngModelChange)="controllerChanged($event)" >
        <option *ngFor="let controller of controllers" [ngValue]="controller"> {{ controller.name }}</option>
        </select>
        </div>
        <ion-list class="width40 margin-left20">
          <ion-item>
            <ion-select *ngIf="showSelect" #tablePropertiesSelect multiple="true" (ionChange)="selectChanged($event)" [(ngModel)]="propSelect" label="{{'Properties' | translate}}">
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isDoorOpened : true" [value]="Property.isDoorOpened">{{'Door' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isWindowOpened : true" [value]="Property.isWindowOpened">{{'Window' | translate}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
        <ion-list class="width30 margin-left20">
          <ion-item>
            <ion-select [interfaceOptions]="customTemperatureAlertOptions" interface="alert" *ngIf="showSelect" #tableTemperatureSelect multiple="true" (ionChange)="selectTemperatureChanged($event)" [(ngModel)]="temperatureTypesSelect" label="{{'Temperature' | translate}}">
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomTemperatureSet : true" [value]="Property.isRoomTemperatureSet">{{'Set room temperature' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomTemperatureCurrent : true" [value]="Property.isRoomTemperatureCurrent">{{'Current room temperature' | translate}}</ion-select-option>
           <!--    <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomTemperatureSet : true" [value]="Property.isBathroomTemperatureSet">{{'Set bathroom temperature' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomTemperatureCurrent : true" [value]="Property.isBathroomTemperatureCurrent">{{'Current bathroom temperature' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomRadiator : true" [value]="Property.isBathroomRadiator">{{'Bathroom radiator' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomFloorHeating : true" [value]="Property.isBathroomFloorHeating">{{'Bathroom floor heating' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomFloorHeating : true" [value]="Property.isRoomFloorHeating">{{'Room floor heating' | translate}}</ion-select-option> -->
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isCO2Measurement : true" [value]="Property.isCO2Measurement">{{'CO2 measurement' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isHumidity : true" [value]="Property.isHumidity">{{'Humidity' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isDaikinUnitMeasuredTemperature : true" [value]="Property.isDaikinUnitMeasuredTemperature">{{'Current daikin temperature' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isDaikinUnitSetTemperature : true" [value]="Property.isDaikinUnitSetTemperature">{{'Set daikin temperature' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isLgUnitMeasuredTemperature : true" [value]="Property.isLgUnitMeasuredTemperature">{{'Current LG temperature' | translate}}</ion-select-option>
              <ion-select-option *ngIf="selectedController | propExistInController : Property.isLgUnitSetTemperature : true" [value]="Property.isLgUnitSetTemperature">{{'Set Lg temperature' | translate}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>


      </div>
      <app-date-time-from-to #dateTimeFromTo (selectedDateTimeFromTo)="setDateTimeFromTo($event)" [hidden]="hideCustomTime"></app-date-time-from-to>
      <div *ngIf="graphCreated">
        <div *ngIf="tableProperties.length > 0 && circumstancesGraph.length > 0" class="circumstances">
          <ngx-charts-line-chart
          [legend]="legend"
          [legendPosition]="LegendPosition.Below"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [xAxis]="true"
          [yAxis]="true"
          [xAxisLabel]="'Time'"
          [yAxisLabel]="'Status'"
          [timeline]="false"
          [results]="circumstancesGraph"
          [yAxisTickFormatting]="yAxisTickFormatting">
        </ngx-charts-line-chart>
        </div>
         <div class="margin-top80 margin-left25 tempGraph">
            <ngx-charts-line-chart
            [legend]="true"
            [legendTitle]="'Legend' | translate"
            [legendPosition]="LegendPosition.Below"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [xAxisLabel]="'Time' | translate"
            [yAxisLabel]="'Values' | translate"
            [yScaleMin]="yScaleTempMin"
            [yScaleMax]="yScaleTempMax"
            [autoScale]="true"
            [timeline]="false"
            [results]="tempGraph | hideHumidityAndCo2"
            [autoScale]="true">
          </ngx-charts-line-chart>
          </div>
          <ng-container *ngIf="tempGraph | filterTemperatureLogs: 'Humidity' as humidityLogs">
          <div *ngIf="humidityLogs.length > 0" class="margin-top80 margin-left25 tempGraph">
            <ngx-charts-line-chart
            [legend]="true"
            [legendTitle]="'Legend' | translate"
            [legendPosition]="LegendPosition.Below"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [xAxisLabel]="'Time' | translate"
            [yAxisLabel]="'Values' | translate"
            [yScaleMin]="yScaleHumidityMin"
            [yScaleMax]="yScaleHumidityMax"
            [autoScale]="true"
            [timeline]="false"
            [results]="humidityLogs"
            [autoScale]="true">
          </ngx-charts-line-chart>
          </div>
        </ng-container>
        <ng-container *ngIf="tempGraph | filterTemperatureLogs: 'CO2Measurment' as co2MeasurementLogs">
          <div *ngIf="co2MeasurementLogs.length > 0" class="margin-top80 margin-left25 tempGraph">
            <ngx-charts-line-chart
            [legend]="true"
            [legendTitle]="'Legend' | translate"
            [legendPosition]="LegendPosition.Below"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [xAxisLabel]="'Time' | translate"
            [yAxisLabel]="'Values' | translate"
            [yScaleMin]="yScaleCo2MeasurmentMin"
            [yScaleMax]="yScaleCo2MeasurmentMax"
            [autoScale]="true"
            [timeline]="false"
            [results]="co2MeasurementLogs"
            [autoScale]="true">
          </ngx-charts-line-chart>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!graphCreated" class="width100 flex-row-center-h"><div class="lds-dual-ring"></div></div>
  </div>
  <div *ngIf="activeCard === 'access'">
  <app-access-logs [controllers]="controllers" [singleRoom]="true"></app-access-logs>
  </div>
  <div *ngIf="activeCard === 'alarm'">
      <app-alarm-logs [controllers]="controllers" [singleRoom]="true"></app-alarm-logs>
  </div>

  <div class="content-container" *ngIf="activeCard === 'fullGraph'">
    <div class="flex-row-center-v gap5 margin-top10 margin-left20">
      <ion-button color="primary" size="small" (click)="showLast24hours()">24 H</ion-button>
      <ion-button color="primary" size="small" (click)="showLast7days()">7 D</ion-button>
      <ion-button color="primary" size="small" (click)="showLast30days()">30 D</ion-button>
      <ion-button color="primary" size="small" (click)="showCustom()">{{'Custom' | translate }}</ion-button>
      <div class="flex-row-center-v margin-left10">
        <select [(ngModel)]="selectedController" (ngModelChange)="controllerChanged($event)">
          <option *ngFor="let controller of controllers" [ngValue]="controller"> {{ controller.name }}</option>
          </select>
      </div>
      <ion-list class="width40 margin-left20">
        <ion-item>
          <ion-select *ngIf="showSelect" multiple="true" (ionChange)="selectChanged($event)" [(ngModel)]="propSelect" label="{{'Properties' | translate}}" (click)="simulateClick()" (ionCancel)="onDismiss()" [interfaceOptions]="{cssClass: 'ionic-custom-select'}">
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDoorOpened : true : searchValue" [value]="Property.isDoorOpened">{{'Door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWindowOpened : true : searchValue" [value]="Property.isWindowOpened">{{'Window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCleaningStatus : true : searchValue" [value]="Property.isCleaningStatus">{{'Cleaning status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isInspectedStatus : true : searchValue" [value]="Property.isInspectedStatus">{{'Inspected status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDoorLock : true : searchValue" [value]="Property.isDoorLock">{{'Door locked' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedWindow : true : searchValue" [value]="Property.isBurglaryDetectedWindow">{{'Burglary detected on window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedDoor : true : searchValue" [value]="Property.isBurglaryDetectedDoor">{{'Burglary detected on door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDndStatus : true : searchValue" [value]="Property.isDndStatus">{{'DND status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isMinibarWasOpenedStatus : true : searchValue" [value]="Property.isMinibarWasOpenedStatus">{{'Minibar was opened' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWelcomeLightStatus : true : searchValue" [value]="Property.isWelcomeLightStatus">{{'Welcome light status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV1 : true : searchValue" [value]="Property.isFancoilV1">{{'Fancoil V1' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV2 : true : searchValue" [value]="Property.isFancoilV2">{{'Fancoil V2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV3 : true : searchValue" [value]="Property.isFancoilV3">{{'Fancoil V3' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomRadiator : true : searchValue" [value]="Property.isBathroomRadiator">{{'Bathroom radiator' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomFloorHeating : true : searchValue" [value]="Property.isBathroomFloorHeating">{{'Bathroom floor heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomFloorHeating : true : searchValue" [value]="Property.isRoomFloorHeating">{{'Room floor heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHVACLargeTempDiffRoom: true : searchValue" [value]="Property.isHVACLargeTempDiffRoom">{{'HVAC large temp diff room' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHVACLargeTempDiffBathroom : true : searchValue" [value]="Property.isHVACLargeTempDiffBathroom">{{'HVAC large temp diff bathroom' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRegulation : true : searchValue" [value]="Property.isHvacRegulation">{{'HVAC regulation' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacCalibration : true : searchValue" [value]="Property.isHvacCalibration">{{'HVAC calibration' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomEcoHyst1 : true : searchValue" [value]="Property.isHvacRoomEcoHyst1">{{'HVAC room eco hys 1' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomEcoHyst2 : true : searchValue" [value]="Property.isHvacRoomEcoHyst2">{{'HVAC room eco hys 2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacBathroomEcoHyst1 : true : searchValue" [value]="Property.isHvacBathroomEcoHyst1">{{'HVAC bathroom eco hys 1' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacBathroomEcoHyst2 : true : searchValue" [value]="Property.isHvacBathroomEcoHyst2">{{'HVAC bathroom eco hys 2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacHeatCool : true : searchValue" [value]="Property.isHvacHeatCool">{{'HVAC heating cooling' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacHeatCoolAuto : true : searchValue" [value]="Property.isHvacHeatCoolAuto">{{'HVAC heating cooling auto' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFlood : true : searchValue" [value]="Property.isFlood">{{'Flood' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacActive : true : searchValue" [value]="Property.isHvacActive">{{'HVAC active' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilOff : true : searchValue" [value]="Property.isHvacRoomControlFancoilOff">{{'HVAC room control fancoil off' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilLow : true: searchValue" [value]="Property.isHvacRoomControlFancoilLow">{{'HVAC room control fancoil low' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilMedium : true : searchValue" [value]="Property.isHvacRoomControlFancoilMedium">{{'HVAC room control fancoil medium' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilHigh : true : searchValue" [value]="Property.isHvacRoomControlFancoilHigh">{{'HVAC room control fancoil high' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilAuto : true : searchValue" [value]="Property.isHvacRoomControlFancoilAuto">{{'HVAC room control fancoil auto' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeBurglary : true : searchValue" [value]="Property.isSafeBurglary">{{'Safe burglary' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeCommError : true : searchValue" [value]="Property.isSafeCommError">{{'Safe comm error' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeDoor : true : searchValue" [value]="Property.isSafeDoor">{{'Safe door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomArmed : true: searchValue" [value]="Property.isRoomArmed">{{'Room armed' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglary : true : searchValue" [value]="Property.isBurglary">{{'Burglary' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedByPIR : true : searchValue" [value]="Property.isBurglaryDetectedByPIR">{{'Burglary detected by PIR' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isIgnoreCardTray : true : searchValue" [value]="Property.isIgnoreCardTray">{{'Ignore card tray' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCardType : true : searchValue" [value]="Property.isCardType">{{'Card type' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isIgnoreWindow : true : searchValue" [value]="Property.isIgnoreWindow">{{'Ignore window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSOS : true : searchValue" [value]="Property.isSOS">{{'SOS' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isPirInput : true : searchValue" [value]="Property.isPirInput">{{'PIR input' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isPanelBlocked : true : searchValue" [value]="Property.isPanelBlocked">{{'Panel blocked' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCardDeposited : true : searchValue" [value]="Property.isCardDeposited">{{'Card deposited' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isValveHeating : true : searchValue" [value]="Property.isValveHeating">{{'Valve heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isValveCooling : true : searchValue" [value]="Property.isValveCooling">{{'Valve cooling' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isNewCard : true : searchValue" [value]="Property.isNewCard">{{'New card' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDumper : true : searchValue" [value]="Property.isDumper">{{'Dumper' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isContactor : true : searchValue" [value]="Property.isContactor">{{'Contactor' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWelcomeLight : true : searchValue" [value]="Property.isWelcomeLight">{{'Welcome light' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isLight : true : searchValue" [value]="Property.isLight">{{'Light' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isMakeUpRoom : true : searchValue" [value]="Property.isMakeUpRoom">{{'Make up room' | translate}}</ion-select-option>
            <!-- NEW PROPS -->
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isControllerTempHigh : true : searchValue" [value]="Property.isControllerTempHigh">{{'High equipment temperature' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomBurglaryPIR : true : searchValue" [value]="Property.isRoomBurglaryPIR">{{'Room burglary detected by PIR' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFuse : true : searchValue" [value]="Property.isFuse">{{'Fuse problem' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isPowerOutage : true : searchValue" [value]="Property.isPowerOutage">{{'Power outage' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFire : true : searchValue" [value]="Property.isFire">{{'Fire' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWind : true : searchValue" [value]="Property.isWind">{{'Strong wind' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCO2 : true: searchValue" [value]="Property.isCO2">{{'CO2 level high' | translate}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
      <div class="margin-left20">
        <ion-checkbox color="primary" (ionChange)="onAccessLogClick($event)">{{'Access log' | translate}}</ion-checkbox>
      </div>
      <div class="margin-left20">
        <ion-checkbox color="primary" (ionChange)="onAlarmLogClick($event)">{{'Alarm log' | translate}}</ion-checkbox>
      </div>
      <app-date-time-from-to #dateTimeFromTo (selectedDateTimeFromTo)="setDateTimeFromTo($event)" [hidden]="hideCustomTime"></app-date-time-from-to>
    </div>
    <div *ngIf="(tableCreated && tableProperties.length > 0) || ((giveAccess || giveAlarm) && !logsLoading)">
      <div class="flex-row-center-h margin-top20 width100">
          <table>
             <tr>
              <th>{{'Time recorded' | translate}}</th>
              <th *ngIf="tableCreated && tableProperties.length > 0" class=" ">{{'Property' | translate}}</th>
              <th *ngIf="tableCreated && tableProperties.length > 0" class=" ">{{'Value' | translate}}</th>
              <th *ngIf="giveAccess" class=" ">{{'User' | translate}}</th>
              <th *ngIf="giveAccess" class=" ">{{'Location' | translate}}</th>
              <th *ngIf="giveAlarm" class=" ">{{'Level' | translate}}</th>
              <th *ngIf="giveAlarm" class=" ">{{'Description' | translate}}</th>
            </tr>
              <tr *ngFor="let data of tableData; let i = index" class="br-gray-b">
                <td *ngIf="showDataAtPage(i)" class="   ">
                 {{ data.timestamp | date:'dd.MM.yyyy. HH:mm:ss'}}
                </td>
                <td  *ngIf="tableCreated && tableProperties.length > 0 && showDataAtPage(i)" class=" ">
                  {{ getPropertyName(data.propertyType) }}
                </td>
                <td *ngIf="tableCreated && tableProperties.length > 0 && showDataAtPage(i)" class=" ">
                  {{ data.value}}
                </td>
                <td *ngIf="giveAccess && showDataAtPage(i)" class=" ">
                  {{ data.user}}
                </td>
                <td *ngIf="giveAccess && showDataAtPage(i)" class=" ">
                  {{ data.locationName}}
                </td>
                <td *ngIf="giveAlarm && showDataAtPage(i)" class=" ">
                  {{ data.level}}
                </td>
                <td *ngIf="giveAlarm && showDataAtPage(i)" class=" ">
                  {{ data.description}}
                </td>
              </tr>
          </table>
        </div>
        <div class="flex-row width95 margin-top5">
          <app-room-log-paginator
            class="to-right"
            [paginationData]="tableData"
            (targetPage)="onPageChange($event)">
          </app-room-log-paginator>
        </div>
    </div>
  <div *ngIf="(!tableCreated && tableProperties.length > 0) || logsLoading" class="width100 flex-row-center-h"><div class="lds-dual-ring"></div></div>
  </div>

