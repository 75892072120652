import { Pipe, PipeTransform } from '@angular/core';
import { Card } from 'src/app/core/models/card/card.model';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'getUserForCard'
})
export class GetUserForCardPipe implements PipeTransform {

  transform(card: Card, users: User[]) : User {
    return users.find((user)=> {
      return user.userId === card.userId;
    })
  }

}
