<div *ngIf="!singleRoom" class="content-header flex-row">
  <h1>{{'Access Log' | translate}}</h1>
  <div *ngIf="loading" class="loading-indicator"><div></div><div></div><div></div><div></div></div>
</div>

<div class="content-container">
  <div class="flex-row"  style="align-items: center;">

    <app-filter
      class="margin-right15 margin-bottom8"
      [inputDelay]="1500"
      (filteredValue)="searchFilterReady($event)">
    </app-filter>
    <!-- <div *ngIf="singleRoom" class="flex-row margin-right5">
      <select [(ngModel)]="mainController" (ngModelChange)="controllerChanged($event)" >
      <option *ngFor="let controller of controllers" [ngValue]="controller"> {{ controller.name }}</option>
      </select>
    </div> -->

    <app-date-time-from-to
      (selectedDateTimeFromTo)="setDateTimeFromTo($event)"
      ></app-date-time-from-to>

      <div *ngIf="!downloadInProgress" class="flex-row-center-full margin-left10">
        <div> {{'Export log for selected timeline to' | translate}}: </div>
        <ion-button class="margin-left10" (click)="confirmDownload('CSV')">CSV</ion-button>
        <ion-button class="margin-left10" (click)="confirmDownload('PDF')">PDF</ion-button>
      </div>

      <div *ngIf="downloadInProgress" class="flex-row-center-full margin-left10">
        <div> {{'Download in progress' | translate}}: </div>
        <div class="loading-indicator"><div></div><div></div><div></div><div></div></div>
      </div>

    <!-- <span class="icon-three-vert-dots font-size24 margin-top4 tooltip margin-right10" (click)="showAdditionalOptions=!showAdditionalOptions">
      <span class="bottom">{{'Additional options' | translate}}</span>
    </span>

    <span class="icon-filter font-size24  margin-top4 tooltip" (click)="additionalFilter=!additionalFilter">
      <span class="bottom">{{'Filtering options' | translate}}</span>
    </span> -->

    <app-paginator
      *ngIf="accessLogData.length > 0"
      class="to-right margin-top5"
      [paginationData]="paginationData"
      (targetPage)="onSubmit($event)">
   </app-paginator>
  </div>

    <!-- <div *ngIf="showAdditionalOptions" class="flex-row mr-b">
      <label for="showArchive">
      <input
        class="mr-l-75 option-input-sm"
        type="checkbox"
        id="showArchive"
        name="showArchive"
        [(ngModel)]="queryParams.includeArchive"
        (change)="onSubmit()">
        <span>{{'Search archived logs' | translate}}</span></label>
      <label for="cardsWoRecords">
      <input
        class="option-input-sm mr-l-25"
        type="checkbox"
        id="cardsWoRecords"
        name="cardsWoRecords"
        [(ngModel)]="showOnlyCardsWithoutRecords"
        (change)="onSubmit()"
        >
        <span>{{'Search only cards without user' | translate}}</span></label>
    </div> -->



<div *ngIf="accessLogData.length === 0 && !loading" >{{'No results found' | translate}}.</div>
<div *ngIf="singleRoom && loading" class="width100 flex-row-center-h"><div class="lds-dual-ring"></div></div>

<div class="margin-top30" *ngIf="accessLogData.length > 0">
  <table class="logs-table" >
    <thead>
      <tr>
        <th class="clickable" (click)="onSort('TIME')">
          <span>{{'Date and time' | translate}}</span>
          {{this.requestBody.sorting==='ASC|TIME'?"▲":""}}
          {{this.requestBody.sorting==='DSC|TIME'?"▼":""}}
        </th>

        <th>
          <span>{{'Card UID' | translate}}</span>
        </th>
        <th class="clickable"(click)="onSort('USER')">
          <span>{{'User' | translate }}</span>
          {{this.requestBody.sorting==='ASC|USER'?"▲":""}}
          {{this.requestBody.sorting==='DSC|USER'?"▼":""}}

        </th>

        <th>
          <span >{{'Location name' | translate}}</span>
        </th>

        <th>
          <span >{{'Event status' | translate}}</span>
        </th>
        </tr>
    </thead>

    <tr *ngFor="let log of accessLogData">
      <td class=" ">{{log.timestamp | date:'dd.MM.yyyy. HH:mm:ss'}}</td>
      <td class=" ">{{log.uid}}</td>
      <td class=" ">
        <div class="text-tooltip">
          <p>{{log.userFullName | textSizeFilterPipe : 15}}</p>
        <span class="bottom" *ngIf="log.userFullName.length > 15">{{log.userFullName}}</span>
      </div>
      </td>
      <td class=" ">
        <div class="text-tooltip">
          <p>{{log.locationName | textSizeFilterPipe : 15}}</p>
          <span class="bottom" *ngIf="log.locationName.length > 15">{{log.locationName}}</span>
        </div>
      </td>
    <td >{{ getTextualValueFromStatus(log.eventStatus)}}</td>
    </tr>
  </table>

  <app-paginator class="flex-row from-back margin-top20 margin-bottom20"
    [paginationData]="paginationData"
    (targetPage)="onSubmit($event)">
  </app-paginator>
</div>

</div>
