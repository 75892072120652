import { LocationsFilter } from '../locations-filter.model';

export class StoredFilter {
  userId: number;
  filters: LocationsFilter;

  public static getGuestRoomFilterFromStorage (userId: number): StoredFilter {
    const filtersStorageKey = 'irooms_filters_settings';
    const storedFilterSettings: StoredFilter [] = JSON.parse(localStorage.getItem(filtersStorageKey));
    return  storedFilterSettings?.find(
      (filterSettings: StoredFilter) => Number(filterSettings.userId) === Number(userId))

  }


  public static storeGuestRoomFilters(activeUserId: number, locationFilters: LocationsFilter) {
    const filtersStorageKey = 'irooms_filters_settings';
    const storedFiltersString: string = localStorage.getItem(filtersStorageKey);
    const newStoredFilter = {
      userId: activeUserId,
      filters: locationFilters
      }
    if (storedFiltersString === null) {
      const storedFilterSettings: StoredFilter [] = [newStoredFilter]
      localStorage.setItem(filtersStorageKey, JSON.stringify(storedFilterSettings));
    } else {
      const storedFilterSettings: StoredFilter [] = JSON.parse(storedFiltersString);
      const newStoredFilterSettings = storedFilterSettings.filter((storedFilter) => storedFilter.userId !== activeUserId)
      newStoredFilterSettings.push(newStoredFilter);
      localStorage.setItem(filtersStorageKey, JSON.stringify(newStoredFilterSettings));
    }
  }

  public static getCommonAreaFilterFromStorage (userId: number): StoredFilter {
    const filtersStorageKey = 'irooms_common_area_filters_settings';
    const storedFilterSettings: StoredFilter [] = JSON.parse(localStorage.getItem(filtersStorageKey));
    return  storedFilterSettings?.find(
      (filterSettings: StoredFilter) => Number(filterSettings.userId) === Number(userId))

  }


  public static storeCommonAreaFilters(activeUserId: number, locationFilters: LocationsFilter) {
    const filtersStorageKey = 'irooms_common_area_filters_settings';
    const storedFiltersString: string = localStorage.getItem(filtersStorageKey);
    const newStoredFilter = {
      userId: activeUserId,
      filters: locationFilters
      }
    if (storedFiltersString === null) {
      const storedFilterSettings: StoredFilter [] = [newStoredFilter]
      localStorage.setItem(filtersStorageKey, JSON.stringify(storedFilterSettings));
    } else {
      const storedFilterSettings: StoredFilter [] = JSON.parse(storedFiltersString);
      const newStoredFilterSettings = storedFilterSettings.filter((storedFilter) => storedFilter.userId !== activeUserId)
      newStoredFilterSettings.push(newStoredFilter);
      localStorage.setItem(filtersStorageKey, JSON.stringify(newStoredFilterSettings));
    }
  }
}
