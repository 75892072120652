import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'controllerIsInList'
})
export class ControllerIsInListPipe implements PipeTransform {

  transform(controller: Controller, controllers: Controller[] ): boolean {
    return controllers.some((cont)=> {
      return cont.locationId === controller.locationId;
    })
  }

}
