import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements OnInit, OnDestroy {

  @Input() inputDelay: number;
  @Input() someTextWriten: EventEmitter<string> = new EventEmitter<string>();
  @Output() filteredValue: EventEmitter<string> = new EventEmitter<string>();
  keyUp = new Subject<string>();
  filterSubscription: Subscription;
  searchFilter = '';

  constructor() { }

  ngOnInit(): void {
    this.filterSubscription = this.keyUp.pipe(
      debounceTime(this.inputDelay),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.someTextWriten.emit(this.searchFilter);
      this.filteredValue.emit(value);
    });
  }

  onFilter(){
    this.keyUp.next(this.searchFilter);
  }

  onEnter(){
    this.someTextWriten.emit(this.searchFilter);
    this.filteredValue.emit(this.searchFilter);
  }

  onClear(){
    this.searchFilter = '';
    this.someTextWriten.emit(this.searchFilter);
    this.filteredValue.emit(this.searchFilter);
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

}
