import { Pipe, PipeTransform } from "@angular/core";
import { Controller } from "src/app/core/models/controller.model";

@Pipe({
    name: 'isAccessControl'
})
export class IsAccessControlPipe  implements PipeTransform {
    transform(controller: Controller): boolean {
        return Controller.isAccessControl(controller);
    }
}