import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRelayOutputStatusState'
})
export class GetRelayOutputStatusStatePipe implements PipeTransform {

  transform(controllers: Controller[], outputStatusNumber: string, updated):
   'By presence' | 'By rented' | 'Force on' | 'Force off' | '...' {
    const byRentedProps = [];
    const forcedOnProps = [];
    const forcedOffProps = [];

    controllers.forEach((cont)=> {
      cont.controllerProperties.$values.forEach((prop)=> {
        if (!prop.mqttTopic.endsWith(outputStatusNumber)) {
          return
         }

        if (Property.isRelayOutputByRentedOrPresence(prop) ) {
          byRentedProps.push(prop)
        } else if (Property.isRelayOutputForceOn(prop) ) {
          forcedOnProps.push(prop)
        } else if (Property.isRelayOutputForceOff(prop) ) {
          forcedOffProps.push(prop)
        }
      })
    })

    if (forcedOnProps.some((p)=> Property.isActive(p))) {
      // return 'Force on'
      return forcedOnProps[0].name.split('|')[1]
    } else if (forcedOffProps.some((p)=> Property.isActive(p))) {

      // return 'Force off'
      return forcedOffProps[0].name.split('|')[1]

    }else if (byRentedProps.some((p)=> Property.isActive(p))) {
      return 'By rented'
    } else if (byRentedProps.some((p)=> Property.isInactive(p))){
      return 'By presence'
    } else {
      return '...'
    }
  }

}
