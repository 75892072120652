import { Pipe, PipeTransform } from '@angular/core';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';

@Pipe({
  name: 'getLatestHighestPriorityAlarm'
})
export class GetLatestHighestPriorityAlarmPipe implements PipeTransform {

  transform(alarms: AlarmAlert[]): AlarmAlert {
    if (alarms.length === 0 ) {
      return;
    }

    let priorityAlarms: AlarmAlert[] = alarms.filter( alarm => +alarm.level === 4)
    if (priorityAlarms.length === 0) {
      priorityAlarms = alarms.filter( alarm => +alarm.level === 3)
    }
    if (priorityAlarms.length === 0) {
      priorityAlarms = alarms.filter( alarm => +alarm.level === 2)
    }
    if (priorityAlarms.length === 0) {
      priorityAlarms = alarms.filter( alarm => +alarm.level === 1)
    }

    priorityAlarms.sort(function(x, y){
      return <any>new Date(y.timestamp) - <any>new Date(x.timestamp);
  })

  return priorityAlarms[0];
  }
}
