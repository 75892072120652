import { Pipe, PipeTransform } from '@angular/core';
import { Location, Tag } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'locationHasTag'
})
export class LocationHasTagPipe implements PipeTransform {

  transform(location: Location, tagName: string): boolean {
    return location.locationTags.$values.some((lTag: Tag)=> {
      return lTag.tag.toLowerCase() === tagName.toLowerCase()
    });
  }

}
