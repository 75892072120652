import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';

@Pipe({
  name: 'getControllersInSubObject'
})
export class GetControllersInSubObjectPipe implements PipeTransform {

  transform(controllers: Controller[], subObject: ProjectSubObject): Controller[] {
    if (!subObject) {
      return controllers;
    }
      const contInSubobject = controllers.filter((cont )=> {
        return + cont.projectSubObjectId === subObject.projectSubobjectId
      })
      return contInSubobject
    
  }
}
