import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CardValidity } from './http/api-settings.service';

@Injectable({
  providedIn: 'root'
})
export class CardValidityService {

  cardValiditySettings: CardValidity;
  cardValidityChanged = new Subject<CardValidity>();

  constructor() {}

  getCardValiditySettings() {
      return this.cardValiditySettings;
  }

  setCardValiditySettings(cardValiditySettings: CardValidity) {
      this.cardValiditySettings = cardValiditySettings;
      this.cardValidityChanged.next(this.cardValiditySettings);
  }

  
}
