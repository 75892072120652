import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'textSizeFilterPipe'
})
export class TextSizeFilterPipe implements PipeTransform {
    transform(value: string, limit = 30, ellipsis = '...') {
        if (value) {
            return value?.length > limit ? value.substr(0, limit) + ellipsis : value;
        } else {
            return ''
        }
    }
}
