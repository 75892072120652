import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getFloorsForEditingGroups',
})
export class GetFloorsForEditingGroupsPipe implements PipeTransform {
  transform(allFloors: LocationGroup[], locations: Location[],
  targetObject: ProjectObject, targetSubobject: ProjectSubObject): LocationGroup[] {

      const targetObj: string = targetObject.designation.split('/')[0];
      const targetSubobj: string = targetSubobject.designation.split('/')[1];

      const floorsInObjectAndSuboject = allFloors.filter((flo)=> {
        return flo.locations.some((location: Location)=> {
          const floorObject = Location.getLocationObject(location);
          const floorSubobject = Location.getLocationSubObject(location);
          return targetObj === floorObject && targetSubobj === floorSubobject
        })
      })


        const floorList = floorsInObjectAndSuboject.slice();
        const locationOnFloors:Location[]  = floorList.reduce((flList,floor)=> {
            flList = [...floor.locations, ...flList]
            return flList;
          },[])
        const floorForUndistributedControllers: LocationGroup = {
            id: 9999,
            name: 'Undistributed',
            locationGroupTypeId: 3,
            locations: []
          };

          locations.forEach((loc : Location)=> {
            if (Location.getLocationObject(loc) === targetObj && Location.getLocationSubObject(loc)=== targetSubobj && !locationOnFloors.some( location => location.locationId == loc.locationId)) {
              floorForUndistributedControllers.locations.push(loc);
            }
          })



          if (floorForUndistributedControllers.locations.length>0) {
            floorList.push(floorForUndistributedControllers)
          }
          return floorList;
  }
}
