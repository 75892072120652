import { Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Setting } from 'src/app/core/models/project/setting.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiLocationGroupsService, LocationGroup } from '../../services/api-location-groups.service';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { LocationPresetCrossRef } from 'src/app/core/models/cross-ref.model';
import { ButtonsSelectComponent } from '../buttons-select/buttons-select.component';
import { PopoverController } from '@ionic/angular';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { Location } from 'src/app/core/models/project/location.model';

@Component({
  selector: 'app-room-modal-hvac',
  templateUrl: './room-modal-hvac.component.html',
  styleUrls: ['./room-modal-hvac.component.scss']
})
export class RoomModalHvacComponent {
  @Input() location: Location;
  controllers: Controller[];
  Controller = Controller;
  isAccessControl = Controller.isAccessControl;
  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  intervalForDoorsActive: boolean;
  defaultSettings: DefaultsPreset[];
  defaultPresetsSubscription: Subscription;
  stopControllerPolling = false;
  controllerPollingSubscription: Subscription;

  locationCrossRef: LocationPresetCrossRef[] =[];
  defaultPresets: DefaultsPreset[] = [];
  hvacModeLocationGroups: LocationGroup[] = [];
  props: Property[] = [];

  Property = Property;
  renderNumberInputs = true;
  logedInUser: User;
  settings$ = this.settingsService.getRoomModalPropSettings();
  bathroomHasTemps: boolean;
  hasProps: boolean;
  settingsSubscription: Subscription;

  toggleHvacExist = false;

  ignorePublishingList = [];
  loadingPublishing = false;
  optionsRoomRadiator = ['active by rented', 'active by presence'];
  optionsBathroomRadiator = ['active by rented ', 'active by presence '];
  selectedOptionRoom: string;
  selectedOptionBathroom: string;
  reqInProgress = false;

  fancoilMode: string;

  constructor(
    private settingsService: SettingsService,
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService,
    private apiSettingsService: ApiSettingsService,
    private apiLocationGroupsService: ApiLocationGroupsService,
    private popoverController: PopoverController,
    private currentUserStoreService: CurrentUserStoreService
    ) {}

    ngOnInit(): void {
      this.controllers = this.location.controllers.$values;
      this.selectController(this.controllers[0]);
      this.apiSettingsService.getDefaultPresets().subscribe();
      this.defaultPresetsSubscription = this.settingsService.getDefaultPresets().subscribe( value => {
        this.defaultSettings = value;
      })
      this.getFancoilMode();
      this.getLocationPreseCrossRef();
      this.getDefaultPresets();
      this.getHvacModeLocationGroups();
      this.logedInUser = this.currentUserStoreService.getUser()
      this.toggleHvacExist = this.isPropertyInController(Property.isHvacToggle)
      this.checkIfBathroomHasTemps();
      this.apiProjectService.getNotPublishingList().subscribe((list)=> {
        this.ignorePublishingList = list;
        this.loadingPublishing = false;
      });
      this.checkForSettings();
    }

    checkIfBathroomHasTemps() {
      this.settingsSubscription = this.settings$.subscribe((settings) => {
        settings.bathroom.forEach((setting) => {
            this.props = this.selectedControllers.reduce((allFilteredProps, controller)=> {
            const filteredPropsOnController = controller.controllerProperties.$values.filter((prop: Property) => {
              return (prop.type >= Number(setting.codeFrom) && prop.type <= Number(setting.codeTo))
            });
            allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
            return allFilteredProps;
          },[]);
        })
      })
      if(this.props.length > 0){
        this.bathroomHasTemps = true;
      }else{
        this.bathroomHasTemps = false;
      }
    }

    checkForSettings(){
      this.settingsSubscription = this.settings$.subscribe((settings) => {
        settings.room.forEach(setting => {
          const props =  this.selectedControllers.reduce((allFilteredProps, controller)=> {
            const filteredPropsOnController = controller.controllerProperties.$values.filter((prop: Property) => {
              return (+prop.type >= Number(setting.codeFrom) && +prop.type <= Number(setting.codeFrom))
            });
            allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
            return allFilteredProps;
          },[]);
          if(props.length > 0){
            this.hasProps = true;
          }else{
            this.hasProps = false;
          }
        });
      });
    }

    getFancoilMode() {
      const target = this.selectedControllers[0].controllerProperties.$values.find( prop => {
        if ((Property.isHvacRoomControlFancoilOff(prop) && typeof +prop.value === 'number' && prop.value !== '') ||
            (Property.isHvacRoomControlFancoilLow(prop) && typeof +prop.value === 'number' && prop.value !== '') ||
            (Property.isHvacRoomControlFancoilMedium(prop) && typeof +prop.value === 'number' && prop.value !== '') ||
            (Property.isHvacRoomControlFancoilHigh(prop) && typeof +prop.value === 'number' && prop.value !== '') ||
            (Property.isHvacRoomControlFancoilAuto(prop) && typeof +prop.value === 'number' && prop.value !== '')) {
          return true;
        }
      })
  
      if (target) {
        this.fancoilMode = target.value;
      } else {
        this.fancoilMode = '0'
      }
    }

    getLocationPreseCrossRef() {
      this.apiSettingsService.getLocationPresetCrossReferences().subscribe( value => {
        this.locationCrossRef = value;
      })
    }

    getDefaultPresets() {
      this.apiSettingsService.getDefaultPresets().subscribe( value => {
        this.defaultPresets = value;
      })
    }

    getHvacModeLocationGroups() {
      this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.HvacModeGroup).subscribe( value => {
        this.hvacModeLocationGroups = value;
      })
    }

    selectController(controller: Controller) {
      this.renderNumberInputs = false;
      this.selectedControllers[0] = controller;
      this.selectedControllers = this.selectedControllers.slice();
      setTimeout(() => { // render is reset to reinit child components and restart ngoinit and ngodestroy of child components
        this.renderNumberInputs = true;
      }, 0);
      this.checkForSettings();
    }

    isPropertyInController(target: any) {
      let property: Property;
      const active: boolean = this.controllers.some(
        (controller: Controller) => {
          property = controller.controllerProperties.$values.find(target);
          return property;
        }
      );
      return active
    }

    onFancoilSelect(event: any) {
      let property: Property;
      const parentController: Controller = this.controllers.find(
        (controller: Controller) => {
          property = controller.controllerProperties.$values.find(Property.isHvacRoomControlFancoilOff);
          return property;
        }
      );
      const designation = parentController.designation;
      const propertyId = property.id;
      this.apiProjectService
        .changeProperty(designation, propertyId, event.target.value)
        .subscribe();
    }

    isSettingInController(target: any) {
      let setting: Setting;
      const active: Controller = this.controllers.find(
        (controller: Controller) => {
          setting = controller.controllerSettings.$values.find(target);
          return setting;
        }
      );

      if(setting && setting.value === '1') {
        return false;
      } else {
        return active
      }
    }

    syncHvacSettings() {
      this.apiProjectService.syncHvacSettings(this.selectedControllers[0].id).subscribe()
    }

    onDestroy(){
      if (this.defaultPresetsSubscription) {
        this.defaultPresetsSubscription.unsubscribe();
      }
      if (this.controllerPollingSubscription) {
        this.controllerPollingSubscription.unsubscribe();
      }
      if (this.settingsSubscription) {
        this.settingsSubscription.unsubscribe();
      }

    }
}
