<ng-container
  *ngIf="selectedControllers | findProperty : hvacBaseSet: null : true as hvacBaseSetProps"
>
  <div *ngIf="hvacBaseSetProps.length > 0" class="flex-row-center-v">
    <app-number-input
      [controllers]="selectedControllers"
      [findPropFunction]="hvacBaseSet"
      [findPropFunctionSecondaryA]="hvacEcoHyst1"
      [findPropFunctionSecondaryB]="hvacEcoHyst2"
    ></app-number-input>
  </div>
</ng-container>
<ng-container
  *ngIf="selectedControllers | findProperty : hvacEcoHyst1: null : true as hvacEcoHyst1Props"
>
  <div *ngIf="hvacEcoHyst1Props.length > 0" class="flex-row-center-v">
    <app-number-input
      class="flex-center"
      [controllers]="selectedControllers"
      [findPropFunction]="hvacEcoHyst1"
      [findPropFunctionSecondaryA]="hvacBaseSet"
      [findPropFunctionSecondaryB]="hvacEcoHyst2"
    ></app-number-input>
  </div>
</ng-container>
<ng-container
  *ngIf="selectedControllers | findProperty : hvacEcoHyst2 : null : true as hvacEcoHyst2Props"
>
  <div *ngIf="hvacEcoHyst2Props.length > 0" class="flex-row-center-v">
    <app-number-input
      [controllers]="selectedControllers"
      [findPropFunction]="hvacEcoHyst2"
      [findPropFunctionSecondaryA]="hvacBaseSet"
      [findPropFunctionSecondaryB]="hvacEcoHyst1"
    ></app-number-input>
  </div>
</ng-container>
