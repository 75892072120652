import { Pipe, PipeTransform } from '@angular/core';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { KeyOption } from 'src/app/modules/project/services/http/api-project.service';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getAccessControllsOnFloor'
})
export class GetAccessControllsOnFloorPipe implements PipeTransform {

  transform(keyOptions: KeyOption[], floor: LocationGroup, selectedDesignation: string): KeyOption[] {

    const targetObj: string = selectedDesignation.split('/')[0];
    const targetSubobj: string = selectedDesignation.split('/')[1];

    return keyOptions.filter((kOption: KeyOption)=> {
      if (kOption.floor === null) { // if controller is not on floor put it on undistributed floor
        kOption.floor =
        { id: 9999,
          locationGroupTypeId: LocationGroupType.FloorGroup,
          locations: [],
          name: 'Undistributed'
          }
      }

      return (targetObj === Location.getLocationObject(kOption.location) || targetObj === '*')
      && (targetSubobj === Location.getLocationSubObject(kOption.location) || targetSubobj === '*')
      && kOption.floor.id === floor.id
    })
  }

}
