import { Component, Input, OnInit } from '@angular/core';

import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Setting } from 'src/app/core/models/project/setting.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { Subscription } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { RoomTagPopoverComponent } from 'src/app/shared/components/room-tag-popover/room-tag-popover.component';
import { PopoverController } from '@ionic/angular';
import { Location, Tag } from 'src/app/core/models/project/location.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { User } from 'src/app/core/models/user/user.model';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';


@Component({
  selector: 'app-access-control-modal-settings',
  templateUrl: './access-control-modal-settings.component.html',
  styleUrls: ['./access-control-modal-settings.component.scss'],
})
export class  AccessControlModalSettingsComponent implements OnInit {
  @Input() location: Location
  controllers: Controller[];
  isAccessControl = Controller.isAccessControl;
  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  intervalForDoorsActive: boolean;
  defaultSettings: DefaultsPreset[];
  stopControllerPolling: boolean = false;
  controllerPollingSubscription: Subscription;
  allTags: Tag[] = []
  Property = Property;


  isDoorOpened = Property.isDoorOpened;

  isACDoorOpenPushButton = Property.ACDoorOpenPushButton;
  isACDoorOpenAnyCard = Property.ACDoorOpenAnyCard;
  isOfflineTimer = Setting.offlineTimer;
  isACAlwaysOpen = Property.ACAlwaysOpen;

  isACDoorGuestDenyFrom = Property.ACDoorGuestDenyFrom;
  isACDoorGuestDenyTo = Property.ACDoorGuestDenyTo;

  isACDenyGuests = Property.ACDenyGuests;
  isACDenyStaff = Property.ACDenyStaff;
  isACDenyAll = Property.ACDenyAll;
  SoftwarePermissionId = SoftwarePermissionId
  signedInUser: User;

  constructor(
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService,
    private apiSettingsService: ApiSettingsService,
    private popoverController: PopoverController,
    private pipe: CustomTranslatePipe,
    private currentUserStoreService: CurrentUserStoreService
    ) {}

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values
    this.selectController(this.controllers[0]);
    this.isIntervalForDoorsActive();
    this.apiSettingsService.getDefaultPresets().subscribe( value => {
      this.defaultSettings = value;
    })

    this.apiProjectService.getAllTags().subscribe( value => {
      this.allTags = value;
    })
    this.isControllerPolled();
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());

  }

  isControllerPolled() {
    this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    this.apiProjectService.getNotPolledControllers().subscribe( value => {
      value.forEach( cont => {
        if (cont.object === this.controllers[0].object && cont.subObject === this.controllers[0].subObject
          && cont.zone === this.controllers[0].zone && cont.subZone === this.controllers[0].subZone) {
          this.stopControllerPolling = true;
        }
      })
    })
    this.controllerPollingSubscription = this.projectService.notPolledControllersChanged.subscribe( () => {
      this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    })
  }



  selectController(controller: Controller) {
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
  }

  onSetIntervalClicked(event: any) {
    if (event.target.checked) {
      this.intervalForDoorsActive = true;
    } else {
      this.intervalForDoorsActive = false;
      this.setToTime(100),
      this.setFromTime(100);
    }
  }

  getAlarmName(prop: Property) {
    if (Property.isAnyBurglaryProperty(prop)) {
      return this.pipe.transform('Burglary detected')
    }
     else if (Property.isSafeCommError(prop)) {
      return this.pipe.transform('Safe comm error')
    }
    else if (Property.isHVACLargeTempDiffRoom(prop)) {
      return this.pipe.transform('Large temp. diff. room')
    }
    else if (Property.isHVACLargeTempDiffBathroom(prop)) {
      return this.pipe.transform('Large temp. diff. bathroom' )
    }
    else if (Property.isFlood(prop)) {
      return this.pipe.transform('Flood')
    }
    else if (Property.isBurglaryDetectedByPIR(prop)) {
      return this.pipe.transform('Burglary detected')
    }
     else if (Property.isSOS(prop)) {
      return this.pipe.transform('SOS')
    }
    else if (Property.isDoorOpenTooLongAlarm(prop)) {
      return this.pipe.transform('Door open too long')
    }
    else if (Property.isWindowOpenTooLongAlarm(prop)) {
      return this.pipe.transform('Win. open too long')
    }
  }

  isIntervalForDoorsActive() {
    let denyFrom: Property;
    let denyTo: Property;

    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        denyFrom = controller.controllerProperties.$values.find(this.isACDoorGuestDenyFrom);
        return denyFrom;
      }
    );

    if (parentController) {
      denyTo = parentController.controllerProperties.$values.find(this.isACDoorGuestDenyTo);
    }

    if (denyFrom && denyTo) {
      if (denyFrom.value !== '100' && denyTo.value !== '100') {
        this.intervalForDoorsActive = true;
      }
    }
  }

  async editControllerTags(ev) {
    const popover = await this.popoverController.create({
      component: RoomTagPopoverComponent,
      cssClass: 'tags-popover',
      event: ev,
      showBackdrop: false,
      componentProps: {
        location: this.location,
        existingTags: this.allTags
      }
    });
    await popover.present();

    popover.onDidDismiss().then( () => {
    })
  }


  procesTimeObject(data: any[]) {
    const timeFrom = data[0].getHours() * 60 + data[0].getMinutes();
    this.setFromTime(Math.floor(timeFrom / 15));

    const timeTo = data[1].getHours() * 60 + data[1].getMinutes();
    this.setToTime(Math.floor(timeTo / 15))

  }

  setFromTime(value: number) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(this.isACDoorGuestDenyFrom);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  setToTime(value: number) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(this.isACDoorGuestDenyTo);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }


  syncHvac() {
    this.apiProjectService.syncHvacSettings(this.selectedControllers[0].id).subscribe()
  }

  changeControllerPooling(event: any) {
    const contToSend = structuredClone(this.controllers[0])
    if (event.target.checked) {
      this.apiProjectService.stopPolling(contToSend).subscribe()
    } else {
      this.apiProjectService.resumePolling(contToSend).subscribe()
    }
  }

  ngOnDestroy(): void {
    if (this.controllerPollingSubscription) {
      this.controllerPollingSubscription.unsubscribe();
    }
  }
}
