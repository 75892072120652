import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { HvacModeType } from 'src/app/core/models/hvac-mode-type.enum';
import { HvacMode } from 'src/app/core/models/hvac-modes/hvac-mode.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { API_BASE_URL } from 'src/environments/environment';
import { HvacModesService } from './hvac-modes.service';

@Injectable({
  providedIn: 'root'
})
export class ApiHvacModesService {

  constructor(
    private http: HttpClient,
    private hvacModesService: HvacModesService
      ) { }

  getHvacModes() {
    return this.http.get<HvacMode[]>(API_BASE_URL + '/modes').pipe(
        tap(modes => {
            this.hvacModesService.setHvacModes(modes);

        })
    );
  }

  activateHvacMode(modeId) {
    return this.http.get(API_BASE_URL + `/modes/activate?id=${modeId}`)
  }

  getActiveHvacMode(objectId: string) {
    return this.http.get<HvacModeType>(API_BASE_URL + `/modes/activemode?objectid=${objectId}`).pipe(
      tap((activateHvacModeGlobal: HvacModeType) => {
        this.hvacModesService.setActiveHvacModeGlobal(activateHvacModeGlobal);
    })
    )
  }

}
