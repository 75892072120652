import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Card } from 'src/app/core/models/card/card.model';
import { CardReaderService } from 'src/app/core/services/card-reader.service';
import { CustomTranslatePipe } from '../../pipes/custom-translate.pipe';
import { Location } from 'src/app/core/models/project/location.model';

@Component({
  selector: 'app-ble-key-qr-code',
  templateUrl: './ble-key-qr-code.component.html',
  styleUrls: ['./ble-key-qr-code.component.scss']
})
export class BleKeyQrCodeComponent implements OnInit {
  @Input() card: Card;
  @Input() location: Location;

  img: string
  deepLink: string;

  codeForDisplay: Array<{ id: string, qr: string }> = [];

  constructor(
    private cardReaderService: CardReaderService,
    private toastController: ToastController,
    private pipe: CustomTranslatePipe,
    private modalController: ModalController
    ) {}

  ngOnInit(): void {
    this.cardReaderService.getCardQrCode(this.card.uid).subscribe(resp => {
      this.img = 'data:image/png;base64,' + resp;
    });
    this.cardReaderService.getCardDeepLink(this.card.uid).subscribe(resp => {
      this.deepLink = resp;
    });
  }

  async copyToClipboard() {
    navigator.clipboard.writeText(this.deepLink);
    const toast = await this.toastController.create({
      message: this.pipe.transform('Link copied to clipboard'),
      duration:2000
    });
    await toast.present();
  }

  openQrCodeInNewWindow() {
    const myWindow = window.open('', '_blank', 'width=600,height=600');
    myWindow.document.write(`<img src=${this.img}>`);
  }

  sendLinkToGuestEmail() {
    this.cardReaderService.sendDeepLinkToCardEmail(this.card.uid).subscribe(async ()=> {
      const toast = await this.toastController.create({
        message: this.pipe.transform('Link sent to guest e-mail'),
        duration:2000
      });
      await toast.present();
    })
  }

  close() {
    this.modalController.dismiss();
  }


}
