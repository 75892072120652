import { Pipe, PipeTransform } from '@angular/core';
import { PermissionType } from 'src/app/core/models/permissions/permission.enum';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'hasEveryAccessGuestRoomPermission',
})
export class HasEveryAccessGuestRoomPermissionPipe implements PipeTransform {

  transform(permissionsList: Permission[], allPermissions: Permission[], locations: Location[]): boolean {
    if (!permissionsList || !allPermissions || !locations) {
      return false
    }
    const accessGuestRoomPermissions = allPermissions.filter((perm) => {
      const location = locations.find( loc => loc.locationId === perm.locationId)
      if (location) {
        const isGuestRoom = Location.isGuestRoom(location);
        return perm.typeId === PermissionType.Unlock && isGuestRoom;
      }
    });
    if (accessGuestRoomPermissions.length === 0 ){
      return false;
    }

    return accessGuestRoomPermissions.every((accessGuestRoomPermission) => {
      return permissionsList?.find((userPerm) => {
        return accessGuestRoomPermission.permissionId === userPerm.permissionId;
      });
    });
  }
}

