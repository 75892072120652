import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRelayOutputNumbers'
})
export class GetRelayOutputNumbersPipe implements PipeTransform {

  transform(controllers: Controller[]):string[]  {
    const relayNumberArray = [];


    controllers.forEach((cont)=> {
      cont.controllerProperties.$values.forEach((prop)=> {
        if (Property.isRelayOutputByRentedOrPresence(prop) || Property.isRelayOutputForceOn(prop)
        || Property.isRelayOutputForceOff(prop) ||Property.isRelayOutputStatus(prop) || Property.isRelayOutputInCooling(prop)) {
          const relayOutputNumber = prop.mqttTopic.split('/')[3]
          if (relayOutputNumber && !relayNumberArray.includes(relayOutputNumber)) {
            relayNumberArray.push(relayOutputNumber)
          }
      }
       /*  if (Property.isRelayOutputByRentedOrPresence(prop) && !relayNumberArray.includes(prop.mqttTopic.split('/')[3]) ) {
          relayNumberArray.push(prop.mqttTopic.split('/')[3] )
        } else if (Property.isRelayOutputForceOn(prop) && !relayNumberArray.includes(prop.mqttTopic.split('/')[3])) {
          relayNumberArray.push(prop.mqttTopic.split('/')[3])
        } else if (Property.isRelayOutputForceOff(prop) && !relayNumberArray.includes(prop.mqttTopic.split('/')[3])) {
          relayNumberArray.push(prop.mqttTopic.split('/')[3])
        } else if (Property.isRelayOutputStatus(prop) && !relayNumberArray.includes(prop.mqttTopic.split('/')[3])) {
          relayNumberArray.push(prop.mqttTopic.split('/')[3])
        } else if (Property.isRelayOutputInCooling(prop) && !relayNumberArray.includes(prop.mqttTopic.split('/')[3])) {
          relayNumberArray.push(prop.mqttTopic.split('/')[3])
        } */
      })
    })
    return relayNumberArray;
  }

}
