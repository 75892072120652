import { Pipe, PipeTransform } from "@angular/core";
import { Controller } from "src/app/core/models/controller.model";

@Pipe({
    name: 'controllerIsSecondInJoinRoom'
})
export class ControllerIsSecondInJoinRoomPipe  implements PipeTransform {
    transform(controllers: Controller[], allControllers: Array<Controller[]>): boolean {
      //TODO JOIN ROOM
        // const mainController = Controller.getMainController(controllers)

        // const index = allControllers.findIndex( cont => cont[0].locationId === mainController.locationId)
        // if (index >= 1) {
        //   if(!allControllers[index-1][0].isJoined && allControllers[index+1][0].isJoined) {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // } else {
        //   return false;
        // }
        return false;
    }
}