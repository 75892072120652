import { Component, Input, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiLocationGroupsService } from '../../services/api-location-groups.service';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { enterAnimation } from 'src/app/shared/animations/animations';
import { ControllerCommisioningParam } from 'src/app/core/models/project/controller-commissioning-params';
import { AlertController, ModalController } from '@ionic/angular';
import { ApiCcpService } from '../../services/api-ccp.service';
import { error } from 'console';

@Component({
  selector: 'app-apply-to-modal',
  templateUrl: './apply-to-modal.component.html',
  animations: [enterAnimation],
  styleUrls: ['./apply-to-modal.component.scss']
})
export class ApplyToModalComponent implements OnInit {

  @Input() ccps: ControllerCommisioningParam[];
  @Input() selectedController: Controller;
  allLocations$: Observable<Location[]> = this.projectService.getAllLocations();
  locations: Location[] = [];
  controllers: Controller[] = [];
  selectedControllers: Controller[] = [];
  groups: any[] = [];
  selectedGroups: any[] = [];
  controllersFromGroups: Controller[] = [];
  controllersToOverwrite: string[] = [];
  allControllersSelected: boolean;
  allGroupsSelected: boolean;
  viewType = 'controllers' || 'groups';
  activePopup: string;
  locationsToShowInPopup: Location[];
  controllerIdsToSend: number[] = [];
  sending: boolean = false;

  constructor(private projectService: ProjectService,
              private apiLocationGroupsService: ApiLocationGroupsService,
              private modalController: ModalController,
              private alertController: AlertController,
              private apiCcpService: ApiCcpService
  ){}

  ngOnInit(): void {
    this.getAllLocations();
    this.getGroups();
  }

  getAllLocations(){
    this.allLocations$.pipe(take(1)).subscribe(locations => {
      locations.map(loc => {
        this.controllers.push(...loc.controllers.$values);
      });
    });
  }

  getGroups(){
    this.apiLocationGroupsService.getLocationGroups().subscribe(groups => {
      this.groups = groups.filter((group)=> {
        return group.locationGroupTypeId === LocationGroupType.CustomGroup
      });
    });
  }

  setView(viewType: string) {
    this.viewType = viewType;
  }

  getControllerType(controller: Controller){
    return controller.controllerSettings.$values[0].value;
  }

  isControllerSelected(i: number){
    const cont = this.controllers.at(i);
    if(this.selectedControllers.includes(cont)){
      return true;
    }else{
      return false;
    }
  }

  onSelectController(index: number, event){
    var selected;
    if(event.detail.checked){
      selected = true;
    }else{
      selected = false;
    }
    let cont = this.controllers.at(index);
    if(selected){
      this.selectedControllers.push(cont);
    }else{
      const selectedIndex = this.selectedControllers.indexOf(cont);
      this.selectedControllers.splice(selectedIndex, 1);
    }
  }

  onSelectGroup(index: number, event){
    var selected;
    if(event.detail.checked){
      selected = true;
    }else{
      selected = false;
    }
    let group = this.groups.at(index);
    if(selected){
      this.selectedGroups.push(group);
    }else{
      const selectedIndex = this.selectedGroups.indexOf(group);
      this.selectedGroups.splice(selectedIndex, 1);
    }
  }

  onSelectAllControllers(event){
    if(event.detail.checked){
      this.allControllersSelected = true;
    }else{
      this.allControllersSelected = false;
    }
    if(this.allControllersSelected){
      this.selectedControllers.push(...this.controllers);
    }else{
      this.selectedControllers = [];
    }
  }

  onSelectAllGroups(event){
    if(event.detail.checked){
      this.allGroupsSelected = true;
    }else{
      this.allGroupsSelected = false;
    }
    if(this.allGroupsSelected){
      this.selectedGroups.push(...this.groups);
    }else{
      this.selectedGroups = [];
    }
  }

  showLocations(event: Event, group){
    if (event.type === 'mouseenter') {
      this.activePopup = group.id;
      if (!this.locationsToShowInPopup) {
        this.locationsToShowInPopup = group.locations;
      }
    } else if (event.type === 'mouseleave') {
      this.activePopup = undefined;
      this.locationsToShowInPopup = undefined;
    }
  }

  showLocationsPopup(id: string) {
    if (this.activePopup === id) {
      return true;
    }
  }

  onSaveAndSend(){
    this.sending = true;
    this.selectedControllers.forEach(cont => {
      this.controllerIdsToSend.push(cont.id);
    });
    this.selectedGroups.forEach(group => {
      group.locations.forEach(cont => {
        this.controllerIdsToSend.push(cont.locationId);
      });
    });

    this.selectedControllers.forEach(cont => {
      if(cont.controllerCommissioningParameters.$values.length > 0 ){
        // console.log("already has ccps: ", cont);
        this.controllersToOverwrite.push(cont.name);
      }
    });

    this.selectedGroups.forEach(group => {
      group.locations.map(loc => {
        this.controllersFromGroups.push(...loc.controllers.$values);
      });
    });
    this.apiCcpService.applyToAlLControllers(this.ccps, this.controllerIdsToSend, false, true).subscribe({
      next: (response: any) => {
          this.sending = false;
          this.modalController.dismiss();
      },
      error: (err) => {
        // this.sending = false;
        this.openOverwriteModal();
      }
    });
  }

  async openOverwriteModal() {
    const alert = await this.alertController.create({
      header: 'Conflict!',
      message: 'Overwrite the control commissioning parameters of these controllers: ' + this.controllersToOverwrite,
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          this.controllersToOverwrite = [];
          this.sending = false;
        }
      },
      {
        text: 'Overwrite',
        handler: () => {
          this.apiCcpService.applyToAlLControllers(this.ccps, this.controllerIdsToSend, true, false).subscribe({
            next: (response: any) => {
              this.controllersToOverwrite = [];
              this.modalController.dismiss();
            },
            error: (err) => {
              // this.openOverwriteModal();
            }
          });
        }
      }],
    });
    await alert.present();
   }

}
