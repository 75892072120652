import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { PermissionType } from 'src/app/core/models/permissions/permission.enum';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Role } from 'src/app/core/models/role.model';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'hasEveryAccessGuestRoomPermissionInRole'
})
export class HasEveryAccessGuestRoomPermissionInRolePipe implements PipeTransform {

  transform(user: User, allPermissions: Permission[], locations: Location[]): boolean {
    const accessGuestRoomPermissions = allPermissions.filter((perm) => {
      const location = locations.find( loc => loc.locationId === perm.locationId)
      if (location) {
        const isGuestRoom = Location.isGuestRoom(location);
        return perm.typeId === PermissionType.Unlock && isGuestRoom;
      }
    });
    if (accessGuestRoomPermissions.length === 0 ){
      return false;
    }

    const result =  user?.roles.some((role)=> {
      return Role.roleHasEveryPermission(role,accessGuestRoomPermissions)
    })
    return result
  }
}




