import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'propertiesAreActive',
})
export class PropertiesAreActivePipe implements PipeTransform {
  transform(properties: Property[], activeCondition: 'any' | 'every'): boolean {
    if (properties?.length === 0) {
      return false;
    }

    if (activeCondition === 'every') {
      return properties.every((prop) => {
        return Property.isActive(prop);
      });
    } else {
      return properties.some((prop) => {
        return Property.isActive(prop);
      });
    }
  }
}
