<div class="flex-row-space-evenly margin-top30 full-height">
  <div class="width90 flex-row">
    <div class="flex-column width12">
      <div *ngIf="controllers.length > 1" class="flex-column width100">
        <ion-list class="width-150">
          <ion-list-header>{{ "Controllers" | translate }}</ion-list-header>
          <ion-item button (click)="selectAllControllers()" [ngClass]="{
            'ion-list-activated':
            selectedControllers.length > 1
          }">{{'All' | translate}}</ion-item>
          <ion-item style="width: 80px;" button *ngFor="let controller of controllers" (click)="selectControllers(controller)" [ngClass]="{
              'ion-list-activated':
              selectedControllers[0].id === controller.id && selectedControllers.length === 1
            }">
            {{ controller.name }}
            <div [ngClass]="{'inactive-icon-small': !controller.isOnline}"></div>
          </ion-item>
        </ion-list>
      </div>

      <div class="flex-column width100">
        <ion-list class="flex-row">
          <ion-list-header>
            {{ "Tags" | translate }}
            <ion-icon *ngIf="signedInUser.havePermission(SoftwarePermissionId.LocationTags)"
              class="margin-left15 clickable" name="create-outline" (click)="editControllerTags($event)"></ion-icon>
          </ion-list-header>
        </ion-list>
        <div *ngFor="let tag of location.locationTags.$values">
          {{tag.tag}}
        </div>
      </div>
    </div>

    <!-- STATUSES -->
    <ng-container *ngIf="selectedControllers.length == 1;else moreControllers">
      <div class="flex-column width22">
        <div class="margin-bottom30">{{'STATUSES' | translate}}</div>

        <!-- DO NOT DISTURB -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isDndStatus"
          [activeText]="'Do not disturb on'" [inactiveText]="'Do not disturb off'" [iconActive]="'icon-forbidden'"
          [iconInactive]="'icon-forbidden'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- ROOM ARMED -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isRoomArmed"
          [activeText]="'Room armed'" [inactiveText]="'Room not armed'" [iconActive]="'icon-room-armed'"
          [iconInactive]="'icon-room-armed'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!--CARD DEPOSITED -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isCardDeposited"
          [activeText]="'Card deposited'" [inactiveText]="'Card not deposited'" [iconActive]="'icon-card'"
          [iconInactive]="'icon-card'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!--ROOM INSPECTED -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isInspectedStatus" [activeText]="'Room inspected'"
          [inactiveText]="'Room not inspected'" [iconActive]="'icon-inspect'" [iconInactive]="'icon-inspect'"
          [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!--ROOM CLEANED -->

        <app-modal-view-multiple-boolean-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isCleaningStatus" [activeText]="'Room cleaned'"
          [inactiveText]="'Room not cleaned'" [iconActive]="'icon-broom'" [iconInactive]="'icon-broom'"
          [forcePropIndex]="-1">
        </app-modal-view-multiple-boolean-prop>

          <!--ROOM HIGHEST CLEANING STATUS -->

        <app-modal-view-multiple-boolean-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isHighestCleaningStatus" [activeText]="'Room highest cleaned status'"
          [inactiveText]="'Room highest cleaned status'" [iconActive]="'icon-broom'" [iconInactive]="'icon-broom'"
          [forcePropIndex]="-1">
        </app-modal-view-multiple-boolean-prop>

        <!--MAKE UP ROOM -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isMakeUpRoom"
          [activeText]="'Make up room active'" [inactiveText]="'Make up room inactive'" [iconActive]="'icon-broom'"
          [iconInactive]="'icon-broom'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- NEW CARD -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isNewCard"
          [activeText]="'New card'" [inactiveText]="'No new card'" [iconActive]="'icon-card'"
          [iconInactive]="'icon-card'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!--CARD TYPE -->

        <ng-container *ngIf="controllers | findProperty: Property.isCardType: null : true as cardTypeProps">
          <div *ngFor="let cardTypeProp of cardTypeProps"
            class="status-container-small flex-row-center-v margin-bottom12 width230">
            <div class="icon-card icon-background-off font-size19"></div>
            <div class="status-text-off">
              {{ cardTypeProp.name | translate }} -
              {{ cardTypeProp.value | getCardTypeNameByCode: cardTypes }}
            </div>
          </div>
        </ng-container>


      </div>

      <!-- DOORS AND WINDOWS -->

      <div class="flex-column width22">
        <div class="margin-bottom30">
          {{'DOORS' | translate}} {{'AND' | translate}} {{'WINDOWS' | translate}}</div>

        <!-- WINDOWS -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isWindowOpened"
          [activeText]="'Window open'" [inactiveText]="'Window closed'" [iconActive]="'icon-window-open'"
          [iconInactive]="'icon-window-closed'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- DOORS -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isDoorOpened"
          [activeText]="'Door open'" [inactiveText]="'Door closed'" [iconActive]="'icon-door_opened_status'"
          [iconInactive]="'icon-door'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>
      </div>

      <!-- HVAC -->
      <div class="flex-column width22">
        <div class="margin-bottom30"> HVAC</div>

        <!-- ROOM TEMPERATURE CURRENT -->

        <app-modal-view-numeric-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isRoomTemperatureCurrent" [forcePropIndex]="-1">
        </app-modal-view-numeric-prop>

        <!-- ROOM TEMPERATURE SET -->

        <app-modal-view-numeric-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isRoomTemperatureSet" [forcePropIndex]="-1">
        </app-modal-view-numeric-prop>

        <!-- HVAC ACTIVE -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isHvacActive"
          [activeText]="'Hvac on'" [inactiveText]="'Hvac off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
          [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- HVAC HEATING/COOLING -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isHvacHeatCool"
          [activeText]="'Cooling mode'" [inactiveText]="'Heating mode'" [iconActive]="'icon-snowflake'"
          [iconInactive]="'icon-snowflake'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- HVAC HEAT COOL AUTO -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isHvacHeatCoolAuto" [activeText]="'Hvac Auto on'"
          [inactiveText]="'Hvac Auto off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
          [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- HVAC FANCOIL -->
        <app-modal-view-mutex-boolean-prop *ngIf="showHvacSection" [controllers]="selectedControllers"
          [mutexPropsFunc]="[Property.isFancoilV1, Property.isFancoilV2, Property.isFancoilV3]" [icon]="'icon-fan'"
          [text]="'Fancoil speed'">
        </app-modal-view-mutex-boolean-prop>

        <!-- HVAC FANCOIL V1 -->

        <!-- <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isFancoilV1"
      [activeText]="'Fancoil V1 on'"
      [inactiveText]="'Fancoil V1 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop> -->

        <!-- HVAC FANCOIL V2 -->

        <!-- <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isFancoilV2"
      [activeText]="'Fancoil V2 on'"
      [inactiveText]="'Fancoil V2 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop> -->

        <!-- HVAC FANCOIL V3 -->

        <!-- <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isFancoilV3"
      [activeText]="'Fancoil V3 on'"
      [inactiveText]="'Fancoil V3 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop> -->

        <!-- ROOM FLOOR HEATING -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isRoomFloorHeating" [activeText]="'Room floor heating on'"
          [inactiveText]="'Room floor heating off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
          [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- BATHROOM FLOOR HEATING -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isBathroomFloorHeating" [activeText]="'Bathroom floor heating on'"
          [inactiveText]="'Bathroom floor heating off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
          [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- BATHROOM CURRENT TEMPERATURE -->

        <app-modal-view-numeric-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isBathroomTemperatureCurrent" [forcePropIndex]="-1">
        </app-modal-view-numeric-prop>


        <!-- BATHROOM SET TEMPERATURE -->

        <app-modal-view-numeric-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isBathroomTemperatureSet" [forcePropIndex]="-1">
        </app-modal-view-numeric-prop>

        <!-- CO2 -->

        <app-modal-view-numeric-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isCO2Measurement" [sufix]="'ppm'" [icon]="'icon-co2'" [forcePropIndex]="-1">
        </app-modal-view-numeric-prop>

        <!-- CO2 -->

        <app-modal-view-numeric-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isHumidity"
          [sufix]="'%'" [icon]="'icon-humidity'" [forcePropIndex]="-1">
        </app-modal-view-numeric-prop>

        <!-- BATHROOM RADIATOR -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers"
          [propFilterFunction]="Property.isBathroomRadiator" [activeText]="'Bathroom radiator on'"
          [inactiveText]="'Bathroom radiator off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
          [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- ROOM RADIATOR -->

        <app-modal-view-boolean-prop [controllers]="selectedControllers" [propFilterFunction]="Property.isRoomRadiator"
          [activeText]="'Room radiator on'" [inactiveText]="'Room radiator off'" [iconActive]="'icon-temp'"
          [iconInactive]="'icon-temp'" [forcePropIndex]="-1">
        </app-modal-view-boolean-prop>

        <!-- RELAY OUTPUT PROPS -->

        <ng-container *ngIf="controllers | findProperty : Property.isRelayOutputStatus: null : true as relayOutputStatusProps">
          <ng-container *ngFor="let relayOutputStatusProp of relayOutputStatusProps">
            <div class="status-container-small flex-row-center-v margin-bottom12 width230">
              <!-- RELAY OUTPUT ON -->
              <ng-container *ngIf="relayOutputStatusProp | propIsActive; else relayOutputOff">

                  <div class="icon-settings icon-background-on font-size19"></div>
                  <div class="status-text-on">{{ relayOutputStatusProp| getRelayOutputStatusName | translate }} {{'on' | translate}}</div>
              </ng-container>
              <!-- RELAY OUTPUT OFF -->
              <ng-template #relayOutputOff>
                <div class="icon-settings icon-background-off font-size19"></div>
                <div class="status-text-off">{{ relayOutputStatusProp| getRelayOutputStatusName | translate }} {{'off' | translate}}</div>
              </ng-template>
            </div>
      </ng-container>
      </ng-container>

      <!-- DAIKIN -->
        <div *ngIf="selectedControllers | findProperty : Property.isDaikinUnitOnOff: null : true as props">
          <div *ngFor="let prop of props; let i=index">
            <div class="margin-bottom10">
              {{'Daikin unit' | translate}} {{i+1}}</div>
            <!-- DAIKIN UNIT ON /OFF -->
            <app-modal-view-boolean-prop [controllers]="selectedControllers"
              [propFilterFunction]="Property.isDaikinUnitOnOff" [activeText]="'Daikin unit on'"
              [inactiveText]="'Daikin unit off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
              [forcePropIndex]="i">
            </app-modal-view-boolean-prop>
            <!-- DAIKIN UNIT SET TEMPERATURE -->
            <app-modal-view-numeric-prop [controllers]="selectedControllers"
              [propFilterFunction]="Property.isDaikinUnitSetTemperature" [forcePropIndex]="i">
            </app-modal-view-numeric-prop>
            <!-- DAIKIN UNIT MEASURED TEMPERATURE -->
            <app-modal-view-numeric-prop [controllers]="selectedControllers"
              [propFilterFunction]="Property.isDaikinUnitMeasuredTemperature" [forcePropIndex]="i">
            </app-modal-view-numeric-prop>
            <!-- DAIKIN UNIT STATUS -->
         <!--    <app-daikin-unit-status [controllers]="selectedControllers" [forcePropIndex]="i">
            </app-daikin-unit-status> -->
            <app-display-dictionary-prop-in-room-modal
            [controllers]="selectedControllers"
            [forcePropIndex]="i"
            [findPropFunction]="Property.isDaikinUnitStatus"
            [icon]="'icon-temp'"
            [dictionary]="daikinUnitStatusDictionary"
            >
            </app-display-dictionary-prop-in-room-modal>
            <!-- DAIKIN UNIT FAN SPEED -->
        <!--     <app-daikin-unit-fan-speed [controllers]="selectedControllers" [forcePropIndex]="i">
            </app-daikin-unit-fan-speed> -->
            <app-display-dictionary-prop-in-room-modal
            [controllers]="selectedControllers"
            [forcePropIndex]="i"
            [findPropFunction]="Property.isDaikinUnitFanSpeed"
            [icon]="'icon-fan'"
            [dictionary]="daikinUnitFanSpeedDictionary"
            >
            </app-display-dictionary-prop-in-room-modal>
            <!-- DAIKIN UNIT OPERATION MODE -->
         <!--    <app-daikin-unit-operation-mode [controllers]="selectedControllers" [forcePropIndex]="i">
            </app-daikin-unit-operation-mode> -->
            <app-display-dictionary-prop-in-room-modal
            [controllers]="selectedControllers"
            [forcePropIndex]="i"
            [findPropFunction]="Property.isDaikinUnitMode"
            [icon]="'icon-temp'"
            [dictionary]="daikinUnitOperationModeDictionary"
            >
            </app-display-dictionary-prop-in-room-modal>
          </div>
        </div>

        <!-- LG -->
        <div *ngIf="selectedControllers | findProperty : Property.isLgUnitOnOff: null : true as props">
          <div *ngFor="let prop of props; let i=index">
            <div class="margin-bottom10">
              {{'Lg unit' | translate}} {{i+1}}</div>
            <!-- LG UNIT ON /OFF -->
            <app-modal-view-boolean-prop [controllers]="selectedControllers"
              [propFilterFunction]="Property.isLgUnitOnOff" [activeText]="'Lg unit on'"
              [inactiveText]="'Lg unit off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
              [forcePropIndex]="i">
            </app-modal-view-boolean-prop>
            <!-- LG UNIT SET TEMPERATURE -->
            <app-modal-view-numeric-prop [controllers]="selectedControllers"
              [propFilterFunction]="Property.isLgUnitSetTemperature" [forcePropIndex]="i">
            </app-modal-view-numeric-prop>
            <!-- LG UNIT MEASURED TEMPERATURE -->
            <app-modal-view-numeric-prop [controllers]="selectedControllers"
              [propFilterFunction]="Property.isLgUnitMeasuredTemperature" [forcePropIndex]="i">
            </app-modal-view-numeric-prop>
            <!-- LG UNIT STATUS -->
           <!--  <app-daikin-unit-status [controllers]="selectedControllers" [forcePropIndex]="i">
            </app-daikin-unit-status> -->
            <app-display-dictionary-prop-in-room-modal
            [controllers]="selectedControllers"
            [forcePropIndex]="i"
            [findPropFunction]="Property.isLgUnitStatus"
            [icon]="'icon-temp'"
            [dictionary]="lgUnitStatusDictionary"
            >
            </app-display-dictionary-prop-in-room-modal>
            <!-- LG UNIT FAN SPEED -->
         <!--    <app-daikin-unit-fan-speed [controllers]="selectedControllers" [forcePropIndex]="i">
            </app-daikin-unit-fan-speed> -->
            <app-display-dictionary-prop-in-room-modal
            [controllers]="selectedControllers"
            [forcePropIndex]="i"
            [findPropFunction]="Property.isLgUnitFanSpeed"
            [icon]="'icon-fan'"
            [dictionary]="lgUnitFanSpeedDictionary"
            >
            </app-display-dictionary-prop-in-room-modal>
            <!-- LG UNIT OPERATION MODE -->
         <!--    <app-daikin-unit-operation-mode [controllers]="selectedControllers" [forcePropIndex]="i">
            </app-daikin-unit-operation-mode> -->
            <app-display-dictionary-prop-in-room-modal
            [controllers]="selectedControllers"
            [forcePropIndex]="i"
            [findPropFunction]="Property.isLgUnitOperationMode"
            [icon]="'icon-temp'"
            [dictionary]="lgUnitOperationModeDictionary"
            >
            </app-display-dictionary-prop-in-room-modal>
          </div>
        </div>

        <!--VRV -->

        <ng-container *ngIf="
          controllers | findProperty : Property.isVrvOnOff: null : true as vrvOnOffProps
        ">
          <ng-container *ngIf="
            controllers | findProperty : Property.isVrvSpeed: null : true as vrvSpeedProps
          ">
            <ng-container *ngIf="vrvOnOffProps.length > 0">
              <div class="status-container-small flex-row-center-v margin-bottom12 width230">
                <!-- VRV ON -->
                <ng-container *ngIf="vrvOnOffProps[0] | propIsActive; else vrvOff">
                  <!-- VRV HIGH -->
                  <ng-container *ngIf="vrvSpeedProps | propIsActive; else vrvLow">
                    <div class="icon-fan icon-background-on font-size19"></div>
                    <div class="status-text-on">{{ "Vrv high" | translate }}</div>
                  </ng-container>
                  <!-- VRV LOW -->
                  <ng-template #vrvLow>
                    <div class="icon-fan icon-background-on font-size19"></div>
                    <div class="status-text-on">{{ "Vrv low" | translate }}</div>
                  </ng-template>
                </ng-container>
                <!-- VRV OFF -->
                <ng-template #vrvOff>
                  <div class="icon-fan icon-background-off font-size19"></div>
                  <div class="status-text-off">{{ "Vrv off" | translate }}</div>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #moreControllers>
      <div class="flex-column width22">
        <div class="margin-bottom30">{{'STATUSES' | translate}}</div>


        <div *ngFor="let controller of selectedControllers">

          <div>
            {{controller.name}}
          </div>
          <!-- DO NOT DISTURB -->
          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isDndStatus"
            [activeText]="'Do not disturb on'" [inactiveText]="'Do not disturb off'" [iconActive]="'icon-forbidden'"
            [iconInactive]="'icon-forbidden'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!-- ROOM ARMED -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isRoomArmed"
            [activeText]="'Room armed'" [inactiveText]="'Room not armed'" [iconActive]="'icon-room-armed'"
            [iconInactive]="'icon-room-armed'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!--CARD DEPOSITED -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isCardDeposited"
            [activeText]="'Card deposited'" [inactiveText]="'Card not deposited'" [iconActive]="'icon-card'"
            [iconInactive]="'icon-card'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!--ROOM INSPECTED -->

            <app-modal-view-boolean-prop [controllers]="[controller]"
            [propFilterFunction]="Property.isInspectedStatus" [activeText]="'Room inspected'"
            [inactiveText]="'Room not inspected'" [iconActive]="'icon-inspect'" [iconInactive]="'icon-inspect'"
            [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!--ROOM CLEANED -->

          <app-modal-view-multiple-boolean-prop [controllers]="[controller]"
            [propFilterFunction]="Property.isCleaningStatus" [activeText]="'Room cleaned'"
            [inactiveText]="'Room not cleaned'" [iconActive]="'icon-broom'" [iconInactive]="'icon-broom'"
            [forcePropIndex]="-1">
          </app-modal-view-multiple-boolean-prop>

            <!--ROOM HIGHEST CLEANING STATUS -->

          <app-modal-view-multiple-boolean-prop [controllers]="[controller]"
            [propFilterFunction]="Property.isHighestCleaningStatus" [activeText]="'Room highest cleaned status'"
            [inactiveText]="'Room highest cleaned status'" [iconActive]="'icon-broom'" [iconInactive]="'icon-broom'"
            [forcePropIndex]="-1">
          </app-modal-view-multiple-boolean-prop>

          <!--MAKE UP ROOM -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isMakeUpRoom"
            [activeText]="'Make up room active'" [inactiveText]="'Make up room inactive'" [iconActive]="'icon-broom'"
            [iconInactive]="'icon-broom'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!-- NEW CARD -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isNewCard"
            [activeText]="'New card'" [inactiveText]="'No new card'" [iconActive]="'icon-card'"
            [iconInactive]="'icon-card'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!--CARD TYPE -->

          <ng-container *ngIf="[controller] | findProperty: Property.isCardType: null : true as cardTypeProps">
            <div *ngFor="let cardTypeProp of cardTypeProps"
              class="status-container-small flex-row-center-v margin-bottom12 width230">
              <div class="icon-card icon-background-off font-size19"></div>
              <div class="status-text-off">
                {{ cardTypeProp.name | translate }} -
                {{ cardTypeProp.value | getCardTypeNameByCode: cardTypes }}
              </div>
            </div>
          </ng-container>
        </div>

      </div>

      <!-- DOORS AND WINDOWS -->

      <div class="flex-column width22">
        <div class="margin-bottom30">
          {{'DOORS' | translate}} {{'AND' | translate}} {{'WINDOWS' | translate}}</div>

        <div *ngFor="let controller of selectedControllers">

          <div>
            {{controller.name}}
          </div>

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isWindowOpened"
            [activeText]="'Window open'" [inactiveText]="'Window closed'" [iconActive]="'icon-window-open'"
            [iconInactive]="'icon-window-closed'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!-- DOORS -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isDoorOpened"
            [activeText]="'Door open'" [inactiveText]="'Door closed'" [iconActive]="'icon-door_opened_status'"
            [iconInactive]="'icon-door'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>
        </div>
        <!-- WINDOWS -->
      </div>

      <!-- HVAC -->
      <div class="flex-column width22">
        <div class="margin-bottom30"  *ngIf="showHvacSection"> HVAC</div>

        <div *ngFor="let controller of selectedControllers">
          <div *ngIf="showHvacSection">
            {{controller.name}}
          </div>

          <!-- ROOM TEMPERATURE CURRENT -->

          <app-modal-view-numeric-prop [controllers]="[controller]"
            [propFilterFunction]="Property.isRoomTemperatureCurrent" [forcePropIndex]="-1">
          </app-modal-view-numeric-prop>

          <!-- ROOM TEMPERATURE SET -->

          <app-modal-view-numeric-prop [controllers]="[controller]"
            [propFilterFunction]="Property.isRoomTemperatureSet" [forcePropIndex]="-1">
          </app-modal-view-numeric-prop>

          <!-- HVAC ACTIVE -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isHvacActive"
            [activeText]="'Hvac on'" [inactiveText]="'Hvac off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
            [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!-- HVAC HEATING/COOLING -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isHvacHeatCool"
            [activeText]="'Cooling mode'" [inactiveText]="'Heating mode'" [iconActive]="'icon-snowflake'"
            [iconInactive]="'icon-snowflake'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!-- HVAC HEAT COOL AUTO -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isHvacHeatCoolAuto"
            [activeText]="'Hvac Auto on'" [inactiveText]="'Hvac Auto off'" [iconActive]="'icon-temp'"
            [iconInactive]="'icon-temp'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>


          <!-- HVAC FANCOIL  -->
          <app-modal-view-mutex-boolean-prop *ngIf="showHvacSection" [controllers]="[controller]"
            [mutexPropsFunc]="[Property.isFancoilV1, Property.isFancoilV2,
            Property.isFancoilV3, Property.isFancoilVAuto]" [icon]="'icon-fan'"
            [text]="'Fancoil speed'">
          </app-modal-view-mutex-boolean-prop>

          <!-- HVAC FANCOIL V1 -->

          <!-- <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isFancoilV1"
      [activeText]="'Fancoil V1 on'"
      [inactiveText]="'Fancoil V1 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop> -->

          <!-- HVAC FANCOIL V2 -->
          <!--
      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isFancoilV2"
      [activeText]="'Fancoil V2 on'"
      [inactiveText]="'Fancoil V2 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop> -->

          <!-- HVAC FANCOIL V3 -->

          <!-- <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isFancoilV3"
      [activeText]="'Fancoil V3 on'"
      [inactiveText]="'Fancoil V3 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop> -->

          <!-- ROOM FLOOR HEATING -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isRoomFloorHeating"
            [activeText]="'Room floor heating on'" [inactiveText]="'Room floor heating off'" [iconActive]="'icon-temp'"
            [iconInactive]="'icon-temp'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!-- BATHROOM CURRENT TEMPERATURE -->

          <app-modal-view-numeric-prop [controllers]="[controller]"
            [propFilterFunction]="Property.isBathroomTemperatureCurrent" [forcePropIndex]="-1">
          </app-modal-view-numeric-prop>


          <!-- BATHROOM SET TEMPERATURE -->

          <app-modal-view-numeric-prop [controllers]="[controller]"
            [propFilterFunction]="Property.isBathroomTemperatureSet" [forcePropIndex]="-1">
          </app-modal-view-numeric-prop>

          <!-- CO2 -->

          <app-modal-view-numeric-prop [controllers]="[controller]" [propFilterFunction]="Property.isCO2Measurement"
            [sufix]="'ppm'" [forcePropIndex]="-1">
          </app-modal-view-numeric-prop>

          <!-- HUMIDITY -->

          <app-modal-view-numeric-prop [controllers]="[controller]" [propFilterFunction]="Property.isHumidity"
            [sufix]="'%'" [forcePropIndex]="-1">
          </app-modal-view-numeric-prop>

          <!-- BATHROOM RADIATOR -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isBathroomRadiator"
            [activeText]="'Bathroom radiator on'" [inactiveText]="'Bathroom radiator off'" [iconActive]="'icon-temp'"
            [iconInactive]="'icon-temp'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!-- ROOM RADIATOR -->

          <app-modal-view-boolean-prop [controllers]="[controller]" [propFilterFunction]="Property.isRoomRadiator"
            [activeText]="'Room radiator on'" [inactiveText]="'Room radiator off'" [iconActive]="'icon-temp'"
            [iconInactive]="'icon-temp'" [forcePropIndex]="-1">
          </app-modal-view-boolean-prop>

          <!-- DAIKIN -->
          <div *ngIf="[controller] | findProperty : Property.isDaikinUnitOnOff: null : true as props">
            <div *ngFor="let prop of props; let i=index">
              <!-- DAIKIN UNIT ON /OFF -->
              <app-modal-view-boolean-prop [controllers]="[controller]"
                [propFilterFunction]="Property.isDaikinUnitOnOff" [activeText]="'Daikin unit on'"
                [inactiveText]="'Daikin unit off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
                [forcePropIndex]="i">
              </app-modal-view-boolean-prop>
              <!-- DAIKIN UNIT SET TEMPERATURE -->
              <app-modal-view-numeric-prop [controllers]="[controller]"
                [propFilterFunction]="Property.isDaikinUnitSetTemperature" [forcePropIndex]="i">
              </app-modal-view-numeric-prop>
              <!-- DAIKIN UNIT MEASURED TEMPERATURE -->
              <app-modal-view-numeric-prop [controllers]="[controller]"
                [propFilterFunction]="Property.isDaikinUnitMeasuredTemperature" [forcePropIndex]="i">
              </app-modal-view-numeric-prop>
              <!-- DAIKIN UNIT STATUS -->
      <!--         <app-daikin-unit-status [controllers]="[controller]" [forcePropIndex]="i">
              </app-daikin-unit-status> -->
              <app-display-dictionary-prop-in-room-modal
                [controllers]="selectedControllers"
                [forcePropIndex]="i"
                [findPropFunction]="Property.isDaikinUnitStatus"
                [icon]="'icon-temp'"
                [dictionary]="daikinUnitStatusDictionary"
             >
              </app-display-dictionary-prop-in-room-modal>
              <!-- DAIKIN UNIT FAN SPEED -->
       <!--        <app-daikin-unit-fan-speed [controllers]="[controller]" [forcePropIndex]="i">
              </app-daikin-unit-fan-speed> -->
              <app-display-dictionary-prop-in-room-modal
            [controllers]="[controller]"
            [forcePropIndex]="i"
            [findPropFunction]="Property.isDaikinUnitFanSpeed"
            [icon]="'icon-fan'"
            [dictionary]="daikinUnitFanSpeedDictionary"
            >
            </app-display-dictionary-prop-in-room-modal>
               <!-- DAIKIN UNIT OPERATION MODE -->
     <!--        <app-daikin-unit-operation-mode [controllers]="[controller]" [forcePropIndex]="i">
            </app-daikin-unit-operation-mode> -->
            <app-display-dictionary-prop-in-room-modal
            [controllers]="[controller]"
            [forcePropIndex]="i"
            [findPropFunction]="Property.isDaikinUnitMode"
            [icon]="'icon-temp'"
            [dictionary]="daikinUnitOperationModeDictionary"
            >
            </app-display-dictionary-prop-in-room-modal>
            </div>
          </div>

            <!-- Lg -->
            <div *ngIf="[controller] | findProperty : Property.isLgUnitOnOff: null : true as props">
              <div *ngFor="let prop of props; let i=index">
                <!-- Lg UNIT ON /OFF -->
                <app-modal-view-boolean-prop [controllers]="[controller]"
                  [propFilterFunction]="Property.isLgUnitOnOff" [activeText]="'Lg unit on'"
                  [inactiveText]="'Lg unit off'" [iconActive]="'icon-temp'" [iconInactive]="'icon-temp'"
                  [forcePropIndex]="i">
                </app-modal-view-boolean-prop>
                <!-- Lg UNIT SET TEMPERATURE -->
                <app-modal-view-numeric-prop [controllers]="[controller]"
                  [propFilterFunction]="Property.isLgUnitSetTemperature" [forcePropIndex]="i">
                </app-modal-view-numeric-prop>
                <!-- Lg UNIT MEASURED TEMPERATURE -->
                <app-modal-view-numeric-prop [controllers]="[controller]"
                  [propFilterFunction]="Property.isLgUnitMeasuredTemperature" [forcePropIndex]="i">
                </app-modal-view-numeric-prop>
                <!-- Lg UNIT STATUS -->

                <app-display-dictionary-prop-in-room-modal
                  [controllers]="selectedControllers"
                  [forcePropIndex]="i"
                  [findPropFunction]="Property.isLgUnitStatus"
                  [icon]="'icon-temp'"
                  [dictionary]="lgUnitStatusDictionary"
               >
                </app-display-dictionary-prop-in-room-modal>
                <!-- Lg UNIT FAN SPEED -->

                <app-display-dictionary-prop-in-room-modal
              [controllers]="[controller]"
              [forcePropIndex]="i"
              [findPropFunction]="Property.isLgUnitFanSpeed"
              [icon]="'icon-fan'"
              [dictionary]="lgUnitFanSpeedDictionary"
              >
              </app-display-dictionary-prop-in-room-modal>
                 <!-- Lg UNIT OPERATION MODE -->

              <app-display-dictionary-prop-in-room-modal
              [controllers]="[controller]"
              [forcePropIndex]="i"
              [findPropFunction]="Property.isLgUnitOperationMode"
              [icon]="'icon-temp'"
              [dictionary]="lgUnitOperationModeDictionary"
              >
              </app-display-dictionary-prop-in-room-modal>
              </div>
            </div>

          <!--VRV -->

          <ng-container *ngIf="
        [controller] | findProperty : Property.isVrvOnOff: null : true as vrvOnOffProps
        ">
            <ng-container *ngIf="
          [controller]
           | findProperty : Property.isVrvSpeed: null : true as vrvSpeedProps
          ">
              <ng-container *ngIf="vrvOnOffProps.length > 0">
                <div class="status-container-small flex-row-center-v margin-bottom12 width230">
                  <!-- VRV ON -->
                  <ng-container *ngIf="vrvOnOffProps[0] | propIsActive; else vrvOff">
                    <!-- VRV HIGH -->
                    <ng-container *ngIf="vrvSpeedProps | propIsActive; else vrvLow">
                      <div class="icon-fan icon-background-on font-size19"></div>
                      <div class="status-text-on">{{ "Vrv high" | translate }}</div>
                    </ng-container>
                    <!-- VRV LOW -->
                    <ng-template #vrvLow>
                      <div class="icon-fan icon-background-on font-size19"></div>
                      <div class="status-text-on">{{ "Vrv low" | translate }}</div>
                    </ng-template>
                  </ng-container>
                  <!-- VRV OFF -->
                  <ng-template #vrvOff>
                    <div class="icon-fan icon-background-off font-size19"></div>
                    <div class="status-text-off">{{ "Vrv off" | translate }}</div>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>

      </div>
    </ng-template>


    <div class="flex-column width22">

      <!-- ALARM BOX RED  -->

      <div class="alarm-box-red">
        <div class="flex-row-center-v margin-bottom5 width100">
          <div class="icon-alert font-size20 red"></div>
          <div class="margin-left20 margin-top5"> {{'Alarms' | translate}}</div>
        </div>

        <ion-button color="dark" size="small" fill="outline" expand="block"
          class="margin-bottom5 align-self-center clear-btn" (click)="clearStatus()">{{'Clear' |
          translate}}</ion-button>
        <ng-container *ngIf="
          (selectedControllers | findProperty: Property.isSOS: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isBurglary: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isSafeBurglary: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isFlood: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isBurglaryDetectedDoor: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isBurglaryDetectedWindow: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isRoomBurglaryPIR: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isFire: null : true | propIsActive)
        ">
          <div class="flex-column ">
            <div *ngIf="selectedControllers | findProperty: Property.isBurglary: null : true | propIsActive">
              <div class="prop-title">{{'Burglary detected' | translate}}</div>
            </div>
            <ng-container *ngIf="selectedControllers | findProperty: Property.isSOS: null : true as sosProps">
              <ng-container *ngFor="let sos of sosProps">
                <div *ngIf="sos | propIsActive">
                  <div class="prop-title">{{ sos.name }}</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedControllers| findProperty: Property.isSafeBurglary: null : true as safeBurglaryProps">
              <ng-container *ngFor="let safeBurglaryProp of safeBurglaryProps">
                <div *ngIf="safeBurglaryProp | propIsActive">
                  <div class="prop-title">{{ getAlarmName(safeBurglaryProp) | translate}}</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedControllers | findProperty: Property.isFlood: null : true as floodProps">
              <ng-container *ngFor="let floodProp of floodProps">
                <div *ngIf="floodProp | propIsActive">
                  <div class="prop-title">{{ getAlarmName(floodProp) | translate}}</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="selectedControllers | findProperty: Property.isBurglaryDetectedDoor: null : true as isBurglaryDetectedDoorProps">
              <ng-container *ngFor="
                    let burglaryDetectedDoorProp of isBurglaryDetectedDoorProps
                  ">
                <div *ngIf="burglaryDetectedDoorProp | propIsActive">
                  <div class="prop-title">
                    {{ getAlarmName(burglaryDetectedDoorProp) | translate}}
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="selectedControllers | findProperty: Property.isBurglaryDetectedWindow: null : true as burglaryDetectedWindowProps">
              <ng-container *ngFor="
                    let burglaryDetectedWindowProp of burglaryDetectedWindowProps
                  ">
                <div *ngIf="burglaryDetectedWindowProp | propIsActive">
                  <div class="prop-title">
                    {{ getAlarmName(burglaryDetectedWindowProp) | translate}}
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="selectedControllers | findProperty: Property.isRoomBurglaryPIR: null : true as roomBurglaryPirProps">
              <ng-container *ngFor="let roomBurglaryPirProp of roomBurglaryPirProps">
                <div *ngIf="roomBurglaryPirProp | propIsActive">
                  <div class="prop-title">{{ getAlarmName(roomBurglaryPirProp) | translate}}</div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedControllers | findProperty: Property.isFire: null : true as fireProps">
              <ng-container *ngFor="let fireProp of fireProps">
                <div *ngIf="fireProp | propIsActive">
                  <div class="prop-title">{{ fireProp.name }}</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- WARNING BOX YELLOW  -->
      <div class="alarm-box-yellow">
        <div class="flex-row-center-v margin-bottom5 width100">
          <div class="icon-alert font-size20 yellow"></div>
          <div class="margin-left20 margin-top5"> {{'Warnings' | translate}}</div>
        </div>

        <div class="flex-row margin-left20" *ngIf="
          (selectedControllers | findProperty: Property.isHVACLargeTempDiffRoom: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isDoorLockBatteryLow: null  : true| propIsActive) ||
          (selectedControllers | findProperty: Property.isControllerTempHigh: null : true | propIsActive) ||
          ((selectedControllers | findProperty: Property.isWindowOpenTooLongAlarm: null : true | propIsActive) && !(location | locationHasTag: 'low window')) ||
          (selectedControllers | findProperty: Property.isDoorOpenTooLongAlarm: null  : true| propIsActive) ||
          (selectedControllers | findProperty: Property.isSafeCommError: null : true | propIsActive) ||
          ((selectedControllers | findProperty: Property.isLowWindowOpenTooLong: null : true | propIsActive) && (location | locationHasTag: 'low window')) ||
          (selectedControllers | findProperty: Property.isFuse: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isPowerOutage: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isWind: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isCO2: null : true | propIsActive) ||
          (selectedControllers | findProperty: Property.isHVACLargeTempDiffBathroom: null : true | propIsActive)
        ">

          <ng-container *ngIf="controllers | findProperty: Property.isHVACLargeTempDiffRoom: null : true as hvacLargeTempDiffRoomProps">
            <ng-container *ngFor="let hvacLargeTempDiffRoomProp of hvacLargeTempDiffRoomProps">
              <div *ngIf="hvacLargeTempDiffRoomProp | propIsActive">
                <div class="prop-title">{{ getAlarmName(hvacLargeTempDiffRoomProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="controllers | findProperty: Property.isDoorLockBatteryLow: null : true as doorLockBatteryLowProps">
            <ng-container *ngFor="let doorLockBatteryLowProp of doorLockBatteryLowProps">
              <div *ngIf="doorLockBatteryLowProp | propIsActive">
                <div class="prop-title">{{ getAlarmName(doorLockBatteryLowProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="selectedControllers | findProperty: Property.isControllerTempHigh: null : true as contTemperatureHighProps">
            <ng-container *ngFor="let contTempProp of contTemperatureHighProps">
              <div *ngIf="contTempProp | propIsActive">
                <div class="prop-title">{{ contTempProp.name }}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="selectedControllers | findProperty: Property.isWindowOpenTooLongAlarm: null : true as windowOpenTooLongProps">
            <ng-container *ngFor="let windowOpenTooLongProp of windowOpenTooLongProps">
              <div *ngIf="(windowOpenTooLongProp | propIsActive)  && !(location | locationHasTag: 'low window')">
                <div class="prop-title">
                  {{ getAlarmName(windowOpenTooLongProp) | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="selectedControllers| findProperty: Property.isDoorOpenTooLongAlarm: null : true as doorOpenTooLongProps">
            <ng-container *ngFor="let doorOpenTooLongProp of doorOpenTooLongProps">
              <div *ngIf="doorOpenTooLongProp | propIsActive">
                <div class="prop-title">
                  {{ getAlarmName(doorOpenTooLongProp) | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selectedControllers | findProperty: Property.isSafeCommError: null : true as safeCommErrorProps">
            <ng-container *ngFor="let safeCommErrorProp of safeCommErrorProps">
              <div *ngIf="safeCommErrorProp | propIsActive">
                <div class="prop-title">{{ getAlarmName(safeCommErrorProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="selectedControllers | findProperty: Property.isLowWindowOpenTooLong: null : true as lowWinOpenTooLongProps">
            <ng-container *ngFor="let lowWinOpenTooLongProp of lowWinOpenTooLongProps">
              <div *ngIf="(lowWinOpenTooLongProp | propIsActive) && (location | locationHasTag: 'low window')">
                <div class="prop-title">{{ getAlarmName(lowWinOpenTooLongProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selectedControllers | findProperty: Property.isFuse: null : true as fuseProps">
            <ng-container *ngFor="let fuseProp of fuseProps">
              <div *ngIf="fuseProp | propIsActive">
                <div class="prop-title">{{ fuseProp.name | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selectedControllers | findProperty: Property.isPowerOutage: null : true as powerOutageProps">
            <ng-container *ngFor="let powerOutageProp of powerOutageProps">
              <div *ngIf="powerOutageProp | propIsActive">
                <div class="prop-title">{{ powerOutageProp.name }}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selectedControllers | findProperty: Property.isWind: null : true as windProps">
            <ng-container *ngFor="let windProp of windProps">
              <div *ngIf="windProp | propIsActive">
                <div class="prop-title">{{ windProp.name }}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selectedControllers | findProperty: Property.isCO2: null : true as co2Props">
            <ng-container *ngFor="let co2Prop of co2Props">
              <div *ngIf="co2Prop | propIsActive">
                <div class="prop-title">{{ co2Prop.name }}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="controllers| findProperty: Property.isHVACLargeTempDiffBathroom: null : true as hvacLargeTempDiffBathroomProps">
            <ng-container *ngFor="let hvacLargeTempDiffBathroomProp of hvacLargeTempDiffBathroomProps">
              <div *ngIf="hvacLargeTempDiffBathroomProp | propIsActive">
                <div class="prop-title">{{ getAlarmName(hvacLargeTempDiffBathroomProp) | translate}}</div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>


  </div>

</div>
<!-- <app-d3-room [controller]="mainController"></app-d3-room> -->
