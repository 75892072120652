<div *ngIf="!roomSwap">
<div class="content-container">
  <div class="flex-row-center-full">
    <ion-spinner *ngIf="!tablesReady" class="flex-row-center-full" name="lines-sharp"></ion-spinner>
  </div>
  <div *ngIf="tablesReady" class="flex-row margin-left200 margin-top20 width75">
        <div class="margin-bottom30 uppercase">{{'Access Cards' |translate}}</div>
          <table *ngIf="guestCards.length > 0">
            <tr>
              <th class="uppercase bg-white">{{'Valid from' | translate}}</th>
              <th class="uppercase bg-white">{{'Valid to' | translate}}</th>
              <th class="uppercase bg-white">{{'User' | translate}}</th>
              <th class="uppercase bg-white">{{'Additional passages' | translate}}</th>
              <th class="uppercase bg-white">{{'Cards' | translate}}</th>
            </tr>
            <ng-container *ngIf="{data : lastGuestCardOnHolder$ | async } as guestCardOnHolderData">
              <tr *ngFor="let card of guestCards; let i = index" class="br-gray-b"
              [ngClass]="{'selected': (guestCardOnHolderData?.data?.uid === card?.uid), 'inactive-card': card.validFrom | getValidFromToCss}">
                <td class=" ">
                  <div class="flex-row-center">
                    <span>{{ card.validFrom | date: "dd.MM.yyyy." }}</span>
                    <span>{{ card.validFrom | date: " - HH:mm" }}</span>
                  </div>
                </td>
                <td class=" ">
                  <div class="flex-row-center" [ngClass]="card.validTo | getValidToCssClass">
                    <span>{{ card.validTo | date: "dd.MM.yyyy." }}</span>
                    <span>{{ card.validTo | date: " - HH:mm" }}</span>
                  </div>
                </td>
                <td class=" ">{{ card.user.fullName }}</td>
                <td class=" ">
                  <ion-button
                  (mouseenter)="showAccess($event, card)"
                  (mouseleave)="showAccess($event, card)"
                  color="primary"
                  class="margin-right5 ion-no-border"
                  fill="outline"
                  size="small"
                  >{{'Show' | translate}}
                </ion-button>
                <div *ngIf="showAccessPopup(card.uid)" class="trigger" [@enterAnimation]>
                  <div class="popup">
                    <div *ngIf="!userToShowInPopup" class="inner">
                      <div class="lds-dual-ring"></div>
                    </div>
                    <div *ngIf="userToShowInPopup" class="inner flex-row">
                      <div *ngFor="let loc of userToShowInPopup.permissions; let i = index;" class="flex-row margin-all5">
                        <div class="br-b">{{loc.locationName}}</div>
                        <div *ngIf="userToShowInPopup.permissions.length !== i+1">,</div>
                      </div>
                      <div *ngIf="userToShowInPopup.permissions.length === 0" class="width100">
                        <div class="text-align-center">{{'Empty' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                </td>
                <td>
                  <ion-button
                  *ngIf="signedInUser | userHasPermission : swPermissions.CardsGuestUpdate"
                  (click)="deleteGuest(card.user)"
                  color="danger"
                  class="margin-right5"
                  fill="outline"
                  size="small">
                    <ion-icon name="close-outline"></ion-icon>
                  </ion-button>

                  <ion-button
                  (click)="syncGuest(card.uid)"
                  color="warning"
                  fill="outline"
                  class="margin-right5"
                  [disabled]="cardSyncActive"
                  size="small">
                    <ion-icon name="sync"></ion-icon>
                  </ion-button>

                  <ion-button
                  *ngIf="(signedInUser | userHasPermission : swPermissions.CardsGuestUpdate)"
                  (click)="editCard(card.user.userId)"
                  color="primary"
                  fill="outline"
                  size="small">
                    <ion-icon name="create"></ion-icon>
                  </ion-button>
                </td>
              </tr>
            </ng-container>
          </table>
          <h3 *ngIf="guestCards.length === 0" class="flex-row-center-h margin-top50">
            {{'No cards for access control' | translate}}
          </h3>
        </div>
        <!-- <div *ngIf="signedInUser | userHasPermission : swPermissions.CardsStaffUpdate" class="flex-row backup-btn">
          <ion-button class="to-right" color="light" (click)="createBackupCard()">{{'Create backup card' | translate}}</ion-button>
        </div> -->
</div>
</div>


<app-modal-confirmation
    [type]="'cardholder'"
    [content]=""
    (decision)="onCardOnreaderModalDecision($event)"
    *ngIf="openCardOnReaderModal">
</app-modal-confirmation>

<app-modal-confirmation
    [type]="'cardExist'"
    [content]=""
    (decision)="onModalDecisionCardExist($event)"
    *ngIf="cardExist">
</app-modal-confirmation>
