import { Component, Input, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { combineLatest, take } from 'rxjs';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { Property } from 'src/app/core/models/project/property.model';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';
import { LocationGroup } from 'src/app/shared/services/api-location-groups.service';
import { DemoModeService } from 'src/app/shared/services/demo-mode.service';
import { DEMO_MODE } from 'src/environments/environment';

@Component({
  selector: 'app-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss']
})
export class GroupSettingsComponent implements OnInit {
  @Input() selectedGroup: LocationGroup;
  defaultPresets: DefaultsPreset[];
  possiblePresetProps: Property [];
  targetProps
  targetEquipmentRelayPropsOptions
  targetEquipmentRelayPropValues = [];
  loading = false;
  signedInUser: User;
  $allLocations = this.projectService.getAllLocations();


  constructor(
    private apiSettingsService: ApiSettingsService,
    private apiProjectService: ApiProjectService,
    private currentUserStoreService: CurrentUserStoreService,
    private toastController: ToastController,
    private pipe: CustomTranslatePipe,
    private demoModeService: DemoModeService,
    private projectService: ProjectService

    ) { }

  ngOnInit(): void {
    combineLatest([
      this.apiSettingsService.getDefaultPresets(),
      this.apiSettingsService.getPresetProps(),
    ]).
        subscribe(([defaults, presetProps])=> {
          this.defaultPresets = defaults;
          this.possiblePresetProps = presetProps;
          this.targetProps = this.combinePropsWithDefaults([])[0];
          this.targetEquipmentRelayPropsOptions = this.combinePropsWithDefaults([])[1]
        })
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
    if (DEMO_MODE) {
      this.demoModeService.getData().subscribe( value => {
        this.defaultPresets = value.Presets;
        this.possiblePresetProps = value.PresetProps
        this.targetProps =this.combinePropsWithDefaults([])[0];
        this.targetEquipmentRelayPropsOptions = this.combinePropsWithDefaults([])[1]
      })
    }
  }

  combinePropsWithDefaults(defaultProps) {
    // const presetProps = JSON.parse(JSON.stringify(this.presetProps));
    const presetProps: any[] = structuredClone(this.possiblePresetProps);
     const props = presetProps.map((presetP)=> {
      const dProp = defaultProps.find((prop)=> {
        if (Property.isAnyNonStatusRelayOutputProperty(prop)) {
          return prop.name.split('|')[0] === presetP.name.split('|')[0]
        }
        return prop.type === presetP.type
      })
      if (!dProp) {
        presetP.include = false;
        presetP.value = null;
        return presetP;
      } else {
        presetP.include = true;
        presetP.value = dProp.value;
        return presetP
      }
     })

     const hvacTemp = [];
     const hvacOther = [];
     const equipmentIgnoreProps = [];
     const equipmentRelayOutput = [];
     const equipmentOther = [];
     const access = [];
     const burglary = [];
     const other = [];

     props.forEach((p: Property)=> {
      if (p.mqttTopic.startsWith('hvac/temp/')) {
        hvacTemp.push(p)
      }  else if (p.mqttTopic.startsWith('hvac/')) {
        hvacOther.push(p)
      }  else if (p.mqttTopic.startsWith('equipment/ignore')) {
        equipmentIgnoreProps.push(p)
      } else if (p.mqttTopic.startsWith('equipment/relayoutput/')) {
        equipmentRelayOutput.push(p)
      } else if (p.mqttTopic.startsWith('equipment/')) {
        equipmentOther.push(p)
      } else if (p.mqttTopic.startsWith('access/lock')) {
        access.push(p)
      } else if (p.mqttTopic.startsWith('security/burglary/') || p.mqttTopic.startsWith('access/burglary/')) {
        burglary.push(p)
      } else {
        other.push(p)
      }
     })
     const nonEquipmentRelayProps = [...hvacTemp,...hvacOther,...equipmentIgnoreProps,
      /* ...equipmentRelayOutput */...equipmentOther,...access,...burglary,...other]
    const equipmentRelayProps = [...equipmentRelayOutput]
     return [nonEquipmentRelayProps, equipmentRelayProps]


  }

  onClickPreset(preset) {
    this.targetProps = this.combinePropsWithDefaults( preset.equipmentPresetProperties.$values)[0]
    this.targetEquipmentRelayPropsOptions = this.combinePropsWithDefaults(preset.equipmentPresetProperties.$values)[1]
    this.targetEquipmentRelayPropValues = preset.equipmentPresetProperties.$values.filter((p)=> {
      return Property.isAnyNonStatusRelayOutputProperty(p)
    })
  }

  onClickNoPreset() {
    this.targetProps = this.combinePropsWithDefaults([])[0];
    this.targetEquipmentRelayPropsOptions = this.combinePropsWithDefaults([])[1]
    this.targetEquipmentRelayPropValues = [];
  }

  async onApply() {
    if (!this.signedInUser.havePermission(SoftwarePermissionId.LocationGroupsActivatePresets)) {
      const toast = await this.toastController.create({
        message: this.pipe.transform('Permission required. You do not have permission to activate preset.'),
        duration: 2000
      });
      await toast.present();
      return;
    }
    const includedProps: any[] = this.targetProps.filter((prop) => {
      return prop.include;
    });

    const includedRelayProps: any[] = this.targetEquipmentRelayPropValues.filter((prop )=> {
      const propIsIncluded = this.targetEquipmentRelayPropsOptions.some((p)=> {
        return p.include && (p.name.split('|')[0] === prop.name.split('|')[0])
      })
      return propIsIncluded // && Number.isInteger(prop.value)
      // return prop.include && Number.isInteger(prop.value)
    })
    const allIncludedProps = [...includedProps, ...includedRelayProps]

    const propsToSend: {typeCode:number, value: number}[] = [];
    let burglaryDetectedDoorProp;
    let burglaryDetectedWindowProp;
    let burglaryDetectedPIRProp;

   /*  propsToSend = propsToSend.map((prop) => {
      return {
        typeCode: prop.type,
        value: prop.value
      }
    });
    */

    allIncludedProps.forEach((prop)=> {
      if (Property.isBurglaryDetectedDoor(prop)) {
        burglaryDetectedDoorProp = (prop)
      } else  if (Property.isBurglaryDetectedWindow(prop)) {
        burglaryDetectedWindowProp = (prop)
      } else if (Property.isBurglaryDetectedByPIR(prop)) {
        burglaryDetectedPIRProp = (prop)
      } else {
        // propsToSend.push(prop)
        propsToSend.push({
          typeCode: prop.type,
          value: prop.value
        })
      }
    })
    if (propsToSend.length > 0 && this.selectedGroup.id) {
      this.loading = true;
      this.apiProjectService.changeMultiplePropertiesByGroup(this.selectedGroup.id, propsToSend).subscribe({
        next: () => {
         this.loading = false;
       },
       error: (err)=> {
        this.loading = false;
       }});
    }

    if (burglaryDetectedDoorProp && this.selectedGroup.id) {
      if (burglaryDetectedDoorProp.value) {
        this.apiProjectService.resumePublishingByGroup(burglaryDetectedDoorProp.type,this.selectedGroup.id).subscribe()
      } else {
        this.apiProjectService.stopPublishingByGroup( burglaryDetectedDoorProp.type, this.selectedGroup.id).subscribe()
      }
    }
    if (burglaryDetectedWindowProp && this.selectedGroup.id) {
      if (burglaryDetectedWindowProp.value) {
        this.apiProjectService.resumePublishingByGroup(burglaryDetectedWindowProp.type,this.selectedGroup.id).subscribe()
      } else {
        this.apiProjectService.stopPublishingByGroup( burglaryDetectedWindowProp.type, this.selectedGroup.id).subscribe()
      }
    }
    if (burglaryDetectedPIRProp && this.selectedGroup.id) {
      if (burglaryDetectedPIRProp.value) {
        this.apiProjectService.resumePublishingByGroup(burglaryDetectedPIRProp.type,this.selectedGroup.id).subscribe()
      } else {
        this.apiProjectService.stopPublishingByGroup( burglaryDetectedPIRProp.type, this.selectedGroup.id).subscribe()
      }
    }
  }

  defaultPropChange(event: any, prop:Property) {
    if (Property.isCO2SetPoint(prop)) {
      // no validation for co2setpoint
      return
    }

    const maxValue = 30;
    const minValue = 0

    if(prop.writeDataType == 'B') {
      if (event.target.value < minValue) {
        prop.value = minValue
      } else if (event.target.value > maxValue) {
        prop.value = maxValue
      }
    } else if (prop.writeDataType == 'b') {
      if (event.target.value < 0) {
        prop.value = 0
      } else if (event.target.value > 1) {
        prop.value = 1
      }
    }
  }

  equipmentRelayPropValuesIncludes(prop) {
    return this.targetEquipmentRelayPropValues.some((p)=> {
      return prop.name === p.name
    })
  }

  onClickRelayOutputPropValue(pr: Property) {
    const prop = structuredClone(pr)
    prop.value = 1;

    const index = this.targetEquipmentRelayPropValues.findIndex((p: Property) => {
      return prop.name.split('|')[0] === p.name.split('|')[0];
    });
    if (index === -1) {
      this.targetEquipmentRelayPropValues.push(prop);
    } else {
      this.targetEquipmentRelayPropValues.splice(index, 1);
      this.targetEquipmentRelayPropValues.push(prop);
    }
  }

}
