<div class="flex-row-space-around margin-20-all">
    <div class="content-form-group">
      <label class="width-100px">{{'From' | translate}}:</label>
  
      <owl-date-time-inline [(ngModel)]="selectedDateTime[0]"  #dtFrom></owl-date-time-inline>
    </div>
  
    <div class="content-form-group ">
  
      <label class="width-100px" >{{'To' | translate}}:</label>
  
      <owl-date-time-inline [(ngModel)]="selectedDateTime[1]"  #dtTo ></owl-date-time-inline>
    </div>
</div>

<ion-button class="margin-right10 margin-bottom10 float-right" colos="primary" (click)="acceptData()">{{'Accept' | translate}}</ion-button>
  