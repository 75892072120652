<div style="justify-content: start;">
  <div class="modal-header">
    <h2>{{ "Apply to modal" }}</h2>
  </div>
    <div class="flex-center">
      <div class="tabs">
        <input hidden type="radio" id="tab1" name="tab-control" checked>
        <input hidden type="radio" id="tab2" name="tab-control">
        <ul class="flex-row-space-between">
          <div (click)="setView('controllers')" class="margin-right50"><label for="tab1" role="button">
            <span class="font-size22 clickable">{{'Controllers' | translate}}</span></label></div>
          <div (click)="setView('groups')" class="margin-left50"><label for="tab2" role="button">
            <span class="font-size22 clickable">{{'Groups' | translate}}</span></label></div>
        </ul>
        <div class="bottom-slider">
          <div class="indicator"></div>
        </div>
      </div>
    </div>

  <ion-content *ngIf="viewType === 'controllers'" style="height: 640px;">
    <div class="flex-row-vertical margin-top30 margin-right30 margin-left30">
    <table>
      <thead class="sticky-header">
        <tr>
          <th class="width10" style="align-content: end;">
            <ion-checkbox
              [checked]="selectedControllers.length === controllers.length"
              (ionChange)="onSelectAllControllers($event)">
            </ion-checkbox>
          </th>
          <th class="width20">Name</th>
          <th class="width20">Address</th>
          <th class="width20">Type</th>
        </tr>
      </thead>
      <ng-container *ngIf="controllers.length !== 0 && controllers; let i = index">
        <tr *ngFor="let cont of controllers; let i = index">
          <ng-container *ngIf="selectedController.id !== cont.id">
          <th class="width10" style="align-content: end;">
            <ion-checkbox
              [checked]="isControllerSelected(i)"
              (ionChange)="onSelectController(i, $event)">
            </ion-checkbox>
            </th>
            <td class="width20">
              {{ cont.name }}
            </td>
            <td class="width20">
              {{ cont.address }}
            </td>
            <td class="width20">
            {{ getControllerType(cont) }}
            </td>
          </ng-container>
          </tr>
        </ng-container>
      </table>
    </div>
  </ion-content>

  <ion-content *ngIf="viewType === 'groups'" style="height: 640px;">
    <div class="flex-row-vertical margin-top30 margin-right30 margin-left30">
      <table>
        <thead class="sticky-header">
          <tr>
            <th class="width10" style="align-content: end;">
              <ion-checkbox
                [checked]="selectedGroups.length === groups.length"
                (ionChange)="onSelectAllGroups($event)">
              </ion-checkbox>
            </th>
            <th class="width20">Group</th>
            <th class="width20">Locations</th>
          </tr>
        </thead>
        <ng-container *ngIf="groups.length !== 0 && groups; let i = index">
          <tr *ngFor="let group of groups; let i = index">
            <th class="width10" style="align-content: end;">
              <ion-checkbox
                [checked]="allGroupsSelected"
                (ionChange)="onSelectGroup(i, $event)"
              ></ion-checkbox>
            </th>
            <td class="width20">
              {{ group.name }}
            </td>
            <td class="width20">
              <ion-button
              (mouseenter)="showLocations($event, group)"
              (mouseleave)="showLocations($event, group)"
              color="primary"
              class="margin-right5 ion-no-border"
              fill="outline"
              size="small"
              >{{'Show' | translate}}
            </ion-button>
            <div *ngIf="showLocationsPopup(group.id)" class="trigger" [@enterAnimation]>
              <div class="popup">
                <div *ngIf="!locationsToShowInPopup" class="inner">
                  <div class="lds-dual-ring"></div>
                </div>
                <div *ngIf="locationsToShowInPopup" class="inner flex-row">
                  <div *ngFor="let loc of locationsToShowInPopup; let i = index;" class="flex-row margin-all5">
                    <div class="br-b" style="font-weight: bold; margin-right: 4px;">{{loc.name}},</div>
                  </div>
                  <div *ngIf="locationsToShowInPopup.length === 0" class="width100">
                    <div class="text-align-center">{{'Empty' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ion-content>

  <div class="flex-column margin-bottom20 margin-right20 margin-top20">
    <ion-button
      *ngIf="!sending"
      class="to-right width20"
      [disabled]="selectedControllers.length <= 0"
      (click)="onSaveAndSend()">
      Save and send
    </ion-button>
    <ion-button
      *ngIf="sending"
      class="to-right width20"
      disabled>
      Sending...
    </ion-button>
  </div>
</div>
