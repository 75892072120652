import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-modal-view-multiple-boolean-prop',
  templateUrl: './modal-view-multiple-boolean-prop.component.html',
  styleUrls: ['./modal-view-multiple-boolean-prop.component.scss']
})
export class ModalViewMultipleBooleanPropComponent implements OnInit, OnDestroy  {
  @Input() controllers: Controller[];
  @Input() propFilterFunction: (property: Property) => boolean
  @Input() activeText: string;
  @Input() inactiveText: string;
  @Input() iconActive: string;
  @Input() iconInactive: string;
  @Input() forcePropIndex: number;
  updatedSub: Subscription;
  updated;
  Property = Property;

  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {
    this.updatedSub = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
    })
  }

  ngOnDestroy () {
    if (this.updatedSub) {
      this.updatedSub.unsubscribe();
    }
  }
}
