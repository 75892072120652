export class CardType {
    typeId: number;
    code: number;
    name: string;

    constructor() {}


    public static isGuestCard(cardType: CardType) {
      return Number(cardType.code) === 0
    }

    public static isMaidCard(cardType: CardType) {
      return Number(cardType.code) === 1
    }

    public static isDoorCard(cardType: CardType) {
      return Number(cardType.code) === 5 || // one time
      Number(cardType.code) === 6 // passage only
    }

    public static isStaffCard(cardType: CardType) {
      if (!cardType?.code) {
        return false
      }
      return Number(cardType.code) === 1 || // room service(maid)
      Number(cardType.code) === 2 || // maintanance
      Number(cardType.code) === 3 ||// managment
      Number(cardType.code) === 4 ||// reception
      Number(cardType.code) === 7 // housekeeping

    }
}
