import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Controller } from 'src/app/core/models/controller.model';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { ValueGraph } from 'src/app/core/models/logs/value-graph.model'
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { Property } from 'src/app/core/models/project/property.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { DateTimeFromToComponent } from 'src/app/modules/logs/components/date-time-from-to/date-time-from-to.component';
import { ApiAccessLogsService } from 'src/app/modules/logs/services/http/api-access-logs.service';
import { ApiAlarmLogsService } from 'src/app/modules/logs/services/http/api-alarm-logs.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { PopoverController } from '@ionic/angular';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { UserSettingsService } from '../../services/user-settings.service';
import { DEMO_MODE } from 'src/environments/environment';
import { DemoModeService } from '../../services/demo-mode.service';
import { CustomTranslatePipe } from '../../pipes/custom-translate.pipe';

export interface IValueDictionaryItem {
  CodeFrom: number;
  CodeTo: number;
  activeName?: string,
  inactiveName?: string,
  name: string,
  mode: 'bool' | 'num' | 'custom(cardType)' | 'custom(openTooLong)'
}

@Component({
  selector: 'app-room-modal-log',
  templateUrl: './room-modal-log.component.html',
  styleUrls: ['./room-modal-log.component.scss']
})
export class RoomModalLogComponent implements OnInit {

  @ViewChild('dateTimeFromTo') dateTimeFromTo: DateTimeFromToComponent;
  @ViewChild('tablePropertiesSelect') tablePropertiesSelect: any;
  @ViewChild('tableTemperatureSelect') tableTemperatureSelect: any;
  @Input() controllers: Controller[];
  private readonly EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/cntproptypes.json';
  private readonly VALUE_LOG_TRANSLATION_URL: string = 'assets/config/valueLogTranslation.json';
  activeCard: 'access' | 'alarm' | 'fullGraph' | 'temperature';
  propertiesList: Array<any>;

  // graph
  graphCreated = false;
  tableCreated = true;
  tempGraph: any[];
  circumstancesGraph: any[];
  tableData: any = [];
  tableProperties: string[] = [];
  temperatureProperties: string[] = [];
  selectedController: Controller;
  LegendPosition = LegendPosition;

  body: ValueGraph = new ValueGraph;

  // options
  xAxisLabel = 'Time';
  yAxisLabel = 'Values';
  yScaleMin = 0;
  yScaleMax = 30;
  yScaleHumidityMin = 0;
  yScaleHumidityMax = 100;
  yScaleCo2MeasurmentMin = 200;
  yScaleCo2MeasurmentMax = 2000;
  // colorScheme = {domain: ['#6BA0C4', '#03143A']};
  hideCustomTime = true;
  tempEvent: CustomEvent;
  tempEventTemperature: CustomEvent;
  showSelect = true;

  Property = Property;

  propSelect: any;
  temperatureTypesSelect: any;

  // event log
  page = 1;
  giveAccess: boolean = false;
  giveAlarm: boolean = false;
  logsLoading = false;

  accessCheckbox = false;
  alarmCheckbox = false;

  queryParams = {
    pageNumber: 1,
    pageSize: 10000,
    includeArchive: false
  };

  valueDictionary: IValueDictionaryItem[] = [];
  cardTypes: CardType[];
  cardTypesSubscription: Subscription;
  signedInUser: User;
  swPermissions = SoftwarePermissionId;
  searchActive = false
  searchValue = '';
  localeId: string;
  propsHr: Property[] = [];

  customTemperatureAlertOptions = {
    cssClass: 'custom-temperature-props-alert'
  };

  constructor(private apiProjectService: ApiProjectService,
              private apiAccessLogService: ApiAccessLogsService,
              private apiAlarmLogsService: ApiAlarmLogsService,
              private http: HttpClient,
              private cardsService: CardsService,
              private currentUserStoreService: CurrentUserStoreService,
              private popoverController: PopoverController,
              private userSettingsService: UserSettingsService,
              private demoModeService: DemoModeService,
              private pipe: CustomTranslatePipe,
              ) { }

  ngOnInit(): void {
    this.selectedController = this.controllers[0];
    this.localeId = this.userSettingsService.getLanguage();
    this.http.get<any>(this.EQUIPMENT_PROPERTY_TYPES_URL).subscribe( resp => {
      this.propertiesList = resp.Definitions;
    })
    this.http.get<IValueDictionaryItem[]>(this.VALUE_LOG_TRANSLATION_URL).subscribe( resp => {
      this.valueDictionary = resp;

    })
    this.cardTypes = this.cardsService.getCardTypes();
    // this.api.getCardTypes().subscribe();
    this.cardTypesSubscription = this.cardsService.cardTypesChanged.subscribe(() => this.cardTypes = this.cardsService.getCardTypes())
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());

    if (this.signedInUser.havePermission(SoftwarePermissionId.AccessLogView)) {
      this.activeCard = 'access';
    } else if (this.signedInUser.havePermission(SoftwarePermissionId.AlarmsLogView)) {
      this.activeCard = 'alarm';
    } else if (this.signedInUser.havePermission(SoftwarePermissionId.ValueLogView)) {
      this.activeCard = 'fullGraph';
    }
    if(DEMO_MODE) {
      this.demoModeService.getData().subscribe( value => {
        this.body.properties.push(this.selectedController.controllerProperties.$values.find( prop => Property.isRoomTemperatureCurrent(prop)).type.toString())
        this.temperatureTypesSelect = []
        this.temperatureTypesSelect.push(Property.isRoomTemperatureCurrent)
        this.tempGraph = this.createDataFromResponse(value.TemperatureLog);
        this.tableData = value.ValueLog
        this.tableProperties = value.TableProperties
        this.propSelect = [];
        this.circumstancesGraph = [];
        this.propSelect.push(Property.isDoorOpened)
        this.propSelect.push(Property.isWindowOpened)
        this.tableCreated = true;
        this.logsLoading = false
        this.graphCreated = true;

      })
    }
    if (this.localeId === 'hr') {
      this.getPropNamesHr();
    }
  }

  cardClicked(card: 'access' | 'alarm' | 'fullGraph' | 'temperature') {
    if (DEMO_MODE) {
      this.demoModeService.getData().subscribe( value => {
        this.tableProperties = value.TableProperties
        this.graphCreated = true;

      })
    }
    this.activeCard = card;
    if (DEMO_MODE) { return }
    this.tableProperties = [];
    this.temperatureTypesSelect = [];
    this.temperatureProperties = [];
    this.propSelect = undefined;
    if (this.activeCard === 'temperature') {
      this.selectedController.controllerProperties.$values.forEach ( prop => {
        if (Property.isRoomTemperatureCurrent(prop)) {
          this.temperatureProperties.push(prop.type.toString())
          this.temperatureTypesSelect.push(Property.isRoomTemperatureCurrent)
        } else if (Property.isBathroomTemperatureCurrent(prop)) {
          this.temperatureProperties.push(prop.type.toString())
          this.temperatureTypesSelect.push(Property.isBathroomTemperatureCurrent)
        }
      })
      this.createGraph();
    }
    if (this.activeCard === 'fullGraph') {
      setTimeout (() => {
        this.showLast7days();
      },200)
    }
  }

  getPropertyName(propType: string) {
    const target = this.propertiesList.find( (prop: PropertyType) => {
      if (+propType >= prop.CodeFrom && +propType <= prop.CodeTo) { return true}
    })



    if (propType) {
      if (target) {
        if (target.Name == 'RelayOutputStatus' ||
        target.Name == 'RelayOutputControl' ||
        target.Name == 'RelayOutputForceOn' ||
        target.Name == 'RelayOutputForceOff' ||
        target.Name == 'RelayOutputByRentedOrPresence' ||
        target.Name == 'RelayOutputInCooling' ) {
          return this.handleSpecialPropNames(propType)
        } else {
          return target.Name;
        }
      } else {
        return 'Unknown property';
      }
    } else {
      return null;
    }
  }

  handleSpecialPropNames(propTypeCode: string) {
    const targetProp = this.selectedController.controllerProperties.$values.find(prop => +prop.type == +propTypeCode)
    return targetProp.name.split('|')[0] + ' ' + targetProp.name.split('|')[1];
  }

  createGraph() {
    //we dont wanna change data if we are in demo mode
    if (DEMO_MODE) {
      return
    }
    this.logsLoading = true;
    this.body.controllerDesignations = [];
    this.body.controllerDesignations.push(this.selectedController.designation);
    if (this.activeCard === 'temperature') {
      this.graphCreated = false;
      // this.body.properties = ["6250", "6200", ...this.tableProperties];
      this.body.properties = [...this.temperatureProperties,...this.tableProperties];
    }
    if (this.activeCard === 'fullGraph') {
      this.tableCreated = false;
      this.body.properties = this.tableProperties;
    }

    if ((this.tableProperties.length > 0 && this.activeCard === 'fullGraph') || this.activeCard === 'temperature') {
      this.apiProjectService.getGraph(this.body).subscribe( response => {
        if (response) {
          if (this.activeCard === 'temperature') {
            this.tempGraph = this.createDataFromResponse(response);
            this.graphCreated = true;
          }

          if (this.activeCard === 'fullGraph') {
            this.tableData = this.reduceDataForTableView(response);
            this.getAlarmAndAcessLogs();
          }
        } else {
          this.graphCreated = false;
        }
      });
    } else {
      this.getAlarmAndAcessLogs();
    }
  }

  reduceDataForTableView(response: any) {
    const tempData = [];
    this.tableProperties.forEach( prop => {
      response.forEach( (element , index) => {
        if ((index === 0 && element.propertyType === prop) || (element.propertyType === prop &&
           response[index].value !== response[index-1].value)) {
            tempData.push(element);
        }
      });
    })
    tempData.sort( (a, b) =>  <any>new Date(b.timestamp) - <any> new Date(a.timestamp));
    return tempData;
  }

  createDataFromResponse(resp: any) {
    let results = [];
    this.body.properties.forEach( prop => {
      results.push({name: this.getPropertyName(prop), series: []})
    })
    resp.forEach( data => {
      const target = results.find( element => {
       if (element.name === this.getPropertyName(data.propertyType)) { return true;}
      })
      if (target) {
        target.series.push({name: new Date(data.timestamp), value: data.value})
      }
    });
    if (this.activeCard === 'temperature') {
      this.circumstancesGraph = [];
      this.tableProperties.forEach( propType => {
        const prop = this.selectedController.controllerProperties.$values.find (p => p.type === +propType);
        if (prop) {
          this.circumstancesGraph.push(...results.filter( data => data.name === this.getPropertyName(prop.type.toString())));
          // We need to change every value 9 to value 1 in windows
          const target = this.circumstancesGraph.find( element => element.name === 'Window');
          if (target) {
            target.series.forEach( data => {
              if (data.value == 9) {
                data.value = 1;
              }
            })
          }
        }
        results = results.filter( data => data.name !== this.getPropertyName(propType));
      })
    }
    if(this.localeId === 'hr'){
      results.forEach(prop => {
        prop.name = this.pipe.transform(prop.name);
      });
    }
    // if(this.localeId === 'hr' && results[0].name === 'RoomTemperature'){
    //   results[0].name = "Sobna temperatura";
    return results;
  }

  yAxisTickFormatting = (value) => {
    if (value == 0) {
      return `Closed`
    }
    if (value == 1) {
      return `Open`
    }
  };

  setDateTimeFromTo(selectedDateTime: Date[]) {
    if (!(selectedDateTime[0] === undefined || selectedDateTime[0] === null)
    && !(selectedDateTime[1] === undefined || selectedDateTime[1] === null)) {
      this.body.dateTimeFrom = selectedDateTime[0].toISOString();
      this.body.dateTimeTo = selectedDateTime[1].toISOString();
      this.createGraph();
    }
  }

  getAlarmAndAcessLogs() {
    if (this.accessCheckbox) {
      this.removeAccessLog();
      this.getAccessLog(1)
    }
    if (this.alarmCheckbox) {
      this.removeAlarmLog()
      this.getAlarmLog(1)
    }

    if (!this.alarmCheckbox && !this.accessCheckbox) {
      this.tableCreated = true;
      this.logsLoading = false;
    }
  }


  showLast24hours() {
    this.dateTimeFromTo.showLast24hours();
  }

  showLast7days() {
    this.dateTimeFromTo.showLast7days();
  }

  showLast30days() {
    this.dateTimeFromTo.showLast30days();
  }

  selectChanged(event: CustomEvent) {
    // saving last selected props for more user friendly exp when swaping controllers
    this.tempEvent = event;
    this.runEventCode(event);
    this.createGraph();
    // this.sortTable();
    this.onDismiss();
  }

  selectTemperatureChanged(event: CustomEvent) {
    this.tempEventTemperature = event;
    this.runEventCodeForTemperature(event)
    this.createGraph();
  }

  runEventCode(event: CustomEvent) {
    this.tableProperties = [];
    this.selectedController.controllerProperties.$values.forEach ( prop => {
      let target: Property;
      event.detail.value.forEach( value => {
        target = value(prop);
        if(target && this.tableProperties.indexOf(prop.type.toString()) === -1) {
          this.tableProperties.push(prop.type.toString());
        }
      });
    })
  }

  getRelayName(name: string) {
    return name.split('|')[0];
  }

  runEventCodeForTemperature (event: CustomEvent) {
    this.temperatureProperties = [];
    this.selectedController.controllerProperties.$values.forEach ( prop => {
      let target: Property;
      event.detail.value.forEach( value => {
        target = value(prop);
        if(target && this.temperatureProperties.indexOf(prop.type.toString()) === -1) {
          this.temperatureProperties.push(prop.type.toString());
        }
      });
    })
  }

  controllerChanged(controller: Controller) {
    this.selectedController = controller;
    if (this.tempEvent) {
      this.runEventCode(this.tempEvent);
    }

    if (this.tempEventTemperature) {
      this.runEventCodeForTemperature(this.tempEventTemperature)
    }
    this.createGraph()

    // force ion-select update when swaping controllers
    this.showSelect = false
    setTimeout( () => {
      this.showSelect = true;
    },100)
  }

  simulateClick() {
    // we use this to emulate button click to open the search component
      // let elements = document.querySelectorAll('.alert-head.sc-ion-alert-md');
      //   console.log(elements)
      //   var rect = elements[0].getBoundingClientRect();
      //   console.log(rect.top, rect.right, rect.bottom, rect.left);
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
        clientX: 0,
        clientY:0,
        screenX: 0,
        screenY:0
      });
      this.openSearch(evt);
  }

  onDismiss() {
    if (this.searchActive) {
      this.searchActive = false;
      this.popoverController.dismiss(null)
      this.searchValue = '';
    }
  }

  getPropNamesHr() {
    this.propsHr = this.selectedController.controllerProperties.$values.map(prop => {
      prop.name = this.pipe.transform(prop.name);
      return prop;
    });
  }

  async openSearch(ev) {
    if (!this.searchActive) {
      this.searchActive = true;
      let myEmitter = new EventEmitter<string>();
      myEmitter.subscribe( value => {
        this.searchValue = value
      });
      const popover = await this.popoverController.create({
        component: FilterSelectComponent,
        cssClass: 'select-search',
        event: ev,
        backdropDismiss: false,
        showBackdrop: false,
        componentProps: {
          inputDelay: 500,
          someTextWriten: myEmitter
        },
      });
      await popover.present();

      popover.onDidDismiss().then( () => {
      })

    }
  }

  showCustom() {
    if (this.hideCustomTime === true) {
      this.hideCustomTime = false;
    } else {
      this.hideCustomTime = true;
      this.dateTimeFromTo.showLast7days();
    }
  }

  onAccessLogClick(event: any) {
    if (event.target.checked) {
      this.accessCheckbox = true;
      this.createGraph()
    } else {
      this.accessCheckbox = false;
      this.removeAccessLog()
      this.giveAccess = false;
    }
  }

  onAlarmLogClick(event: any) {
    if (event.target.checked) {
      this.alarmCheckbox = true;
      this.createGraph()
    } else {
      this.alarmCheckbox = false;
      this.removeAlarmLog()
      this.giveAlarm = false;
    }
  }

  getAccessLog(pageNumber: number) {
    this.tableCreated = false;
    this.logsLoading = true;
    const requestBody: SsFilterSort = new SsFilterSort();
    requestBody.dateTimeFrom = this.body.dateTimeFrom;
    requestBody.dateTimeTo = this.body.dateTimeTo;
    requestBody.sorting = 'DSC|TIME';
    requestBody.controllerDesignations.push(this.selectedController.designation)
    this.queryParams.pageNumber = pageNumber;

    this.apiAccessLogService.getAccessLogs(requestBody, this.queryParams).subscribe((resp: HttpResponse<AccessLog[]>) => {
      const paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      let accessLogData = resp.body;

      // filtering for single room
        accessLogData = accessLogData.filter( accessData => {
          if (accessData.locationName.includes(this.selectedController.name)) {
            return true;
          }
        })

        this.combineData(accessLogData);
        if (paginationData.HasNext) {
          this.getAccessLog(paginationData.CurrentPage + 1)
        } else {
          this.sortTable();
          this.giveAccess = true;
        }

    });
  }


  getAlarmLog(pageNumber: number) {
    this.tableCreated = false;
    this.logsLoading = true;
    const requestBody: SsFilterSort = new SsFilterSort();
    requestBody.dateTimeFrom = this.body.dateTimeFrom;
    requestBody.dateTimeTo = this.body.dateTimeTo;
    requestBody.sorting = 'DSC|TIME';
    requestBody.controllerDesignations.push(this.selectedController.designation);


    this.queryParams.pageNumber = pageNumber;


    this.apiAlarmLogsService.getAlarmLogs(requestBody, this.queryParams).subscribe(resp => {
      let alarmLogData = resp.body;
      const paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      alarmLogData = alarmLogData.filter( alarm => {
          if (alarm.data.includes(this.selectedController.designation)) {
            return true;
          }
        })

        this.combineData(null, alarmLogData);
        if (paginationData.HasNext) {
          this.getAlarmLog(paginationData.CurrentPage + 1)
        } else {
          this.sortTable();
          this.giveAlarm = true;
        }
    });
  }

  combineData(accessData?: AccessLog[], alarmData?: any) {
    if (accessData) {
      accessData.forEach( data => {
        this.tableData.push({timestamp: new Date(data.timestamp), user: data.userFullName, locationName: data.locationName, type: 'access'})
      })
    }

    if(alarmData) {
      alarmData.forEach( data => {
        this.tableData.push({timestamp: new Date(data.timestamp), level: data.level, description: data.description, type: 'alarm'})
      })
    }
  }

  removeAccessLog() {
    this.tableData = this.tableData.filter( element => element.type !== 'access');
  }

  removeAlarmLog() {
    this.tableData = this.tableData.filter( element => element.type !== 'alarm');
  }

  sortTable() {
    this.tableData.sort( (a, b) =>  <any>new Date(b.timestamp) - <any> new Date(a.timestamp));
    this.logsLoading = false;
    this.tableCreated = true;
  }

  onPageChange(value: any) {
    this.page = value
  }

  showDataAtPage(index: number) {
    if (index === 0 && this.page === 1) {
      return true
    } else {
      const value = index / 50;
      return (this.page === Math.ceil(value))
    }
  }

  onDestroy() {
    this.cardTypesSubscription.unsubscribe()
  }
}
