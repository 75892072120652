import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlarmAlert } from '../models/alarms/alarm-alert.model';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  message = '';
  messageChanged = new Subject<string>();
  alarmAlert: AlarmAlert;
  alarmMessageChanged = new Subject<any>();

  constructor() { }

  getMessage() {
    return this.message;
  }

  setMessage(message: string) {
    this.message = message;
    this.messageChanged.next(this.message);
  }

  getAlarmMessage() {
    return this.alarmAlert;
  }

  setAlarmAlert(alarmAlert: AlarmAlert) {
    this.alarmAlert = alarmAlert;
    this.alarmMessageChanged.next(this.alarmAlert);
  }
}
