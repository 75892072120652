import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTemperatureLogs'
})
export class FilterTemperatureLogsPipe implements PipeTransform {

  transform(values: {name: string, series: any []}[] , valueName: 'CO2Measurment' | 'Humidity'): {name: string, series: any []}[] {
    return values.filter((val)=> {
            return val.name === valueName
            // value names taken from 'assets/cntproptypes.json'
    });
  }

}
