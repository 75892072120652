import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'showRadiator'
})
export class ShowRadiatorPipe implements PipeTransform {

  transform(controllers: Controller[], location : 'room'| 'bathroom'): boolean {

    const byRentedprops = [];
    const forcedOnProps = [];
    const forcedOffProps = [];
    const statusProps = []
    const inCooling = [];

    if (location === 'bathroom') {
      controllers.forEach((cont)=> {
        cont.controllerProperties.$values.forEach((prop)=> {
          if (Property.isBathroomRadiatorByRented(prop)) {
            byRentedprops.push(prop)
          } else if (Property.isBathroomRadiatorForceOn(prop)) {
            forcedOnProps.push(prop)
          } else if (Property.isBathroomRadiatorForceOff(prop)) {
            forcedOffProps.push(prop)
          } else if(Property.isBathroomRadiator(prop)) {
            statusProps.push(prop)
          }  else if(Property.isBathroomRadiatorInCooling(prop)) {
            inCooling.push(prop)
          }
        })
      })
    } else if (location === 'room') {
      controllers.forEach((cont)=> {
        cont.controllerProperties.$values.forEach((prop)=> {
          if (Property.isRadiatorByRented(prop)) {
            byRentedprops.push(prop)
          } else if (Property.isRadiatorForceOn(prop)) {
            forcedOnProps.push(prop)
          } else if (Property.isRadiatorForceOff(prop)) {
            forcedOffProps.push(prop)
          } else if(Property.isRoomRadiator(prop)) {
            statusProps.push(prop)
          } else if(Property.isRadiatorInCooling(prop)) {
            inCooling.push(prop)
          }
        })
      })
    }

    if (statusProps.length > 0 || byRentedprops.length > 0 ||
      forcedOnProps.length > 0 || forcedOffProps.length > 0 || inCooling.length >0) {
      return true
    } else {
      return false
    }



  }

}
