<div
  *ngIf="
    _controllers
      | findPropertyByCode : propData.codeFrom : propData.codeTo  as property
  "
  class="flex-row border-bottom margin-bottom10"
>
  <div class="temp-box-title margin-right20 icon-left-text">
    {{ property[0].name | translate }}
  </div>
  <ng-container *ngIf="{loading :((loadingElements$ | async) | propIsLoading : propData: _controllers)} as data">
  <ion-button
    size="small"
    fill="outline"
    color="medium"
    class="ion-btn-pad-sm margin2 width-25"
    [disabled]="data.loading"
    (click)="decrement()"
    >-</ion-button
  >
  <div
    *ngIf="(targetValue || property[0]?.value) &&
    !data.loading; else novalue"
    class="temp-box-content"
  >
    <span class="temp-num">
      {{
        targetValue !== null && targetValue !== undefined
          ? targetValue
          : property[0]?.value
      }} </span
    ><span class="temp-unit">°C</span>
  </div>
  <ng-template #novalue>
    <div class="temp-box-loading flex-row-center-h">
    <ion-spinner
      *ngIf="data.loading; else noLoading"
      name="lines-sharp"
    ></ion-spinner>
  </div>
    <ng-template #noLoading>
    <div class="margin-top5">
      <div class="no-info">{{ "No info" | translate }}</div>
    </div>
  </ng-template>

  </ng-template>
  <ion-button
    size="small"
    fill="outline"
    color="medium"
    class="ion-btn-pad-sm margin2 width-25"
    [disabled]="data.loading"
    (click)="increment()"
    >+</ion-button
  >
</ng-container>
</div>
