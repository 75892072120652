import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiProjectService, LicenceData } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit {

  licenceKey: string;
  licensingForm: UntypedFormGroup;
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder,
             private apiProjectService: ApiProjectService,
             private router: Router) { }

  ngOnInit() {
    this.apiProjectService.getProjectId().subscribe( projectID => {
      if (projectID === '' || projectID === null) {
        this.router.navigate(['/projectID']);
      } else {
        this.apiProjectService.getLicence().subscribe( (data:LicenceData) => {
          if (!data.isValid) {
            this.buildForm()
          } else {
            this.router.navigate(['/login']);
          }
        })
      }
    })

  }

  buildForm() {
    this.licensingForm = this.formBuilder.group({
      licenceKey: ['', [Validators.required]]
    }); 
}

  onEnterKey() {
    this.submitted = true;
    if (this.licensingForm.valid) {
      this.apiProjectService.setLicenceKey(this.licensingForm.controls.licenceKey.value).subscribe( () => {
        this.router.navigate(['/login']);
      })
    }
  }
}
