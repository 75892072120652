<div class="flex-row margin-top30 modal-content-container">
  <div class="width100 flex-row margin-right50 margin-left100" style="justify-content: flex-start; flex-flow: nowrap">
    <div class="flex-column width8">
      <ion-list *ngIf="controllers.length > 1" class="margin20 width-150">
        <ion-list-header>{{ "Controllers" | translate }}</ion-list-header>
        <ion-item
          button
          style="width: 80px;"
          *ngFor="let controller of controllers"
          (click)="selectController(controller)"
          [ngClass]="{'ion-list-activated': selectedControllers[0].id === controller.id}">
          {{ controller.name }}
          <div [ngClass]="{'inactive-icon-small': !controller.isOnline}"></div>
        </ion-item>
      </ion-list>
    </div>
    <ng-container *ngIf="renderNumberInputs">

  <div *ngIf="hasProps" class="flex-row" style="flex-flow: row;">
      <div class="flex-column width60">
        <div style="height:50px">
        <ion-button
        class="margin-right5 margin-top5 margin-bottom10"
        style="width: 300px; margin-left:3px"
        color="light"
        (click)="syncHvacSettings()"
        size="small"
        ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
        {{'Sync HVAC settings' | translate}}</ion-button>
        </div>
        <div class="margin-bottom30 uppercase">{{ "Room" | translate }}</div>
        <ng-container *ngFor="let propData of (settings$ | async).room  | sortByAdminProps as roomSettings; let i = index">
          <ng-container *ngIf="selectedControllers | findPropertyByCode : propData.codeFrom: propData.codeTo  as settingsRoomProps">
            <ng-container *ngIf="settingsRoomProps.length > 0">
              <div *ngIf="i>0 && roomSettings[i-1].adminProp=== false &&
                roomSettings[i].adminProp=== true &&
                logedInUser.userId === 1"
              class="margin-bottom15 margin-top10 uppercase">{{ "Admin" | translate }}
              </div>
              <ng-container *ngIf="logedInUser.userId === 1 || propData.adminProp === false">
                <app-number-input-code
                  [controllers]="selectedControllers"
                  [propData]="propData">
                </app-number-input-code>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="margin-left20 margin-top50">
        <app-room-temp-info [selectedControllers]="selectedControllers"
          [location]="'room'">
        </app-room-temp-info>
      </div>
  </div>

  <div *ngIf="!hasProps">
    <div class="margin-left100 margin-top40">{{"Controller does not have hvac settings" | translate}}</div>
  </div>

  <div class="flex-row margin-left40" style="flex-flow: row;">
    <div *ngIf="bathroomHasTemps" class="flex-column width61">
      <div style="height:50px">
        <!-- spacer for buttons -->
        </div>
      <div class="margin-bottom30 uppercase">{{ "Bathroom" | translate }}</div>
      <ng-container *ngFor="let propData of (settings$ | async).bathroom | sortByAdminProps as bathroomSettings; let i = index">
        <ng-container
        *ngIf="selectedControllers | findPropertyByCode : propData.codeFrom: propData.codeTo  as settingsBathroomProps"
      >
      <ng-container *ngIf="settingsBathroomProps.length > 0">
        <div *ngIf="i>0 && bathroomSettings[i-1].adminProp=== false &&
          bathroomSettings[i].adminProp=== true &&
          logedInUser.userId === 1"
         class="margin-bottom15 margin-top10 uppercase">{{ "Admin" | translate }}
       </div>
       <ng-container *ngIf="logedInUser.userId === 1 || propData.adminProp === false">
        <app-number-input-code
         [controllers]="selectedControllers"
          [propData]="propData"
        ></app-number-input-code>
      </ng-container>
      </ng-container>
       </ng-container>
       </ng-container>
      <div style="height:54px"></div><!-- SPACER -->
    </div>
    <div class="margin-left20 margin-top50">
      <app-room-temp-info *ngIf="selectedControllers | roomHaveBathroom" [selectedControllers]="selectedControllers"
      [location]="'bathroom'"
      ></app-room-temp-info>
    </div>

  </div>

  <ion-radio-group *ngIf="(selectedControllers[0] | isHvacCommonArea) && (selectedControllers[0] | propExistInController : Property.fancoilMode : true)" class="margin-left20" [(ngModel)]="fancoilMode" (ionChange)="onFancoilSelect($event)">
    <ion-list-header>
     {{'Fancoil' | translate}}
    </ion-list-header>

    <ion-item *ngIf="selectedControllers[0] | propExistInController : Property.isHvacRoomControlFancoilOff : true">
      <ion-radio slot="start" [value]="'0'">{{'Off' | translate}}</ion-radio>
    </ion-item>

    <ion-item *ngIf="selectedControllers[0] | propExistInController : Property.isHvacRoomControlFancoilLow : true">
      <ion-radio slot="start" [value]="'1'">{{'Low' | translate}}</ion-radio>
    </ion-item>

    <ion-item *ngIf="selectedControllers[0] | propExistInController : Property.isHvacRoomControlFancoilMedium : true">
      <ion-radio slot="start" [value]="'2'">{{'Medium' | translate}}</ion-radio>
    </ion-item>

    <ion-item *ngIf="selectedControllers[0] | propExistInController : Property.isHvacRoomControlFancoilHigh : true">
      <ion-radio slot="start" [value]="'3'">{{'High' | translate}}</ion-radio>
    </ion-item>

    <ion-item *ngIf="selectedControllers[0] | propExistInController : Property.isHvacRoomControlFancoilAuto : true">
      <ion-radio slot="start" [value]="'6'">{{'Auto' | translate}}</ion-radio>
    </ion-item>
</ion-radio-group>
  </ng-container>
  <div class="width25" *ngIf="!renderNumberInputs"></div><!-- SPACER. Kees spacing while rerendering components -->
  <div class="width25" *ngIf="!renderNumberInputs"></div>
  </div>
</div>
