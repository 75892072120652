import { Pipe, PipeTransform } from '@angular/core';
import { GetControllerByIndexPipe } from './get-controller-by-index.pipe';
import { FindPropertyByCodePipe } from './find-property-by-code.pipe';
import { DisplayPropertyParams } from '../components/display-property/display-property.component';
import { Location } from 'src/app/core/models/project/location.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'showDisplayProp',
})
export class ShowDisplayPropPipe implements PipeTransform {
  constructor(
    private getControllerByIndex: GetControllerByIndexPipe,
    private findPropertyByCode: FindPropertyByCodePipe
  ) {}

  transform(displayProp: DisplayPropertyParams, location: Location): boolean {
    if (displayProp.mode === 'custom(empty)') {
      return false;
    } else if (displayProp.mode === 'status') {
      return true;
    } else if (
      displayProp.mode === 'custom(daikinUnitFanSpeed)' ||
      displayProp.mode === 'custom(daikinUnitMode)' ||
      displayProp.mode === 'custom(daikinUnitStatus)' ||
      displayProp.mode === 'numericalPropType' ||
      displayProp.mode === 'singlePropType' ||
      displayProp.mode === 'custom(highestCleaningStatus)' ||
      displayProp.mode === 'custom(relayOutputStatus)' ||
      displayProp.mode === 'custom(hvacHeatCoolAuto)'
    ) {
      const controllers: Controller[] = this.getControllerByIndex.transform(
        location.controllers.$values,
        displayProp.propInfo.controllerIndex
      );
      const props = this.findPropertyByCode.transform(
        controllers,
        displayProp.propInfo.codeFrom,
        displayProp.propInfo.codeTo
      );
      return props.length > 0;
    } else if (displayProp.mode === 'custom(fanSpeed)') {
      const controllers: Controller[] = this.getControllerByIndex.transform(
        location.controllers.$values,
        displayProp.propInfo.controllerIndex
      );
      const fanSpeedProps = controllers.reduce(
        (allFilteredProps, controller) => {
          const filteredPropsOnController =
            controller.controllerProperties.$values.filter((prop: Property) => {
              return Property.isFancoilSpeed(prop);
            });
          allFilteredProps = [
            ...allFilteredProps,
            ...filteredPropsOnController,
          ];
          return allFilteredProps;
        },
        []
      );
      return fanSpeedProps.length > 0;
    }
    return true;
  }
}
